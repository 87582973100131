import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react' // Import useState
import { Button } from '../ui/Button'
import { fixDecimal } from '../../Helper'

const SendConfirmationDialog = ({currencyBalance, open, userInput, feeLevel, handleSubmitConfirm, handleClose, from, to, address, amount }) => {


    const tokenCodeORsymbol = userInput?.selectedCoin?.code ?? userInput?.selectedCoin?.symbol ?? from?.code ?? from?.symbol

    return (
        <Transition show={open} as={Fragment}>
            {/* Add the show prop to Transition */}
            <Dialog as="div" className="relative z-[100]" onClose={() => { return; }}> {/* Change setOpen(true) to setOpen(false) to close the dialog */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-[#000000cd] bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full text-white max-w-3xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                                <div className="p-8">
                                    <p className='text-28 font-600 text-center'>Confirmation</p>
                                    <div className="content p-4">
                                        {
                                            (userInput !== undefined && userInput?.selectedCoin) && (
                                                <>
                                                    <h6 className='text-gray text-14 mb-4'>Currency Name</h6>
                                                    <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>
                                                        {userInput?.selectedCoin?.symbol}</p>
                                                </>

                                            )
                                        }
                                        {
                                            (from && to) && (
                                                <>
                                                    <h6 className='text-gray text-14 mb-4'>From Currecy</h6>
                                                    <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>{from?.tokenId ? `${from?.name?.toUpperCase()} (${from?.symbol})`:from?.name }</p>
                                                    <h6 className='text-gray text-14 mb-4'>To Currecy</h6>
                                                    <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>{to?.tokenId ? `${to?.name?.toUpperCase()} (${to?.symbol})`:to?.name }</p>
                                                </>

                                            )
                                        }
                                        <h6 className='text-gray text-14 mb-4'>Address</h6>
                                        <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>{userInput ? userInput?.address : address}</p>
                                        <h6 className='text-gray text-14 mb-4'>Amount</h6>
                                        <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>{userInput ? parseFloat(userInput?.amount) : parseFloat(amount)} {tokenCodeORsymbol}</p>

                                        {/* <h6 className='text-gray text-14 mb-4'>Estimate Fee {feeLevel?.level ? `(` + feeLevel?.level + `)` : "(low)"}</h6>
                                        <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>
                                            {
                                                (feeLevel?.networkFee ?
                                                    parseFloat(fixDecimal(feeLevel?.networkFee)) :
                                                    parseFloat(fixDecimal(feeLevel?.low?.networkFee)))
                                            } {userInput?.selectedCoin?.tokenCode ?? userInput?.selectedCoin?.code ?? userInput?.selectedCoin?.symbol ?? from?.code ?? from?.symbol}
                                        </p> */}
                                        {/* {
                                            !(userInput?.selectedCoin?.tokenId) && (
                                                <>
                                                    <h6 className='text-gray text-14 mb-4'>Total payable amount</h6>
                                                    <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>
                                                        {payableAmount ?? '0.00'}
                                                    </p>
                                                </>
                                            )
                                        } */}
                                        {
                                            userInput && (
                                                <>
                                                    <h6 className='text-gray text-14 mb-4'>Current Balance</h6>
                                                    <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>
                                                   { parseFloat(fixDecimal(currencyBalance[0]?.newBalance)) || "0.0"}  {currencyBalance[0]?.symbol ?? ""}
                                                    </p></>)
                                        }

                                        {
                                            userInput?.tag && (
                                                <>
                                                    <h6 className='text-gray text-14 mb-4'>Meta Tag</h6>
                                                    <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>
                                                        {userInput?.tag}
                                                    </p>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className="grid sm:grid-cols-2 items-center gap-[40px]">
                                        <Button className="w-full !text-14 text-white mt-2" color="error" onClick={handleClose}>Cancel</Button>
                                        <Button className="w-full !text-14 text-white mt-2" onClick={() => handleSubmitConfirm()}>Request</Button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default SendConfirmationDialog
