import React from 'react'

const IcnPending = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" >
            <g>
                <path d="M458.406 380.681c-8.863-6.593-21.391-4.752-27.984 4.109A217.989 217.989 0 0 1 418.889 399c-7.315 8.275-6.538 20.915 1.737 28.231a19.923 19.923 0 0 0 13.239 5.016c5.532 0 11.04-2.283 14.992-6.754a257.803 257.803 0 0 0 13.658-16.829c6.593-8.861 4.754-21.391-4.109-27.983zM491.854 286.886c-10.786-2.349-21.447 4.496-23.796 15.288a215.372 215.372 0 0 1-4.646 17.681c-3.261 10.554 2.651 21.752 13.204 25.013 1.967.607 3.955.896 5.911.896 8.54 0 16.448-5.514 19.102-14.102a255.373 255.373 0 0 0 5.514-20.98c2.349-10.792-4.496-21.446-15.289-23.796zM362.139 444.734a216.715 216.715 0 0 1-16.34 8.233c-10.067 4.546-14.542 16.392-9.996 26.459 3.34 7.396 10.619 11.773 18.239 11.773 2.752 0 5.549-.571 8.22-1.777a256.456 256.456 0 0 0 19.377-9.764c9.645-5.384 13.098-17.568 7.712-27.212-5.383-9.643-17.567-13.096-27.212-7.712zM236 96v151.716l-73.339 73.338c-7.81 7.811-7.81 20.474 0 28.284a19.935 19.935 0 0 0 14.143 5.858 19.943 19.943 0 0 0 14.143-5.858l79.196-79.196A20.001 20.001 0 0 0 276 256V96c0-11.046-8.954-20-20-20s-20 8.954-20 20z" fill="currentcolor" opacity="1" class=""></path>
                <path d="M492 43c-11.046 0-20 8.954-20 20v55.536C425.448 45.528 344.151 0 256 0 187.62 0 123.333 26.629 74.98 74.98 26.629 123.333 0 187.62 0 256s26.629 132.667 74.98 181.02C123.333 485.371 187.62 512 256 512c.169 0 .332-.021.5-.025.168.004.331.025.5.025 7.208 0 14.487-.304 21.637-.902 11.007-.922 19.183-10.592 18.262-21.599-.923-11.007-10.58-19.187-21.6-18.261-6.044.505-12.2.762-18.299.762-.169 0-.332.021-.5.025-.168-.004-.331-.025-.5-.025-119.103 0-216-96.897-216-216S136.897 40 256 40c76.758 0 147.357 40.913 185.936 106h-54.993c-11.046 0-20 8.954-20 20s8.954 20 20 20H448a63.612 63.612 0 0 0 33.277-9.353 19.968 19.968 0 0 0 1.796-1.152C500.479 164.044 512 144.347 512 122V63c0-11.046-8.954-20-20-20z" fill="currentcolor" opacity="1" data-original="#000000" class=""></path>
            </g>
        </svg>
    )
}

export default IcnPending