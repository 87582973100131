import React from "react";

const SwapDetail = () => {
  return (
    <section className="lg:pt-[100px] pt-[40px] lg:pb-[50px] pb-[20px]">
      <div className="lg:container">
        <div className="grid md:grid-cols-2 items-center md:gap-0 gap-8">
          <img
            src={require("../../../assets/images/WebSwapDetail.webp")}
            className="w-[70%] mx-auto md:max-w-full max-w-[400px]"
            alt=""
            data-aos="zoom-in" data-aos-duration="1500"
          />
          <ul className="flex flex-col lg:gap-12 gap-4 max-w-[500px] mx-auto sm:px-0 px-6">
            <li className="relative sm:pt-[40px] pt-[57px] sm:ps-[30px] ps-[36px]" data-aos="fade-left" data-aos-duration="1500">
              <div className="left-0 absolute top-0">
                <h2 className="text-stroke stroke-1 stroke-gray/50 sm:text-[64px] text-[40px] absolute left-0 top-0 z-0">01</h2>
                <h2 className="text-fill text-light-gray/30 sm:text-[64px] text-[40px] absolute left-0 top-0 z-0">01</h2>
              </div>
              <h2 className="sm:text-[38px] text-22 font-600 sm:mb-4 relative capitalize">Swap trending tokens</h2>
              <p className="xl:text-18 font-500">Swap all the latest trending tokens, in one place.</p>
            </li>
            <li className="relative sm:pt-[40px] pt-[57px] sm:ps-[30px] ps-[36px]" data-aos="fade-right" data-aos-duration="1500">
              <div className="left-0 absolute top-0">
                <h2 className="text-stroke stroke-1 stroke-gray/50 sm:text-[64px] text-[40px] absolute left-0 top-0 z-0">02</h2>
                <h2 className="text-fill text-light-gray/30 sm:text-[64px] text-[40px] absolute left-0 top-0 z-0">02</h2>
              </div>
              <h2 className="sm:text-[38px] text-22 font-600 sm:mb-4 relative capitalize">We find the best rates</h2>
              <p className="xl:text-18 font-500">Our swap feature guarantees you a wide range of tokens and the best prices.</p>
            </li>
            <li className="relative sm:pt-[40px] pt-[57px] sm:ps-[30px] ps-[36px]" data-aos="fade-left" data-aos-duration="1500">
              <div className="left-0 absolute top-0">
                <h2 className="text-stroke stroke-gray/50 sm:text-[64px] text-[40px] absolute left-0 top-0 z-0">03</h2>
                <h2 className="text-fill text-light-gray/30 sm:text-[64px] text-[40px] absolute left-0 top-0 z-0">03</h2>
              </div>
              <h2 className="sm:text-[38px] text-22 font-600 sm:mb-4 relative capitalize">Swap in microseconds.</h2>
              <p className="xl:text-18 font-500">Lightning-fast swaps get you real market prices in real-time.</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SwapDetail;
