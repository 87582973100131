import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../../components/ui/Button'
import verifyDevice from "../../assets/images/verifyDevice.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import UseStore from '../../context/AuthProvider'
import { SocketContext } from '../../context/SocketProvider'
import { USER_API_URL } from '../../api/api'
import { useMutation } from 'react-query'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const VerifyDevice = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { setPathName, socketClient } = useContext(SocketContext);
  const state = UseStore();
  const [user, setUser] = useState();
  const axiosInstance = useAxiosPrivate();
  const { setTempUser, userEmail, setReSendOtp } = useContext(SocketContext);
  const { enqueueSnackbar } = useSnackbar()
  const {t} = useTranslation();
  useEffect(() => {
    if (location) {
      setPathName({ path: location?.pathname });
    }
  }, [location, setPathName]);



  useEffect(() => {
    setUser(state?.user);
  }, [state])



  const handleClick = async () => {
    if (location?.state?.type === 1) {
      navigate("/create-phrase")
    } else {
      navigate('/login', { state: { type: 2 } })  //type 2 for login 
    }

  }
  const sendMailAgain = async () => {
   
    const data = {
      email: userEmail?.email,
    }
    await sendMail(data)
  }

  const { mutateAsync: sendMail } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.sendEmailToVerifyLogin, data)
        if(response){
          setTempUser(response?.data?.token);
          setReSendOtp(1)
          // socketClient.emit('verifyUser', data);
          enqueueSnackbar(t('Please_check_mail_to_verify'), {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          navigate("/login/verify-device", { state: { type: 2 } })
        }
      } catch (error) {
        console.log(error);
      }
    }
  );

  return (
    <section className='verify-device bg-light-200 sm:p-20 p-10 rounded-[30px] max-w-[600px] mx-auto'>
      <img className='w-100 max-w-[150px] mx-auto mb-12' src={verifyDevice} alt="" />
      <h6 className='text-20 text-center mb-6 font-600'>Verify Device</h6>
      <p className='text-14 w-[80%] mx-auto font-400 text-light-600'>If you have an account registered with {userEmail?.email} email address, you will receive an email with a link to verify your device.</p>

      {
        user && (
          <>
            <p className='mt-8 text-14 font-600 text-center'>{(user?.email) ? (user?.email) : "email"}</p>
            <div className='max-w-max mx-auto bg-[#54B83133] rounded-[30px] p-4 text-14 text-[#54B831] w-[72px] mt-8'>{user?.isVerify === 1 && "verified"}</div>

          </>
        )
      }
      <div className="text-center mb-8 mt-20">
        {
          !user?.isVerify &&
          (<Link className='text-16 font-600' onClick={sendMailAgain}>Send Again</Link>)
        }
      </div>
      {
        user?.isVerify && (
          <Button className="w-full" size={"sm"} onClick={handleClick}>
            Continue
          </Button>
        )
      }

    </section>
  )
}


export default VerifyDevice