import React from 'react';

const IcnCopy = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8712 3.49805H3.57367C2.70126 3.49805 1.99268 4.20663 1.99268 5.07904V18.4192C1.99268 19.2916 2.70126 20.0002 3.57367 20.0002H12.8712C13.7436 20.0002 14.4522 19.2916 14.4522 18.4192V5.07904C14.4481 4.20663 13.7396 3.49805 12.8712 3.49805ZM13.3423 18.4151C13.3423 18.6772 13.1293 18.8902 12.8671 18.8902H3.56958C3.30744 18.8902 3.09446 18.6772 3.09446 18.4151V5.07904C3.09446 4.81691 3.30744 4.60392 3.56958 4.60392H12.8671C13.1293 4.60392 13.3423 4.81691 13.3423 5.07904V18.4151Z" fill="currentColor" />
            <path d="M16.4264 0H7.12885C6.25643 0 5.54785 0.708581 5.54785 1.58099C5.54785 1.88818 5.7936 2.13393 6.10079 2.13393C6.40798 2.13393 6.65373 1.88818 6.65373 1.58099C6.65373 1.31886 6.86671 1.10588 7.12885 1.10588H16.4264C16.6885 1.10588 16.9015 1.31886 16.9015 1.58099V14.9212C16.9015 15.1833 16.6885 15.3963 16.4264 15.3963C16.1192 15.3963 15.8735 15.642 15.8735 15.9492C15.8735 16.2564 16.1192 16.5021 16.4264 16.5021C17.2988 16.5021 18.0074 15.7936 18.0074 14.9212V1.58099C18.0074 0.708581 17.2988 0 16.4264 0Z" fill="currentColor" />
        </svg>

    );
}

export default IcnCopy;
