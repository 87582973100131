import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Logo from "../../assets/images/Logo.svg"
import authImage from "../../assets/images/authImage.png"

const AuthMaster = () => {
  const navigate = useNavigate();

  const homepage = () => {
    navigate("/")
  }
  return (
    <main className='auth-main h-screen w-full overflow-y-auto theme-scrollbar'>
      <div className="grid grid-cols-1 lg:grid-cols-5">
        <div className="bg-dark sticky top-0 left-0 h-screen w-full hidden lg:block p-20 col-span-2">
          <img src={authImage} alt='' className='h-full m-auto' />
        </div>
        <div className="grid items-center col-span-3">
          <div className="sm:px-[100px] px-[20px] py-6">
            <div className="logo-part md:mb-[50px] mb-[20px] cursor-pointer">
              <img src={Logo} alt="" className='mx-auto lg:h-[80px] sm:h-[60px] h-[50px]' onClick={homepage} />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  )
}

export default AuthMaster