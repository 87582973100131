import React, { useContext, useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { _randomString } from '../../Helper';
import { AuthSocketContext } from '../../context/AuthSocketProvider';
import Loader from '../../components/ui/Loader';
import { useTranslation } from 'react-i18next';

const SwitchWallet = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [key, setKey] = useState();
    const { setAuthKey } = useContext(AuthSocketContext)
    const {t} = useTranslation();

    useEffect(() => {
        const randomeKey = _randomString(16);
        setTimeout(() => {
            setIsLoading(false);
            setKey(randomeKey);
        }, 2000);
        setAuthKey(randomeKey);
    }, []);


    return (
        <>

            <div className="p-12 bg-dark h-full">
                <div className='sm:w-[80%] bg-card h-full mx-auto rounded-[20px] border border-light-100 flex flex-col p-12'>
                    <div className="flex justify-between items-center mb-12">
                        <p className='text-white text-20 font-500'>{t('Change_Wallet')}</p>

                    </div>
                    <div className="h-[calc(100%-30px)] flex flex-col items-center justify-center">
                        <div className="border border-light-100 p-[12px] rounded-[16px]">
                            <QRCode
                                size={180}
                                style={{ height: "auto", maxWidth: "100%", width: "100%", borderRadius: '10px' }}
                                value={key??""}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <div className='mt-[24px]'>
                            <h5 className='!text-white flex justify-center items-center font-normal mb-[12px]'>Scan QR Code to Change Your wallet</h5>
                            <p className='!text-white flex justify-center items-center font-normal text-center max-w-[50%] m-auto'>First you need to change wallet from your oppi wallet mobile app and then scan QR code from home page</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SwitchWallet