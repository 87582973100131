
import * as Yup from "yup";
export const registerSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required("Email is required"),
  password: Yup.string().required('Password is required').matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
    'Password must contain at least 6 characters, one letter, one number, and one special character'
  ),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  country: Yup.string().required('Country is required')

});

export const updateProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required"),
  user_name: Yup.string()
    .required("User name is required"),
  email: Yup.string()
    .email('Invalid email address')
    .required("Email is required"),
  mobile_number: Yup.string().required('Mobile is required'),


});
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required("Email is required"),
  // password: Yup.string().required("Password is required"),
});