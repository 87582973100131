import React from 'react';
import IconHistory from '../../assets/svg/IconHistory';
import SearchIcon from '../../assets/images/SearchIcon.png'
import Input from '../../components/ui/Input';
import { useNavigate } from 'react-router-dom';

const Browser = () => {
  const navigate = useNavigate();
  const browserHistory = () => {
    navigate("/dashboard/browser-history");
  }
  return (
    <div className="p-12 bg-dark h-full">
      <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100 flex flex-col p-12'>
        <div className="flex justify-between items-center mb-8">
          <p className='text-white text-20 font-500'>Browser</p>
          <div className="py-2 px-8 bg-dark flex items-center gap-4 rounded-full border border-light-100 cursor-pointer" onClick={browserHistory}>
            <IconHistory className="text-white w-[20px]" />
            <p className='text-white'>History</p>
          </div>
        </div>
        <div className="relative mb-12">
          <Input className="ps-[36px] py-4 bg-dark" placeholder="Search or Enter Website URL" />
          <img className='absolute top-[12px] left-[16px] cursor-pointer' src={SearchIcon} alt="" />
        </div>
        <div className="h-full flex flex-col justify-center items-center flex-grow">
          <img src={require('../../assets/images/noSearch.svg').default} alt="" />
          <p className='text-white text-18'>No Search</p>
        </div>
      </div>
    </div >
  )
}

export default Browser