import React, { useEffect, useState } from 'react'
import { Button } from '../ui/Button'
import NftDetailHero from "../../assets/images/NftDetailHero.png"
import SendNftModal from '../model/SendNftModal'
import SuccessMessageModal from '../model/SuccessMessageModal'
import { useLocation } from 'react-router-dom'
import { files } from '../../Helper'
import axios from 'axios'
import defaultimage from '../../assets/images/logo.png'


const NftDetails = () => {
  const [openSendNftModal, setOpenSendNftModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [nftDetails, setNftDetails] = useState()
  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      setNftDetails(state?.nftDetail)
    }
  }, [state])
  const handleOpenSendNftModal = () => {
    setOpenSendNftModal(true)
  }
  const handleCloseSendNftModal = () => {
    setOpenSendNftModal(false)
  }
  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true)
  }
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false)
  }
  if (nftDetails) {
    axios.get(nftDetails?.cachedMetadataURI)
      .then((res) => {
        console.log(res, 'rsesss');
      })
      .catch((error) => {
        console.error("Error fetching metadata:", error);
      });
  }

  console.log(nftDetails, 'nftDetails');

  return (
    <div className="p-12 bg-[#000] h-full">
      <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100'>
        <div className="p-12">
          {nftDetails?.media && nftDetails?.media.length === 0 ?
            <img className='w-full aspect-[9/4] object-cover object-center rounded-[20px]' src={defaultimage} alt="" />
            :
            <img className='w-full aspect-[9/4] object-cover object-center rounded-[20px]' src={nftDetails?.media?.[0]?.url} alt="" />
          }
          <div className='p-[12px] flex justify-between last:border-0 bg-[#000] rounded-[12px] my-8'>
            <div className="flex gap-[14px] items-center">
              <div className="text">
                <h5 className='text-white xxl:text-18 text-16'>{nftDetails?.name}</h5>
                <p className='text-gray text-12'>#{nftDetails?.tokenId}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-6">
            <p className='text-gray text-14'>Token Standard</p>
            <p className='text-white text-14'>{nftDetails?.standard}</p>
          </div>
          <div className="flex justify-between mb-6">
            <p className='text-gray text-14'>Asset contract</p>
            <p className='text-theme text-14'>{nftDetails?.collection?.id}</p>
          </div>
          <div className="flex justify-between">
            <p className='text-gray text-14'>Source</p>
            <p className='text-theme text-14'>http://192.168.20.125:5000/</p>
          </div>
          <p className='text-white text-14 mt-8 mb-6'>Description</p>
          <p className='text-gray text-12 mb-8'>{nftDetails?.description ? nftDetails?.description : '-'}</p>

          <Button className="w-full mt-20" size="sm" onClick={handleOpenSendNftModal}>Send</Button>

          <SendNftModal open={openSendNftModal} handleClose={handleCloseSendNftModal} handleOpenSuccessModal={handleOpenSuccessModal} nftDetails={nftDetails} />

          <SuccessMessageModal
            open={openSuccessModal}
            handleClose={handleCloseSuccessModal}
            message={"Send NFT Successfully"}
          />
        </div>
      </div>
    </div>
  )
}

export default NftDetails