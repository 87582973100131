import React from "react";
import { Button } from "../../../components/ui/Button";
import { useTranslation } from "react-i18next";

const DownloadApp = ({ setStep }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col justify-center items-center py-20 h-full my-10">
        <img
          src={require("../../../assets/images/GoogleAuth.svg").default}
          className="w-[120px]"
          alt=""
        />
        <p className="text-gray font-400 max-w-[300px] text-center mt-6">
          {t('install_google_auth_app')}
        </p>
        <div className="flex mt-16">
          <img
            src={require("../../../assets/images/GooglePlay.png")}
            className="w-[50%] cursor-pointer"
            alt=""
          />
          <img
            src={require("../../../assets/images/AppStore.png")}
            className="w-[50%] cursor-pointer"
            alt=""
          />
        </div>
      </div>
      <div className="py-8">
        <Button className="w-full text-[14px] text-white" size="sm" onClick={() => setStep(2)}>
          {t('Next')}
        </Button>
      </div>
    </>
  );
};

export default DownloadApp;
