import React from "react";
import SwapDetail from "../../../components/website/Swap/SwapDetail";
import HowItWorks from "../../../components/website/Swap/HowItWorks";
import SwapWithConfidence from "../../../components/website/Swap/SwapWithConfidence";
import SwapExplore from "../../../components/website/Swap/SwapExplore";
import { Helmet } from "react-helmet-async";

const Swaps = () => {
  return (
    <>
      <section className="bg-black md:py-[125px] py-[40px] relative">
        <Helmet prioritizeSeoTags>
          <title>Swap crypto directly from the Oppi Wallet mobile app and web wallet.
          </title>
          <meta name="description" content="Swapping crypto allows you to easily exchange one crypto asset for another. With Oppi Wallet we will give you the possibility to swap quickly and securly your assets." />
          <link rel="canonical" href="https://oppiwallet.com/swap" />

          <meta property="og:url" content="https://oppiwallet.com/swap" />
          <meta property="og:title" content="Swap crypto directly from the Oppi Wallet mobile app and web wallet." />
          <meta property="og:description" content="Swapping crypto allows you to easily exchange one crypto asset for another. With Oppi Wallet we will give you the possibility to swap quickly and securly your assets." />

        </Helmet>
        <div className="container">
          <div className="grid md:grid-cols-2 items-center md:gap-0 gap-8">
            <div className="flex flex-col md:text-start text-center max-w-[500px] gap-8 mx-auto" data-aos="fade-right" data-aos-duration="1500">
              <h5 className="text-white font-600 lg:text-[42px] text-28">
                Seamless <br /> swapping options
              </h5>
              <p className="text-white sm:text-18 font-500 leading-[36px] max-w-[90%]">
                Swap crypto directly from the Oppi Wallet mobile app and web
                wallet.
              </p>
            </div>
            <img
              src={require("../../../assets/images/WebSwap.webp")}
              className="h-full aspect-square mx-auto md:max-w-full max-w-[80%]"
              alt=""
              data-aos="zoom-in" data-aos-duration="1500"
            />
          </div>
        </div>
        <div className="icons md:block hidden">
          <img
            src={require("../../../assets/images/WebIcon1.svg").default}
            className="absolute z-0 lg:left-[100px] left-[60px] top-[8%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon2.svg").default}
            className="absolute z-0 lg:left-[35px] left-[40px] top-[60%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon2.svg").default}
            className="absolute z-0  left-[45%] top-[80%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon2.svg").default}
            className="absolute z-0 lg:right-[260px] md:right-[180px] right-[40px] top-[8%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon3.svg").default}
            className="absolute z-0 lg:right-[160px] md:right-[140px] right-[60px] top-[80%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon4.svg").default}
            className="absolute z-0 left-[180px] top-[85%] animate-bounce"
            alt=""
          />
        </div>
      </section>
      <SwapDetail />
      <HowItWorks />
      <SwapWithConfidence />
      <SwapExplore />
    </>
  );
};

export default Swaps;
