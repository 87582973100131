import React, { useEffect, useRef, useState } from 'react'
import { RadioGroup, Disclosure } from '@headlessui/react';
import IconPlus from '../../assets/svg/IconPlus';
import IconLess from '../../assets/svg/IconLess';
import { Helmet } from 'react-helmet-async';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { FAQ_URL } from '../../api/api';

const days = ["Generals", "Service", "Payment", "Refund", "Return"]
const faqList = [
    { que: 'What does OppiWallet provide?' },
    { que: 'What is the investment process like? How long it takes?' },
    { que: 'How does Rental work legally? Is it legal?' },
    { que: 'What is the investment process like? How long it takes?' },
    { que: 'How do we pay interest/dividends?' },
    { que: 'How does Rental work legally? Is it legal?' },
    { que: 'How do we pay interest/dividends?' },
    { que: 'How to declare interest received?' }
]

const Faq = () => {
    const buttonRefs = useRef([]);
    const openedRef = useRef(null);
    const [plan, setPlan] = useState(days[0]);
    const [faqData, setFaqData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [faqTitles, setFaqTitles] = useState([]);
    const axiosInstance = useAxiosPrivate();


    const clickRecent = (index = 0) => {
        const clickedButton = buttonRefs.current[index];
        if (clickedButton === openedRef.current) {
            openedRef.current = null;
            return;
        }
        if (Boolean(openedRef.current?.getAttribute("data-value"))) {
            openedRef.current?.click();
        }
        openedRef.current = clickedButton;
    };



    async function getFaqs() {
        const res = await axiosInstance.get(FAQ_URL.getFaqs);
        if (res?.status === 200) {
            setFaqData(res?.data)
            // setIsLoading(false);
        } else {
            console.log('error');
        }
    }

    useEffect(() => {
        if (faqData) {
            const filteredData = faqData?.filter((dt) => dt?.title === plan?.name);
            setFilterData(filteredData[0]?.data);
        }
    }, [faqData, plan])

    useEffect(() => {
        if (faqData) {
            const faqTitles = faqData?.map((obj, index) => ({
                name: obj.title,
                value: index + 1
            }));
            setFaqTitles(faqTitles)
            setPlan(faqTitles[0])
        }
    }, [faqData])

    useEffect(() => {
        getFaqs();
    }, [])

    return (
        <section className='container faq_section md:py-[100px] py-[40px]'>
            <Helmet prioritizeSeoTags>
                <title>FAQ | Oppi Wallet
                </title>
                <meta name="description" content="Explore our FAQ page for answers to commonly asked questions, expert insights, and helpful tips. Frequently asked questions about Oppi Wallet." />
                <link rel="canonical" href="https://oppiwallet.com/faq" />


                <meta property="og:title" content="FAQ | Oppi Wallet" />
                <meta property="og:description" content="Explore our FAQ page for answers to commonly asked questions, expert insights, and helpful tips. Frequently asked questions about Oppi Wallet." />
                <meta property="og:url" content="https://oppiwallet.com/faq" />

            </Helmet>
            <div className="text-center md:mb-[50px] mb-[30px] md:max-w-[800px] w-full md:mx-auto ">
                <h2 className='md:text-[40px] sm:text-[36px] text-[28px] mb-[16px]'>Frequently Asked Questions</h2>
                <p className='text-14 font-500 text-light-600 mb-[22px]'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                <RadioGroup value={plan} onChange={setPlan} className='flex items-center justify-center md:gap-[20px] gap-[12px] flex-wrap'>
                    {faqTitles?.map((time, index) => (
                        <RadioGroup.Option key={index} value={time} onChange={(e) => setPlan(e)} className={"cursor-pointer"}>
                            {({ checked }) => (
                                <span className={`block p-[8px_20px] rounded-full border-light-gray md:text-[16px] text-[14px] font-500 border ${checked ? 'bg-linear text-white border-0 font-600' : 'text-light-800'}`}>{time?.name}</span>
                            )}
                        </RadioGroup.Option>
                    ))}
                </RadioGroup>
            </div>
            <div className="faq_wrapper">
                {
                    filterData?.length > 0 ?
                        filterData?.map((faq, index) => {
                            return <Disclosure key={index}>
                                {({ open }) => (
                                    <div className='md:py-[16px] py-[10px] md:px-[24px] px-[12px] bg-light-200 border border-light-gray mb-[10px] md:rounded-[20px] rounded-[8px] last:mb-0'>
                                        <Disclosure.Button as="div" className="cursor-pointer flex items-start justify-between gap-4" data-value={open} ref={(ref) => { buttonRefs.current[index] = ref }} onClick={() => clickRecent(index)}>
                                            <h6 className='md:text-[16px] text-[14px] font-500 text-card'>{faq.question}</h6>
                                            <span className='mt-1'>{open ? <IconLess className={`md:w-[24px] w-[16px] md:h-[24px] h-[16px] text-light-600`} /> : <IconPlus className={`md:w-[24px] w-[16px] md:h-[24px] h-[16px] text-light-600`} />}</span>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="pt-[12px]">
                                            <p className='text-14 font-400 text-light-600'>{faq?.answer}</p>
                                        </Disclosure.Panel>
                                    </div>
                                )}
                            </Disclosure>
                        })
                        : <div class="flex items-center justify-center flex-col gap-8 min-h-[120px] md:mt-[100px] mt-[40px]">
                            <svg class="md:h-36 md:w-36 h-28 w-28 text-black"
                                viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M196.994 177.948L177.368 159.022" stroke="currentcolor"></path><path d="M184.875 38.1113C196.909 53.5981 204.074 73.0556 204.074 94.186C204.074 144.704 163.12 185.658 112.602 185.658C62.0828 185.658 21.1294 144.704 21.1294 94.186C21.1294 43.6675 62.0828 2.71411 112.602 2.71411C131.074 2.71411 148.268 8.19004 162.648 17.6072" stroke="currentcolor"></path><path d="M261.647 201.732L227.634 172.311C216.912 163.036 200.701 164.21 191.427 174.932L191.427 174.932C182.152 185.654 183.326 201.864 194.048 211.139L228.061 240.56C238.783 249.835 254.994 248.661 264.268 237.939L264.268 237.939C273.543 227.217 272.369 211.007 261.647 201.732Z" fill="currentcolor"></path><path d="M111.901 299.91C158.935 299.91 197.064 292.536 197.064 283.438C197.064 274.341 158.935 266.966 111.901 266.966C64.8661 266.966 26.7371 274.341 26.7371 283.438C26.7371 292.536 64.8661 299.91 111.901 299.91Z" fill="currentcolor"></path><path d="M96.8298 132.387C103.384 124.096 117.558 120.292 128.372 132.387" stroke="currentcolor"></path><path d="M86.3161 90.3309C89.8001 90.3309 92.6245 86.5651 92.6245 81.9197C92.6245 77.2743 89.8001 73.5085 86.3161 73.5085C82.8321 73.5085 80.0077 77.2743 80.0077 81.9197C80.0077 86.5651 82.8321 90.3309 86.3161 90.3309Z" fill="currentcolor"></path><path d="M139.587 91.0318C143.071 91.0318 145.895 87.266 145.895 82.6206C145.895 77.9752 143.071 74.2094 139.587 74.2094C136.103 74.2094 133.278 77.9752 133.278 82.6206C133.278 87.266 136.103 91.0318 139.587 91.0318Z" fill="currentcolor"></path><path d="M154.819 103.45C151.46 104.345 151.156 100.233 151.587 96.7556C151.72 95.6783 152.996 95.2977 153.704 96.1199C156.067 98.8647 158.202 102.547 154.819 103.45Z" fill="currentcolor"></path>
                            </svg>
                            <h5 class="text-gray text-20 -ms-3">Data Not Found</h5>
                        </div>
                }

            </div>
        </section>
    )
}

export default Faq