import React from 'react'

const IconCoinSend = (props) => {
  return (
    <svg {...props} viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.3605 3.6393C12.4543 3.73306 12.5069 3.86024 12.5069 3.99285L12.5069 9.7157C12.5069 9.99184 12.2831 10.2157 12.0069 10.2157C11.7308 10.2157 11.5069 9.99184 11.5069 9.7157L11.5069 5.19996L4.34661 12.3603C4.15135 12.5555 3.83476 12.5555 3.6395 12.3603C3.44424 12.165 3.44424 11.8484 3.6395 11.6532L10.7998 4.49285L6.28408 4.49285C6.00794 4.49285 5.78408 4.26899 5.78408 3.99285C5.78408 3.71671 6.00794 3.49285 6.28408 3.49285L12.0069 3.49285C12.1395 3.49285 12.2667 3.54553 12.3605 3.6393Z" fill="currentcolor" />
    </svg>
  )
}

export default IconCoinSend