import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { files } from '../../../Helper';

const CryptoDetail = () => {
  const { state } = useLocation();
  const [details, setDetails] = useState()

  useEffect(() => {
    if (state) {
      setDetails(state?.blogDetail)
    }
  }, [state])


  return (
    <div className="container">
      <div className="max-w-[800px] mx-auto py-20">
        <div className="text md:max-w-[80%] mx-auto">
          <h4 className="lg:text-32 sm:text-28 text-22 font-600 mb-6 text-center">{details?.categoriesName}</h4>
          <h5 className='sm:text-18 text-16 text-center font-500 text-black/80 mb-12'>{details?.title}</h5>
        </div>
        <div className="image">
          <img src={files(details?.image, 'image')} alt="attachment" className='w-full' />
        </div>
        <div className="content-wrapper">
          <div className="content pt-12">
        
            <div 
              dangerouslySetInnerHTML={{
                __html: details?.description,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CryptoDetail