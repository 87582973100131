import React, { useContext, useState } from 'react'
import { Button } from '../../components/ui/Button'
import { Link, useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { isEmpty } from 'lodash'
import { registerSchema } from '../../validation/AuthValidation'
import { SocketContext } from '../../context/SocketProvider'
import AuthInput from '../../components/ui/AuthInput'
import AuthInputPassword from '../../components/ui/AuthInputPassword'
import CountryList from '../../components/ui/CountryList'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { useMutation } from 'react-query'
import { USER_API_URL } from '../../api/api'



const Register = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [acceptTerms, setAcceptTerms] = useState("");
  const [country, setCountry] = useState();
  const axiosInstance = useAxiosPrivate();
  const { setTempUser } = useContext(SocketContext);


  // handle terms

  const handleTrems = (e) => {
    if (e.target.checked) {
      setAcceptTerms(1)
    } else {
      setAcceptTerms(0)
    }
  }
  // Register form submit
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      country: "",
    },
    validationSchema: registerSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (acceptTerms === 0 || acceptTerms === "") {
        enqueueSnackbar("Please accept terms and conditions first", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
          preventDuplicate: true
        });
        return;
      }
      const data = {
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        country: country,
        device: 3,
        terms: acceptTerms

      }
      await register(data);

    },
  });
  const { mutateAsync: register } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(
          USER_API_URL.register,
          data
        );
        if (response) {
          setTempUser(data?.data?.token);
          enqueueSnackbar("Please check your mail to verify!", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          navigate("/register/verify-device", { state: { type: 1 } })  
          setAcceptTerms(0)
        }
      } catch (error) {
        const errorData = error?.response?.data?.errors;
        const errorMessge = error?.message;
        if (!isEmpty(errorData)) {
          Object.keys(errorData).forEach((key) => {
            enqueueSnackbar(errorData[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        } else if (!isEmpty(errorMessge)) {
          enqueueSnackbar(errorMessge, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(error, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    }
  );


  const getCountryName = (value) => {
    setCountry(value);
    formik.setFieldValue('country', value);
  };
  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched
  } = formik;



  return (
    <>
      <section className='login-screen bg-light-200 sm:p-20 p-10 rounded-[30px] max-w-[600px] mx-auto'>
        <h4 className='sm:mb-12 mb-10 font-600'>
          Create an Account
        </h4>
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <div className="form-group mb-4">
              <AuthInput lable="Email"
                placeholder="Enter Email Name"
                {...getFieldProps('email')}
                errorMsg={errors.email && touched.email ? errors.email : null}
              />
            </div>
            <div className="form-group mb-4">
              <AuthInputPassword
                lable="Password"
                placeholder="Enter Password Here"
                errorMsg={errors.password && touched.password ? errors.password : null}
                {...getFieldProps('password')}
              />
            </div>
            <div className="form-group mb-4">
              <AuthInputPassword
                lable="Confirm Password"
                placeholder="Enter Confirm Password"
                errorMsg={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : null}
                {...getFieldProps('confirmPassword')}
              />
            </div>
            <div className="form-group mb-4">
              <label className='text-black text-16 font-500 mb-4'>Country of Residence</label>
              <CountryList errorMsg={errors.country && touched.country ? errors.country : null} onChildData={getCountryName} />
            </div>
            <div className='form-item flex items-center mt-6'>
              <input type="checkbox" className='accent-theme' id="custom_check" onChange={handleTrems} value={acceptTerms} />
              <label className='text-14 ml-4 text-dark-300 font-400 cursor-pointer' htmlFor="custom_check">I Agree oppi Crypto <Link className='font-500 underline'>Terms of Service</Link> & <Link className='font-500 underline'>Privacy Policy.</Link></label>
            </div>
            <Button type="submit" className="w-full mt-12" size={"sm"}>
              Continue
            </Button>
          </Form>
        </FormikProvider>
        <p className='text-gray text-16 mt-4 font-500 flex justify-center gap-3'>An already have account?<Link className='underline font-600 text-light-600' to={'/login'}>Login Here</Link></p>
      </section>
    </>
  );
};

export default Register;
