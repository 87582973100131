import React from 'react';

const IcnStep3 = (props) => {
    return (
        <svg {...props} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.53125 23.6395H22.3753V11.1364H0.53125V23.6395ZM2.54083 14.6562C2.77123 14.6562 2.99937 14.6108 3.21223 14.5226C3.42508 14.4344 3.61847 14.3052 3.78136 14.1422C3.94425 13.9793 4.07345 13.7858 4.16156 13.573C4.24968 13.3601 4.29499 13.1319 4.29492 12.9015H18.6111C18.6111 13.3669 18.7959 13.8132 19.125 14.1423C19.4541 14.4713 19.9004 14.6562 20.3658 14.6562V20.115C19.9004 20.115 19.4541 20.2999 19.125 20.6289C18.7959 20.958 18.6111 21.4043 18.6111 21.8697H4.29492C4.29499 21.6393 4.24968 21.4111 4.16156 21.1982C4.07345 20.9854 3.94425 20.7919 3.78136 20.629C3.61847 20.466 3.42508 20.3368 3.21223 20.2486C2.99937 20.1604 2.77123 20.115 2.54083 20.115V14.6562Z" fill="currentColor" />
            <path d="M11.4528 20.02C12.9064 20.02 14.0848 18.8416 14.0848 17.388C14.0848 15.9344 12.9064 14.756 11.4528 14.756C9.99919 14.756 8.8208 15.9344 8.8208 17.388C8.8208 18.8416 9.99919 20.02 11.4528 20.02Z" fill="currentColor" />
            <path d="M1.67871 10.2608H23.2521V20.3525L25.0138 20.1658L23.7071 7.73499L1.67871 10.2608Z" fill="currentColor" />
            <path d="M25.008 4.36914L2.8623 9.04397L24.483 6.77247L25.568 17.0858L27.3646 16.6541L25.008 4.36914Z" fill="currentColor" />
        </svg>

    );
}

export default IcnStep3;
