import React from "react";
import IcnStep1 from "../../../assets/svg/IcnStep1";
import IcnStep2 from "../../../assets/svg/IcnStep2";
import IcnStep3 from "../../../assets/svg/IcnStep3";
import IcnStep4 from "../../../assets/svg/IcnStep4";

const HowItWorks = () => {
  return (
    <>
      <section className="lg:py-[50px] py-[40px] bg-mid-gray">
        <div className="container">
          <h5 className="lg:text-40 text-32 font-600 text-center mb-[60px]" data-aos="fade-down" data-aos-duration="1500">How it Works</h5>
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-2 sm:gap-12 gap-[133px] sm:py-0 py-[20px]" data-aos="fade-right" data-aos-duration="1500">
            <div className="relative mx-auto">
              <img src={require("../../../assets/images/StepArrow.png")} alt="" className="w-full sm:rotate-0 rotate-[90deg]" />
              <div className="absolute sm:left-[22%] left-[41%] lg:-top-[18%] md:-top-[15%] sm:-top-[20%] top-0 flex sm:flex-row flex-row-reverse items-center xxl:gap-[18px] gap-[12px]">
                <div className="xxl:h-[72px] h-[60px] xxl:w-[72px] w-[60px] rounded-full flex items-center justify-center bg-green1 shadow-100 border-[3px] border-white">
                  <IcnStep1 className='h-[28px] w-[28px] text-white' />
                </div>
                <p className="sm:text-[22px] text-[20px] font-700 text-[#21A371]">Step 01</p>
              </div>
              <p className="text-18 font-600 absolute top-[45%] sm:left-12 left-[30%] w-[60%] capitalize">Select the swap option.</p>
            </div>
            <div className="relative mx-auto">
              <img src={require("../../../assets/images/StepArrow.png")} alt="" className="w-full sm:rotate-0 rotate-[90deg]" />
              <div className="absolute sm:left-[22%] left-[41%] lg:-top-[18%] md:-top-[15%] sm:-top-[20%] top-0 flex sm:flex-row flex-row-reverse items-center xxl:gap-[18px] gap-[12px]">
                <div className="xxl:h-[72px] h-[60px] xxl:w-[72px] w-[60px] rounded-full flex items-center justify-center bg-[#EBA900] shadow-100 border-[3px] border-white">
                <IcnStep2 className='h-[28px] w-[28px] text-white' />
                </div>
                <p className="sm:text-[22px] text-[20px] font-700 text-[#EBA900]">Step 02</p>
              </div>
              <p className="text-18 font-600 absolute top-[45%] sm:left-12 left-[30%] w-[60%] capitalize">Choose the asset you want to swap.</p>
            </div>
            <div className="relative mx-auto">
              <img src={require("../../../assets/images/StepArrow.png")} alt="" className="w-full sm:rotate-0 rotate-[90deg]" />
              <div className="absolute sm:left-[22%] left-[41%] lg:-top-[18%] md:-top-[15%] sm:-top-[20%] top-0 flex sm:flex-row flex-row-reverse items-center xxl:gap-[18px] gap-[12px]">
                <div className="xxl:h-[72px] h-[60px] xxl:w-[72px] w-[60px] rounded-full flex items-center justify-center bg-[#2A4687] shadow-100 border-[3px] border-white">
                <IcnStep3 className='h-[28px] w-[28px] text-white' />
                </div>
                <p className="sm:text-[22px] text-[20px] font-700 text-[#2A4687]">Step 03</p>
              </div>
              <p className="text-18 font-600 absolute top-[45%] sm:left-12 left-[30%] w-[60%] capitalize">Type in your swap amount.</p>
            </div>
            <div className="relative mx-auto sm:mt-0 -mt-[31px]">
              <img src={require("../../../assets/images/StepArrow2.png")} alt="" className="w-full sm:rotate-0 rotate-[90deg]" />
              <div className="absolute sm:left-[22%] left-[41%] lg:-top-[18%] md:-top-[15%] sm:-top-[20%] top-0 flex sm:flex-row flex-row-reverse items-center xxl:gap-[18px] gap-[12px]">
                <div className="xxl:h-[72px] h-[60px] xxl:w-[72px] w-[60px] rounded-full flex items-center justify-center bg-[#4AB500] shadow-100 border-[3px] border-white">
                <IcnStep4 className='h-[28px] w-[28px] ' />
                </div>
              <p className="sm:text-[22px] text-[20px] font-700 text-[#4AB500]">Step 04</p>
              </div>
              <p className="text-18 font-600 absolute top-[45%] sm:left-12 left-[30%] w-[60%] capitalize">Click to confirm <br /> <span className="text-[#4AB500]">“ Swap“</span></p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="lg:py-[50px] py-[40px] bg-mid-gray">
        <div className="container">
          <h5 className="lg:text-40 text-32 font-600 text-center mb-[60px]" data-aos="fade-down" data-aos-duration="1500">How it Works</h5>
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 lg:gap-2 sm:gap-12 gap-[100px]" data-aos="fade-right" data-aos-duration="1500">
            <div className="relative mx-auto">
              <img src={require("../../../assets/images/Step1.png")} alt="" className="w-full sm:rotate-0 rotate-[90deg]" />
              <p className="absolute sm:left-[45%] left-[24%] sm:top-[6%] top-[15%] text-22 font-700 text-[#21A371]">Step 01</p>
              <p className="text-18 font-600 absolute top-[45%] sm:left-12 left-[24%] w-[60%] capitalize">Select the swap option.</p>
            </div>
            <div className="relative mx-auto">
              <img src={require("../../../assets/images/Step2.png")} alt="" className="w-full sm:rotate-0 rotate-[90deg]" />
              <p className="absolute sm:left-[45%] left-[24%] sm:top-[6%] top-[15%] text-22 font-700 text-[#EBA900]">Step 02</p>
              <p className="text-18 font-600 absolute top-[45%] sm:left-12 left-[24%] w-[60%] capitalize">Choose the asset you want to swap.</p>
            </div>
            <div className="relative mx-auto">
              <img src={require("../../../assets/images/Step3.png")} alt="" className="w-full sm:rotate-0 rotate-[90deg]" />
              <p className="absolute sm:left-[45%] left-[24%] sm:top-[6%] top-[15%] text-22 font-700 text-[#2A4687]">Step 03</p>
              <p className="text-18 font-600 absolute top-[45%] sm:left-12 left-[24%] w-[60%] capitalize">Type in your swap amount.</p>
            </div>
            <div className="relative mx-auto">
              <img src={require("../../../assets/images/Step4.png")} alt="" className="w-full sm:rotate-0 rotate-[90deg]" />
              <p className="absolute sm:left-[45%] left-[24%] sm:top-[6%] top-[15%] text-22 font-700 text-[#4AB500]">Step 04</p>
              <p className="text-18 font-600 absolute top-[45%] sm:left-12 left-[24%] w-[60%] capitalize">Click to confirm <br /> <span className="text-[#4AB500]">“ Swap“</span></p>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default HowItWorks;
