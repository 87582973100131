import React from "react";
import IconDone from "../../../assets/svg/IconDone";

const VirtualCardPlatform = () => {
  return (
    <section className="lg:pt-[100px] pt-0">
      <div className="container">
        <div className="grid md:grid-cols-2 items-center md:gap-0 gap-8">
          <div className="sm:max-w-[510px] mx-auto">
            <h5 className="sm:text-32 md:text-left text-center capitalize font-700 mb-8" data-aos="fade-down" data-aos-duration="1500">
              oppi wallet virtual card Platform
            </h5>
            <div className="flex lg:mb-10 mb-6" data-aos="fade-up" data-aos-duration="1500">
              <div className="grid place-content-center bg-linear w-[24px] p-3 h-max rounded-full me-6">
                <IconDone className="w-[12px] text-white" />
              </div>
              <p className="text-16 font-600">Buy Virtual Credit Cards</p>
            </div>
            <div className="flex lg:mb-10 mb-6" data-aos="fade-up" data-aos-duration="1200">
              <div className="grid place-content-center bg-linear w-[24px] p-3 h-max rounded-full me-6">
                <IconDone className="w-[12px] text-white" />
              </div>
              <p className="text-16 font-600">strong Auth Section</p>
            </div>
            <div className="flex lg:mb-10 mb-6" data-aos="fade-up" data-aos-duration="1000">
              <div className="grid place-content-center bg-linear w-[24px] p-3 h-max rounded-full me-6">
                <IconDone className="w-[12px] text-white" />
              </div>
              <p className="text-16 font-600">Fast & Secure Transaction</p>
            </div>
            <div className="flex lg:mb-10 mb-6" data-aos="fade-up" data-aos-duration="700">
              <div className="grid place-content-center bg-linear w-[24px] p-3 h-max rounded-full me-6">
                <IconDone className="w-[12px] text-white" />
              </div>
              <p className="text-16 font-600">Set Card Limit</p>
            </div>
            <div className="flex lg:mb-10 mb-6" data-aos="fade-up" data-aos-duration="400">
              <div className="grid place-content-center bg-linear w-[24px] p-3 h-max rounded-full me-6">
                <IconDone className="w-[12px] text-white" />
              </div>
              <p className="text-16 font-600">Clean Code</p>
            </div>
          </div>
          <img
            src={require("../../../assets/images/VirtualCardPlatform.webp")}
            className="w-[75%] mx-auto md:max-w-full max-w-[400px]"
            alt=""
            data-aos="zoom-in" data-aos-duration="1500"
          />
        </div>
      </div>
    </section>
  );
};

export default VirtualCardPlatform;
