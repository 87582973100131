import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import { Form, FormikProvider, useFormik } from 'formik'
import { customTokenSchema, customTokenSchemaTwo } from '../../validation/CommonValidation'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { SEND_API_URL, USER_API_URL, WALLET_API_URL } from '../../api/api'
import FileUpload from '../ui/FileUpload'
import { useSnackbar } from 'notistack'
import CustomSelect from '../ui/CustomSelect'
import { useTranslation } from 'react-i18next'

const AddCustomToken = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosInstance = useAxiosPrivate();
  const fileInputRef = useRef();
  const [previewFile, setPreviewFile] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [isFilePreview, setIsFilePreview] = useState();
  const [uploadedImageName, setUploadedImageName] = useState();
  const [selectedCoin, setSelectedCoin] = useState();
  const [selectedCoinSymbol, setSelectedCoinSymbol] = useState();
  const [fromAddressRes, setFromAddressRes] = useState(null);
  const [address, setAddress] = useState("");
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  // const valuesRef = useRef(null);
  const { t } = useTranslation();


  const onSubmit = async (values, { setSubmitting, setErrors }) => {

    setLoading(true);
    if (fromAddressRes) {

      let response = '';

      if (uploadFile) {
        const formData = new FormData();
        formData.append("images", uploadFile);
        response = await uploadImage(formData);
        values.imageName = response;
      }

      const tokenData = {
        tokenId: selectedCoin?._id,
        addressId: address,
        name: values?.name,
        symbol: fromAddressRes?.id,
        icon: values?.imageName?.[0] ? values?.imageName?.[0] : '',
        decimal: values?.decimal,
        code: fromAddressRes?.code,
        nativeAsset: fromAddressRes?.nativeAsset,

      }
      await addCustomToken(tokenData)

    } else {
      // valuesRef.current = values;
      const data = {
        nativeAsset: selectedCoin ? selectedCoin.symbol : null,
        address: address,
      };
      await getDataFromAddress(data);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tokenId: selectedCoin ? selectedCoin?._id : "",
      address: address ? address : "",
      name: fromAddressRes ? fromAddressRes?.name : "",
      symbol: fromAddressRes ? fromAddressRes?.code : "",
      decimal: fromAddressRes ? fromAddressRes?.decimals : "",
    },
    validationSchema: fromAddressRes ? customTokenSchemaTwo(t) : customTokenSchema(t),
    onSubmit: onSubmit,

  });


  const { mutateAsync: getDataFromAddress } = useMutation(
    async (data) => {
      return axiosInstance.post(SEND_API_URL.getDataFromAddress, data).then((res) => res.data);
    },
    {
      onSuccess: async (response) => {
        // Api Step 2: add token 
        setLoading(false);
        setFromAddressRes(response)

      },
      onError: async (error) => {
        setLoading(false);
        if (error?.response?.data?.message) {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 5000,
          });
        }
      },
    }
  );
  const { mutateAsync: addCustomToken } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(WALLET_API_URL.addCustomToken, data).then((res) => res.data)
        if (response) {
          enqueueSnackbar("Custom token added!", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 5000,
          });
          resetForm();
          handleClose();
          setPreviewFile(undefined);
          setUploadFile(undefined);
          setIsFilePreview(false)
          setLoading(false);
          setFromAddressRes(null)
          // valuesRef.current = null
          queryClient.invalidateQueries('tokenListData')
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 5000,
            });
          });
        } else {
          enqueueSnackbar(error?.response?.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 5000,
          });
        }
      }
    }
  );

  const { mutateAsync: uploadImage } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.uploadUserImage, data, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        });
        setUploadedImageName(response?.data);
        return response.data;
      } catch (error) {
        setLoading(false)
        if (error?.response?.data?.message) {
          enqueueSnackbar(error?.response?.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 5000,
          });
        }
      }
    }
  );

  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    setErrors,
    resetForm,
    setValues,
    setFieldValue
  } = formik;

  // File upload
  const handleFileUpload = (ev) => {
    ev.preventDefault();
    if (ev.target.files && ev.target.files.length > 0) {
      const selectedFile = ev.target.files[0];

      if (selectedFile && (selectedFile?.size / 1024 / 1024) > 1) {
        enqueueSnackbar("File size must be less than 1 MB", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 5000,
        });
        return false;
      }

      if (!isValidImageFile(selectedFile)) {
        enqueueSnackbar("Please select only images with extension jpeg,jpg,png.", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 5000,
        });
      } else {
        const selectedFile = fileInputRef.current.files[0];
        setUploadFile(selectedFile)
        let uploadImagePreview = URL.createObjectURL(selectedFile);
        setPreviewFile(uploadImagePreview);
        setIsFilePreview(true);
        setValues({ ...formik.values, file: selectedFile });
        setFieldValue('icon', uploadImagePreview);
      }
    } else {
      setFieldValue('icon', '');
    }
    ev.target.value = '';
  }

  const isValidImageFile = (file) => {
    if (!file) {
      return false;
    }
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    return allowedTypes.includes(file.type);
  };


  const [customToken, setCustomToken] = useState();

  async function getCustomToken() {
    const response = await axiosInstance.get(WALLET_API_URL.getCustomToken);
    return response.data;
  }

  const {
    data: customTokenList,
    refetch
  } = useQuery(['customTokenList'], () => getCustomToken(), {
    keepPreviousData: true,
    initialData: customToken, // Provide initial data
    staleTime: 5000,
  })


  useEffect(() => {
    if (customTokenList) {
      setCustomToken(customTokenList);
    }
  }, [customTokenList])


  useEffect(() => {
    if (getFieldProps('icon')?.value === undefined && uploadFile && uploadFile?.name) {
      setFieldValue('icon', uploadFile?.name);
    }
  }, [formik.errors])

  const getSelectedCoin = (value) => {
    setSelectedCoin(value)
    setSelectedCoinSymbol(value?.symbol)
    setFromAddressRes(null)
    setAddress("")
  }


  const handleAddressInput = (e) => {
    setAddress(e.target.value);
    setFromAddressRes(null);
    setPreviewFile(undefined);
    setUploadFile(undefined);
    setIsFilePreview(false)
  }
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {
        setPreviewFile(undefined);
        setUploadFile(undefined);
        setIsFilePreview(false)
        return;
      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>{t('Add_Custom')}</p>
                  <IconClose onClick={() => {
                    handleClose();
                    setPreviewFile(undefined);
                    setUploadFile(undefined);
                    setIsFilePreview(false)
                    setLoading(false);
                    resetForm();
                    setFromAddressRes(null)
                    // valuesRef.current = null
                    setSelectedCoin(null)
                    setAddress("");
                  }} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <div className="p-12">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                      <label className="!text-white text-16 font-500 mb-4">{t('Select_Token')}</label>
                      <CustomSelect
                        listButtonRootClass="w-full justify-between rounded-full bg-dark p-[14px] ps-[16px] mb-8"
                        data={customToken} getChildData={getSelectedCoin}
                        errorMsg={errors.tokenId && touched.tokenId ? errors.tokenId : null}
                        type={'tokenId'} selectedCoin={selectedCoin} />

                      <Input className="mb-4" name="address" lable={t('Address')}
                        placeholder={t('Contract_Address')} value={address} onChange={(e) => handleAddressInput(e)}
                        errorMsg={errors.address && touched.address ? errors.address : null}
                      />
                      {
                        fromAddressRes && (
                          <>
                            <Input className="mb-4" name="name" lable={t('Name')}
                              placeholder={t('enter_name')} {...getFieldProps('name')}
                              errorMsg={errors.name && touched.name ? errors.name : null} />
                            <div className="mb-4">
                              {
                                isFilePreview && (

                                  <div item xs={12} style={{
                                    textAlign: 'left',
                                    margin: '7px',
                                    position: 'relative'
                                  }}>
                                    <img
                                      src={previewFile}
                                      alt="Selected Preview"
                                      style={{
                                        maxWidth: '50%',
                                        maxHeight: '100px',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        marginLeft: '0',
                                      }}
                                    />
                                  </div>
                                )
                              }
                              <label className="!text-white text-16 font-500 mb-4">{t('Icon')}</label>
                              <FileUpload
                                handleFileUpload={handleFileUpload}
                                ref={fileInputRef}
                                accept={'.png,.jpg,.jpeg'}
                                errorMsg={formik.errors.icon ? formik.errors.icon : null} />
                            </div>
                            <Input className="mb-4" lable={t('symbol')} name="symbol"
                              placeholder={t('enter_symbol')} {...getFieldProps('symbol')}
                              errorMsg={errors.symbol && touched.symbol ? errors.symbol : null} />
                            <Input lable={t('Decimals')} name="decimal"
                              placeholder={t('Decimals')} {...getFieldProps('decimal')}
                              errorMsg={errors.decimal && touched.decimal ? errors.decimal : null} />
                            {/* <Input lable="Code" name="code"
                              placeholder="Code" {...getFieldProps('code')}
                              errorMsg={errors.code && touched.code ? errors.code : null} /> */}
                          </>
                        )
                      }
                      <Button
                        className={`w-full text-[14px] mt-20 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        size="sm" disabled={loading}>
                        {loading ? t('loading') : fromAddressRes == null ? "Continue" : t('save')}
                      </Button>
                    </Form>
                  </FormikProvider>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default AddCustomToken
