import React from 'react'

const IcnExclamation = (props) => {
    return (
        <svg {...props} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.9995 76.25C35.8574 76.25 32.4995 72.8921 32.4995 68.75C32.4995 64.6079 35.8574 61.25 39.9995 61.25C44.1417 61.25 47.4995 64.6079 47.4995 68.75C47.4995 72.8921 44.1417 76.25 39.9995 76.25ZM39.9995 3.74951C34.481 3.74951 29.999 8.23151 29.999 13.75C29.999 17.131 32.207 41.2405 35.249 50.749C35.9315 52.885 36.7505 54.3955 37.49 55.1395C38.2955 55.9495 39.173 56.2495 39.9995 56.2495C40.826 56.2495 41.7035 55.9495 42.509 55.1395C43.2485 54.3955 44.0675 52.885 44.75 50.749C47.792 41.2405 50 17.131 50 13.75C50 8.23151 45.518 3.74951 39.9995 3.74951Z" fill="currentColor" />
        </svg>

    )
}

export default IcnExclamation 
