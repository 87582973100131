import React from 'react'

const IconCoinReceive = (props) => {
  return (
    <svg {...props} viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.63951 12.3607C3.54575 12.2669 3.49307 12.1398 3.49307 12.0072L3.49307 6.2843C3.49307 6.00816 3.71692 5.7843 3.99307 5.7843C4.26921 5.7843 4.49307 6.00816 4.49307 6.2843L4.49307 10.8L11.6534 3.63972C11.8487 3.44446 12.1652 3.44446 12.3605 3.63972C12.5558 3.83498 12.5558 4.15157 12.3605 4.34683L5.20017 11.5072L9.71592 11.5072C9.99206 11.5072 10.2159 11.731 10.2159 12.0072C10.2159 12.2833 9.99206 12.5072 9.71592 12.5072L3.99307 12.5072C3.86046 12.5072 3.73328 12.4545 3.63951 12.3607Z" fill="currentcolor" />
    </svg>
  )
}

export default IconCoinReceive
