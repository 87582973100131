import React from 'react'

const IconTurkey = (props) => {
  return (
    <svg {...props} viewBox="0 0 65 65" fill="none">
      <path d="M0 10.8337H65V54.1661H0V10.8337Z" fill="#D80027" />
      <path d="M31.3845 27.5217L33.6181 30.5998L37.2354 29.4269L34.9983 32.5022L37.2317 35.5801L33.6155 34.4027L31.3785 37.4782L31.3808 33.6753L27.7646 32.4978L31.3821 31.3249L31.3845 27.5217Z" fill="#F0F0F0" />
      <path d="M25.2893 40.1935C21.0403 40.1935 17.5957 36.7489 17.5957 32.4999C17.5957 28.2509 21.0403 24.8063 25.2893 24.8063C26.6142 24.8063 27.8606 25.1414 28.949 25.7311C27.2417 24.0614 24.9068 23.0309 22.3303 23.0309C17.1007 23.0309 12.8613 27.2704 12.8613 32.4998C12.8613 37.7293 17.1008 41.9687 22.3303 41.9687C24.9068 41.9687 27.2417 40.9383 28.949 39.2686C27.8607 39.8585 26.6142 40.1935 25.2893 40.1935Z" fill="#F0F0F0" />
    </svg>

  )
}

export default IconTurkey