import React from 'react'

const IconOff = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" >
      <path d="M15.8329 4.42956C15.4111 4.2453 14.9199 4.43784 14.7356 4.8596C14.5513 5.28136 14.7439 5.77261 15.1656 5.95687C17.8771 7.14141 19.6668 9.82319 19.6668 12.8333C19.6668 16.9753 16.3085 20.3334 12.1667 20.3334C8.02442 20.3334 4.66665 16.9756 4.66665 12.8333C4.66665 9.82386 6.45573 7.14208 9.16638 5.95676C9.58806 5.77238 9.78044 5.28105 9.59603 4.85937C9.41165 4.43768 8.92032 4.2453 8.49864 4.42971C5.18654 5.87797 3 9.15557 3 12.8333C3 17.896 7.10398 22 12.1668 22C17.229 22 21.3335 17.8958 21.3335 12.8333C21.3335 9.15471 19.1461 5.87695 15.8329 4.42956Z" fill="currentcolor" />
      <path d="M12.1663 11.1668C12.6266 11.1668 12.9997 10.7937 12.9997 10.3334V2.83337C12.9997 2.37309 12.6266 2 12.1663 2C11.7061 2 11.333 2.37309 11.333 2.83333V10.3334C11.333 10.7936 11.7061 11.1668 12.1663 11.1668Z" fill="currentcolor" />
    </svg>
  )
}

export default IconOff


