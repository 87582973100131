import React from "react";
import WhatIsWallet from "../../../components/website/Business/WhatIsWallet";
import VirtualCardPlatform from "../../../components/website/Business/VirtualCardPlatform";
import WalletLimit from "../../../components/website/Business/WalletLimit";
import WalletMadeEasy from "../../../components/website/Business/WalletMadeEasy";
import { Helmet } from "react-helmet-async";

const Business = () => {
  return (
    <>
      <section className="bg-black md:pb-[125px] pb-[40px] md:pt-[52px] pt-[40px] relative">
        <Helmet prioritizeSeoTags>
          <title>Send, Receive and Swap crypto Payments All Your Business with the Oppi Wallet
          </title>
          <meta name="description" content="Secure your cryptocurrency with The Most Secure Crypto Oppi Wallet. Send, Receive and Swap your crypto key safe with Oppi Wallet solution." />
          <link rel="canonical" href="https://oppiwallet.com/business" />


          <meta property="og:title" content="Send, Receive and Swap crypto Payments All Your Business with the Oppi Wallet" />
          <meta property="og:description" content="Secure your cryptocurrency with The Most Secure Crypto Oppi Wallet. Send, Receive and Swap your crypto key safe with Oppi Wallet solution." />
          <meta property="og:url" content="https://oppiwallet.com/business" />

        </Helmet>
        <div className="container">
          <div className="grid md:grid-cols-2 items-center md:gap-0 gap-8">
            <div className="flex flex-col md:text-start text-center max-w-[520px] gap-8 mx-auto" data-aos="fade-up" data-aos-duration="1000">
              <h5 className="text-white font-600 capitalize lg:text-[42px] sm:text-28">
                Simplify All Your Business Financial Transactions
              </h5>
              <p className="text-white text-18 font-400 capitalize leading-[36px]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the
              </p>
            </div>
            <img
              src={require("../../../assets/images/Business.webp")}
              className="w-full mx-auto max-w-[450px]"
              alt=""
              data-aos="fade-down" data-aos-duration="1000"
            />
          </div>
        </div>
        <div className="icons md:block hidden">
          <img
            src={require("../../../assets/images/WebIcon1.svg").default}
            className="absolute z-0 lg:left-[100px] left-[60px] top-[8%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon2.svg").default}
            className="absolute z-0 lg:left-[35px] left-[40px] top-[60%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon2.svg").default}
            className="absolute z-0  left-[45%] top-[80%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon2.svg").default}
            className="absolute z-0 lg:right-[260px] md:right-[180px] right-[40px] top-[8%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon3.svg").default}
            className="absolute z-0 lg:right-[160px] md:right-[140px] right-[60px] top-[80%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon4.svg").default}
            className="absolute z-0 left-[180px] top-[85%] animate-bounce"
            alt=""
          />
        </div>
      </section>
      <WhatIsWallet />
      <VirtualCardPlatform />
      <WalletLimit />
      <WalletMadeEasy />
    </>
  );
};

export default Business;
