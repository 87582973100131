import React, { useEffect, useState } from "react";
import { Button } from "../../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import { SUPPORT_TICKET_API_URL } from "../../../api/api";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import { useQuery } from "react-query";
import { DateFomat } from "../../../Helper";
import Loader from "../../../components/ui/Loader";

const Support = () => {
  const navigate = useNavigate();
  const [supportTicketList, setSupportTicketList] = useState();
  const axiosInstance = useAxiosPrivate()



  async function getSupportTicket() {
    const response = await axiosInstance.get(SUPPORT_TICKET_API_URL.getTicket);
    return response.data;
  }
  const { data: ticketList, refetch, isLoading } = useQuery(['ticketList'], () => getSupportTicket(), { keepPreviousData: true, })

  useEffect(() => {
    if (ticketList) {
      setSupportTicketList(ticketList);
    }
  }, [ticketList])



  return (
    <div className="bg-card h-full">
      {/* <div className="py-12 px-8 flex flex-col min-h-[100vh] justify-center items-center">
        <div className="flex justify-center items-center w-[80px] h-[80px] border-2 border-light-100 rounded-full">
          <IconTicket className="w-[48px] text-theme" />
        </div>
        <p className="text-white text-20 mt-6">No any tickets created</p>
        <Button size="sm" className="w-[50%] mx-auto block mt-8 py-[9px]">
          Create New ticket
        </Button>
      </div> */}
      {isLoading ? <Loader /> : <div className="p-12 flex flex-col gap-[14px]">
        {supportTicketList?.length > 0 ? supportTicketList?.map((ticket, index) => (
          <div className="p-6 w-full bg-dark rounded-3xl cursor-pointer" onClick={() => navigate("/dashboard/setting/support-detail", { state: { ticketDetail: ticket } })}>
            <div className="flex justify-between items-start w-full">
              <div className="text">
                <h6 className="text-16 font-500 text-white mb-4">
                  {ticket?.subject}
                </h6>
                <p className="text-12 font-400 text-gray mb-4">
                  {ticket?.description}
                </p>
                <p className="text-gray text-12 font-400">{DateFomat(ticket?.createdAt)}</p>
              </div>

              {
                ticket?.status === '1' ?
                  <Button
                    className="!text-white bg-green flex gap-4 items-center text-14 font-400 px-4 py-1 border border-light-100"
                    variant="gray"
                    size="sm"
                  >
                    Open
                  </Button> : ticket?.status === '2' ?
                    <Button
                      className="!text-white bg-red flex gap-4 items-center text-14 font-400 px-4 py-1 border border-light-100"
                      variant="gray"
                      size="sm"
                    >
                      Close
                    </Button> : ticket?.status === '3' ?
                      <Button
                        className="!text-black bg-gray flex gap-4 items-center text-14 font-400 px-4 py-1 border border-light-100"
                        variant="gray"
                        size="sm"
                      >
                        Responded
                      </Button> : <></>
              }
            </div>
          </div>
        )) : <p className="text-white">Ticket not found!</p>}
      </div>}


    </div>
  );
};

export default Support;
