import React from "react";
import { Button } from "../../../components/ui/Button";
import AboutUsSecureSection from "../../../components/website/AboutUs/AboutUsSecureSection";
import BelieveSection from "../../../components/website/AboutUs/BelieveSection";
import SlideView from "../../../components/website/AboutUs/SlideView";
import AboutExplore from "../../../components/website/AboutUs/AboutExplore";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/images/logo_new.png"

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="overflow-x-hidden hidden-scrollbar">
      <section className="lg:py-[132px] py-[60px] bg-black relative">
        <Helmet prioritizeSeoTags>
          <title>About US | Oppi Wallet
          </title>
          <meta name="description" content="Download the Oppi Wallet app for an easy and secure way to Send, Recive, store, and swap crypto all in on web and mobile wallets." />
          <link rel="canonical" href="https://oppiwallet.com/about-us" />

          <meta property="og:title" content="About US | Oppi Wallet" />
          <meta property="og:description" content="Download the Oppi Wallet app for an easy and secure way to Send, Recive, store, and swap crypto all in on web and mobile wallets." />
        </Helmet>
        <div className="container">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[40px]">
            <img
              src={Logo}
              className="h-full aspect-square md:w-auto w-[60%] mx-auto z-[1]"
              alt=""
              data-aos="fade-right" data-aos-duration="1000"
            />
            <div className="flex flex-col max-w-[500px] gap-[24px] z-[1]" data-aos="fade-left" data-aos-duration="1000">
              <h5 className="text-white sm:text-[42px] text-32 text-28">
                {t('Oppi_empowers_crypto_community_to_expand_their_wealth')}
              </h5>
              <p className="text-white font-extralight leading-[24px] max-w-[80%]">
               {t('Secure_Manage_and_Swap_cryptocurrency_on_web_and_mobile_wallets')}
              </p>
              <Button variant="filled" className="w-max border-0">
                {t('Explore_Oppi_Wallet')}
              </Button>
            </div>
          </div>
        </div>
        <div className="icons md:block hidden">
          <img
            src={require("../../../assets/images/WebIcon1.svg").default}
            className="absolute z-0 lg:left-[100px] left-[60px] top-[8%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon2.svg").default}
            className="absolute z-0 lg:left-[35px] left-[40px] top-[60%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon2.svg").default}
            className="absolute z-0  left-[45%] top-[80%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon2.svg").default}
            className="absolute z-0 lg:right-[260px] md:right-[180px] right-[40px] top-[8%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon3.svg").default}
            className="absolute z-0 lg:right-[160px] md:right-[140px] right-[60px] top-[80%] animate-pulse"
            alt=""
          />
          <img
            src={require("../../../assets/images/WebIcon4.svg").default}
            className="absolute z-0 left-[180px] top-[85%] animate-bounce"
            alt=""
          />
        </div>
      </section>
      <AboutUsSecureSection />
      <BelieveSection />
      <SlideView />
      <AboutExplore />
    </div>
  );
};

export default AboutUs;
