import React from "react";

const IconLanguage = (props) => {
  return (
    <svg {...props} viewBox="0 0 21 21" fill="none">
      <path
        d="M18.4935 18.4932C18.2078 18.4932 17.9221 18.3503 17.8507 18.0646L16.8507 15.636H13.0649L12.0649 18.0646C11.9221 18.4217 11.4935 18.636 11.1364 18.4217C10.7792 18.2789 10.5649 17.8503 10.7792 17.4932L14.3507 8.92174C14.4935 8.63602 14.7078 8.49316 14.9935 8.49316C15.2792 8.49316 15.5649 8.63602 15.6364 8.92174L19.2078 17.4932C19.3507 17.9932 18.9935 18.4932 18.4935 18.4932ZM13.6364 14.2074H16.2792L14.9221 11.0646L13.6364 14.2074Z"
        fill="currentcolor"
      />
      <path
        d="M4.2078 16.3507C3.56494 16.3507 3.27923 15.565 3.7078 15.1364L7.35066 11.4936L5.13637 8.92215C4.85066 8.63644 4.92208 8.20787 5.2078 7.92215C5.49351 7.63644 5.92208 7.70787 6.2078 7.99358L8.35066 10.4936C10.7078 7.99358 11.2792 6.42215 11.4221 5.63644H2.77923C2.35066 5.63644 2.06494 5.35073 2.06494 4.92215C2.06494 4.49358 2.35066 4.20787 2.77923 4.20787H7.06494V3.49358C7.06494 3.06501 7.35066 2.7793 7.77923 2.7793C8.2078 2.7793 8.49351 3.06501 8.49351 3.49358V4.20787H14.2078C14.6364 4.20787 14.9221 4.49358 14.9221 4.92215C14.9221 5.35073 14.6364 5.63644 14.2078 5.63644H12.7792C12.7078 6.70787 11.9935 8.70787 9.2078 11.565L10.7792 13.3507C11.0649 13.6364 10.9935 14.065 10.7078 14.3507C10.4221 14.6364 9.99351 14.565 9.7078 14.2793L8.2078 12.565L4.63637 16.1364C4.56494 16.2793 4.42208 16.3507 4.2078 16.3507Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default IconLanguage;
