import React from 'react'

const IconSwap = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="currentcolor">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.03982 3.45915C9.33301 3.75175 9.33348 4.22662 9.04088 4.51981L5.30813 8.26001H20.5C20.9142 8.26001 21.25 8.59579 21.25 9.01001C21.25 9.42422 20.9142 9.76001 20.5 9.76001H3.50002C3.19681 9.76001 2.92343 9.57744 2.80726 9.29736C2.69108 9.01729 2.75497 8.69482 2.96916 8.48021L7.97916 3.4602C8.27176 3.16702 8.74664 3.16655 9.03982 3.45915ZM2.75002 14.99C2.75002 14.5758 3.08581 14.24 3.50002 14.24H20.5C20.8032 14.24 21.0766 14.4226 21.1928 14.7026C21.309 14.9827 21.2451 15.3052 21.0309 15.5198L16.0209 20.5398C15.7283 20.833 15.2534 20.8335 14.9602 20.5409C14.667 20.2483 14.6666 19.7734 14.9592 19.4802L18.6919 15.74H3.50002C3.08581 15.74 2.75002 15.4042 2.75002 14.99Z" fill="currentcolor" />
    </svg>
  )
}

export default IconSwap


