import { Fragment, useEffect, useState, useTransition } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import IconDown from '../../assets/svg/IconDown';
import { cn } from '../../lib/utils';
import { useTranslation } from 'react-i18next';

const CustomSelectAddressOrigin = ({ errorMsg, data, listButtonTextClass, listButtonRootClass, listDropRootClass, getChildData }) => {
  const {t} = useTranslation(); 
  const [selectedId, setSelectedId] = useState(null);

  const selectedOption = data?.find(option => option.id === selectedId);

  useEffect(() => {
    if (selectedOption) {
      getChildData(selectedOption);
    }
  }, [selectedOption]);

  return (
    <Listbox value={selectedId} onChange={setSelectedId}>
      <div className="relative mt-1">
        <Listbox.Button className={cn("relative w-[116px] px-4 py-[10px] ps-[16px] bg-[#262626] flex items-center justify-center text-white border border-[#b3b3b31a] rounded-[10px] gap-2", listButtonRootClass)}>
          <span className={cn("block truncate text-14 font-400", listButtonTextClass)}>
            {selectedOption ? selectedOption?.name : t('Select_an_option')}
          </span>
          <span className="pointer-events-none">
            <IconDown className="w-6 h-6 text-dark-950" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={cn("absolute z-10 mt-1 w-full px-4 py-[10px] bg-[#262626] rounded-md cursor-pointer border border-[#b3b3b31a]", listDropRootClass)}>
            {data?.map((value) => (
              <Listbox.Option
                key={value.id}
                value={value.id}
                className={"py-[6px] cursor-pointer"}
              >
                {({ selected }) => (
                  <>
                    <span
                      className="block truncate text-14 font-400 text-dark-950 text-white"
                    >
                      {value.name}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
        {errorMsg && <p className={cn('text-12 text-red-500 font-normal')} style={{ color: "red", fontSize: '13px', margin: '8px' }}>{errorMsg}</p>}
      </div>
    </Listbox>
  );
};

export default CustomSelectAddressOrigin;
