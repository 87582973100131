import { Menu, Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconDown from "../../assets/svg/IconDown";
import IconShield from "../../assets/svg/IconShield";
import IconBackup from "../../assets/svg/IconBackup";
import { IconSupport } from "../../assets/svg/IconSupport";
import IconLanguage from "../../assets/svg/IconLanguage";
import IconRight from "../../assets/svg/IconRight";
import EmailAuthentication from "../model/EmailAuthentication";
import { authStore } from "../../context/AuthProvider";
import { checkKyc, files, kycColourCode } from "../../Helper";
import { useMutation } from "react-query";
import { AUTH_API_URL } from "../../api/api";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { useSnackbar } from "notistack";
import EnableGoogleAuthModal from "../model/EnableGoogleAuthModal";
import IconProfileSupport from "../../assets/svg/IconProfileSupport";
import IcnUser from "../../assets/svg/IcnUser";
import { useTranslation } from "react-i18next";

const SettingSideBar = ({ setTooglesidebar, tooglesidebar }) => {
  const { user } = authStore();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { canBackUp } = authStore()
  const axiosInstance = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const [emailAuthenticationModal, setEmailAuthenticationModal] =
    useState(false);
  const [openGoogleAuthModal, setOpenGoogleAuthModal] = useState(false);
  const handleOpenEmailAuthenticationModal = () => {
    setEmailAuthenticationModal(true);
  };
  const handleCloseEmailAuthentication = () => {
    setEmailAuthenticationModal(false);
  };
  const navigate = useNavigate();
  const googleAuth = () => {
    if (user?.is2faEnable === 1) {
      setOpenGoogleAuthModal(!openGoogleAuthModal)
    } else {
      navigate("/dashboard/setting/google-auth");
    }
  };
  // const backupPhrase = () => {
  //   navigate("/dashboard/setting/backup-phrase");
  // };
  const support = () => {
    navigate("/dashboard/setting/support");
  };
  const language = () => {
    navigate("/dashboard/setting/language");
  };

  const home = () => {
    navigate("/dashboard/setting");
  };


  useEffect(() => {
    setTooglesidebar(false);
  }, [pathname, setTooglesidebar]);

  const handleSendEmail = async () => {
    const data = {
      email: user?.email
    }
    await sendEmail(data)
  }

  const { mutateAsync: sendEmail } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(AUTH_API_URL.sendEmailAuth, data).then((res) => res.data)
        if (response) {
          enqueueSnackbar(response?.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });

        }
      } catch (error) {
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    },
   
  );

  return (
    <div
      className={`sidebar theme-scrollbar absolute lg:pt-0 pt-[60px] transition-all duration-500 ease-in-out border-e border-[#b3b3b31a] ${tooglesidebar ? "left-0" : "-left-[100%]"
        } flex items-start flex-col z-[8] lg:static w-full h-full max-w-[300px] px-8 flex-shrink-0 overflow-y-auto bg-card`}
    >
      {
        <div className="w-full flex flex-col">
          <div className="h-[90px] w-[90px] bg-linear-horizontal rounded-full overflow-hidden m-auto my-[20px] flex items-center justify-center">
            {user?.profile ?
              < img src={files(user?.profile, "image")} alt="" className="h-full w-full object-cover" /> :
              <p className="font-600 text-[36px]">
                {user?.name ? user?.name?.split(' ').slice(0, 2).map(name => name.slice(0, 1).toUpperCase()) : user?.email?.slice(0, 1)?.toUpperCase()}
              </p>
            }
          </div>
          <p className="text-white text-center mb-4">
            {user?.name}{" "}
            <span className={`text-[10px] p-2 rounded-full text-white`} style={{ background: kycColourCode(user) }}>
              {checkKyc(user)}
            </span>
          </p>
          <p className="text-gray text-14 text-center mb-12">
            {user?.email}
          </p>
          <div className="flex items-center w-full p-[10px] bg-dark text-white rounded-3xl mb-[16px] gap-[10px] cursor-pointer" onClick={home} >
            <div className="flex justify-center items-center bg-light-200 w-[38px] aspect-square rounded-full">
              <IcnUser className="text-white w-8" />
            </div>
            <p className="text-[14px]">{t('Profile')}</p>
          </div>
          <Menu as="div" className="relative inline-block text-left mb-6">
            {({ open }) => (
              <>
                <Menu.Button className="flex justify-between items-center w-full p-4 bg-dark text-white rounded-3xl">
                  <div className="flex items-center gap-4 text-14">
                    <div className="flex justify-center items-center bg-light-200 w-[38px] aspect-square rounded-full">
                      <IconShield className="text-white w-8" />
                    </div>
                    {t('Security')}
                  </div>
                  <IconDown className="w-8 h-8" />
                </Menu.Button>
                <Transition
                  show={open || pathname.includes("/setting/google-auth")}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="relative -top-[10px] w-full origin-top-right divide-y divide-gray-100 rounded-md bg-dark shadow-lg ring-1 ring-black/5 text-white focus:outline-none p-2 rounded-t-none">
                    <div className="px-1 py-1">
                      <Menu.Item className="mb-4" onClick={googleAuth}>
                        {({ active }) => (
                          <button
                            className={`${active ? "bg-theme text-dark" : "bg-light-200"
                              } flex w-full items-center justify-between rounded-2xl px-4 py-4 text-14 font-500`}
                          // disabled={user?.is2faEnable == '1' ? true : false}
                          >
                            {t('Google_2_FA_Authentication')}
                            {
                              (user?.is2faEnable == 1) ? (
                                <span className={`ml-2 text-[9px] ${active ? "text-black" : "text-white"} bg-[#7684ed] p-[4px_10px] rounded-full`}>
                                  {t('Enable')}
                                </span>
                              ) :
                                (
                                  <span className={`ml-6 text-[9px] ${active ? "text-black" : "text-white"} bg-[#7684ed]/60 p-[4px_10px] rounded-full`}>
                                    {t('Disable')}
                                  </span>
                                )
                            }
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item onClick={handleOpenEmailAuthenticationModal}>
                        {({ active }) => (
                          <button
                            className={`flex w-full items-center justify-between rounded-2xl px-4 py-4 text-14 font-500 ${active ? "bg-theme text-black" : "bg-light-200 text-white"
                              }`}
                            onClick={handleSendEmail}
                            // disabled={user?.isEmailEnable === 1 ? true : false}
                          >
                            {t('Email_Authentication')}
                            {user?.isEmailEnable === 1 ? (
                              <span className={`ml-2 text-[9px] ${active ? "text-black" : "text-white"} bg-[#7684ed] p-[4px_10px] rounded-full`}>
                                {t('Enable')}
                              </span>
                            ) : (
                              <span className={`ml-10 text-[9px] ${active ? "text-black" : "text-white"} bg-[#7684ed]/60 p-[4px_10px] rounded-full`}>
                                {t('Disable')}
                              </span>
                            )}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>

          {/* {
            (user?.isBackup == 0 && canBackUp == 0) ? <></>
              :
              <div
                className="flex justify-between items-center w-full p-4 bg-dark text-white rounded-3xl mb-6 cursor-pointer"
                onClick={() => (user?.isBackup == 0 && canBackUp == 1) && backupPhrase()}
              >
                <div className="flex items-center gap-4 text-14">
                  <div className="flex justify-center items-center bg-light-200 w-[38px] aspect-square rounded-full">
                    <IconBackup className="text-white w-8" />
                  </div>
                  Backup Phrase{" "}
                  {
                    (user?.isBackup == '1') && (
                      <span className="text-[8px] text-theme bg-light-theme p-[4px_10px] rounded-full">
                        Backed up
                      </span>
                    )
                  }

                </div>
                <IconRight className="w-6 h-6" />
              </div>
          } */}
          <div
            className="flex justify-between items-center w-full p-4 bg-dark text-white rounded-3xl mb-6 cursor-pointer"
            onClick={support}
          >
            <div className="flex items-center gap-4 text-14">
              <div className="flex justify-center items-center bg-light-200 w-[38px] aspect-square rounded-full">
                <IconSupport className="text-white w-8" />
              </div>
              {t('Support')}
            </div>
            <IconRight className="w-6 h-6" />
          </div>
          <div
            className="flex justify-between items-center w-full p-4 bg-dark text-white rounded-3xl cursor-pointer"
            onClick={language}
          >
            <div className="flex items-center gap-4 text-14">
              <div className="flex justify-center items-center bg-light-200 w-[38px] aspect-square rounded-full">
                <IconLanguage className="text-white w-8" />
              </div>
              {t('Language')}
            </div>
            <IconRight className="w-6 h-6" />
          </div>
        </div>
      }
      <EmailAuthentication
        open={emailAuthenticationModal}
        handleClose={handleCloseEmailAuthentication}
        setClose={setEmailAuthenticationModal}

      />
      <EnableGoogleAuthModal open={openGoogleAuthModal} handleClose={() => setOpenGoogleAuthModal(false)} isForDisable={1} />
    </div>
  );
};

export default SettingSideBar;
