import React, { useEffect, useState } from 'react'
import NftIcon from "../../assets/images/NftListIcon.png"
import NftReceiveIcon from "../../assets/images/NftsReceiveIcon.png"
import { Button } from '../ui/Button'
import NftReceiveModal from '../model/NftReceiveModal'
import { useNavigate } from 'react-router-dom'
import { TRANSACTION_API_URL } from '../../api/api'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { useQuery } from 'react-query'
import { files } from '../../Helper'
import defaultimage from '../../assets/images/logo.png'
import { useTranslation } from 'react-i18next'

const Nfts = () => {
  const [openNftReceiveModal, setOpenNftReceiveModal] = useState(false);
  const [nfts, setNfts] = useState();
  const navigate = useNavigate();
  const axiosInstance = useAxiosPrivate()
  const {t} = useTranslation();
  const nftDetail = async (data) => {
    navigate("/dashboard/nft-detail", { state: { nftDetail: data } })
  }
  const nft = true;
  const handleOpenNftReceiveModal = () => {
    setOpenNftReceiveModal(true)
  }
  const handleCloseNftReceiveModal = () => {
    setOpenNftReceiveModal(false)
  }


  async function getNfts() {
    const response = await axiosInstance.get(TRANSACTION_API_URL.nftList);
    return response.data;
  }
  const { data: nftList, refetch } = useQuery(['nftList'], () => getNfts(), { keepPreviousData: true, })

  useEffect(() => {
    if (nftList) {
      setNfts(nftList?.data)
    }
    refetch()
  }, [nftList])

  const filterNfts = nfts ?? [];
  return (
    <>
      {
        nft ? <ul className='bg-card rounded-[20px] sm:px-[24px] px-[20px] sm:py-[10px] py-[8px] border border-light-400'>
          {nfts?.length > 0 ?
            filterNfts?.map((data, index) => {
              return <li key={index} onClick={() => nftDetail(data)} className='p-[14px_0_14px] border-b border-b-light-300 flex justify-between last:border-0 items-center'>
                <div className="flex gap-[14px] items-center">
                  {data?.media && data?.media.length === 0  ? 
                  // <img src={require('../../assets/images/new_logo.svg').default} alt="" className='sm:w-[180px] w-[160px] mx-auto' />
                  // D:\oppi-wallet\oppi-wallet-web\src\assets\images\logo100.png ../../assets/images/new_logo.svg').
                  <img src={defaultimage} alt="OPPI" className='xxl:w-[48px] w-[36px] xxl:h-[48px] h-[36px] rounded-full ' />
                  :
                  <img className='xxl:w-[48px] w-[36px] xxl:h-[48px] h-[36px] rounded-full object-cover' src={data?.media?.[0]?.url} alt="" />
                  }
                  <div className="text">
                    <h5 className='text-white xxl:text-18 text-16'>{data?.name}</h5>
                    <p className='text-gray text-14'>#{data?.tokenId}</p>
                  </div>
                </div>
              </li>
            }) : <div className="flex items-center gap-3"><h5 className='text-white text-18'>{t('Record_Not_Found')}</h5></div>
          }
        </ul> :
          <div className="flex flex-col justify-center items-center bg-card rounded-[20px] h-[500px]">
            <img src={NftReceiveIcon} alt="" />
            <p className='text-gray mt-6'>Collectibles will appear here</p>
            <Button size="sm" className="w-[200px] mt-6" onClick={handleOpenNftReceiveModal}>Receive</Button>
            <NftReceiveModal open={openNftReceiveModal} handleClose={handleCloseNftReceiveModal} />
          </div>
      }

    </>
  )
}

export default Nfts