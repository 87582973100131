// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      About_Oppi_Wallet: "About Oppi",
      Features: "Features",
      Support: "Support",
      Sign_In: "Sign In",
      save: 'Save',
      Wallet: 'Wallet',
      Send: 'Send',
      Continue: 'Continue',
      Receive: 'Receive',
      Transactions: 'Transactions',
      Swap: 'Swap',
      swap: 'swap',
      API: "API",
      Payment_Link: "Payment Link",
      Browser: "Browser",
      Setting: "Setting",
      Please_complete_your_KYC_verification_to_unlock_all_features_and_services:
        "Please complete your KYC verification to unlock all features and services.",
      KYC_Now: "KYC Now",
      Logout: "Logout",
      Main_Wallet: "Main Wallet",
      Total_Balance: "Total wallet balance",
      Top_Coins: "Top Coins",
      NFTs: "NFTs",
      Add_Tokens: "Add Tokens",
      View_All: "View All",
      Virtual_Card: "Virtual Card",
      Apply_Virtual_Card: "Apply Virtual Card",
      Set_Card_limit: "Set Card limit",
      Full_Card_Details: "Full Card Details",
      DEBIT_CARD: "DEBIT CARD",
      Set_PIN: "Set PIN",
      See_All_Transaction: "See All Transaction",
      Insights: "Insights",
      Address: "Address",
      Select_Currency: "Select Currency",
      Select_an_option: "Select an option",
      Address_or_name: "Address or name",
      Amount: "Amount",
      Enter_Amount_in_INR: "Enter Amount",
      Address_List: "Address List",
      Add_Address: "Add Address",
      Back: "Back",
      Select: "Select",
      Address_not_found: "Address not found!",
      Coin: "Coin",
      Address_Origin: "Address Origin",
      wallet_lable: "Wallet label ",
      Share: 'Share',
      Coins: 'Coins',
      Maximum: 'Maximum',
      Done: 'Done',
      Apps: 'Apps',
      Daily_Payments: 'Daily Payments',
      Weekly_Payments: 'Weekly Payments',
      Revenue: 'Revenue',
      Api_Request: 'Api Request',
      Callbacks: 'Callbacks',
      Create_Apps: 'Create Apps',
      Api_Details: 'Api Details',
      Api_White_Listed_IP: 'Whitelisted IP Address',
      Title: 'Title',
      App_ID: 'App ID',
      Integration_URL_Description: 'Integration URL Description',
      Auth_Key: 'Auth Key',
      Send_a_notification_to_my_email_each_time_a_payment_is_received_with_this_app: "Send a notification to my email each time a payment is received with this app",
      Provide_the_URL_or_description_where_you_plan_to_integrate_the_API_Using_the_API_on_a_different_website_or_multiple_websites_may_result_in_suspension_of_your_account: "Provide the URL or description where you plan to integrate the API. Using the API on a different website or multiple websites may result in suspension of your account.",
      Type_Description: "Type Description",
      Enter_Your_API_Title: "Enter Your API Title",
      Payment_Link_List: "Payment Link List",
      Payment_Link: "Payment Link",
      Create_Link: "Create Link",
      Select_Currency: "Select Currency",
      Amount: "Amount",
      Expire_Time: "Expire Time",
      Email_Address: "Email Address",
      Note: "Note",
      Create: "Create",
      Please_enter_email: "Please enter email",
      Enter_Email_Address: "Enter Email Address",
      Enter_Message: "Enter Message",
      No_Search: "No Search",
      Update_Profile: "Update Profile",
      Save_Change: "Save & Change",
      Verified: "Verified",
      Email_Authentication: "Email Authentication",
      check_4_digit_otp_email: "Please check your email for the 4 digit OTP code and enter it below",
      OTP_has_been_sent_on: "OTP has been sent on",
      didnt_get_code: "Didn’t get the code? Resend code in",
      Resend: "Resend",
      Submit: "Submit",
      Support: "Support",
      Ticket_not_found: "Ticket not found!",
      Language: "Language",
      Create_ticket: "Create ticket",
      Create_New_ticket: "Create New ticket",
      Ticket_Title: "Ticket Title",
      Write_Message: "Write Message",
      Title_is_required: "Title is required",
      Confirm: "Confirm",
      loading: "loading...",
      Upload_File: "Upload File",
      Google_Authenticator: "Google Authenticator",
      Download_App: "Download App",
      Scan_QR_Code: "Scan QR Code",
      Backup_Key: "Backup Key",
      Enabled_Google_Authenticator: "Enabled Google Authenticator",
      Enabled_Google: "Enabled Google",
      Authenticator: "Authenticator",
      install_google_auth_app: "Download and install the Google Authenticator app",
      Next: "Next",
      Previous: "Previous",
      Scan_QR_code_in_Google_auth_App: "Scan this QR code in the Google Authenticator App",
      manully_type_code_in_app: "If you are unable to scan the QR code, please enter this code manually into the app.",
      save_this_key_on_paper: "Please save this key on paper. This Key will allow you to recover your Google Authenticator in case of phone loss.",
      Resetting_your_Google_Authentication_requires_opening_a_support_ticket: "Resetting your Google Authentication requires opening a support ticket and takes at least 7 days to process.",
      Enabled_Google_Authenticator: "Enabled Google Authenticator",
      Enter_the_6_digit_code_sent_to: "Enter the 6-digit code sent to",
      Google_verification_code: "Google verification code",
      Google_Authenticator_has_been_set_up_successfully: "Google Authenticator has been set up successfully.",
      You_have_successfully_enabled_Google_Authenticator_for_your_account: "You have successfully enabled Google Authenticator for your account.",
      Done: "Done",
      Please_select_a_coin: "Please select a coin",
      Address_is_required: "Address is required",
      Wallet_Lable_is_required: "Wallet Lable is required",
      Wallet_Lable_must_be_at_most_20_characters: "Wallet Lable must be at most 20 characters",
      Please_select_a_address_origin: "Please select a address origin",
      Please_select_currency: "Please select a currency",
      Please_enter_or_select_address_from_the_address_book: "Please enter or select address from the address book",
      Please_enter_address: "Address is required",
      Please_enter_amount_to_transfer: "Please enter amount to transfer",
      Message_is_required: "Message is required",
      Title_must_be_less_then_100_characters: "Title must be less then 100 characters",
      Message_must_be_less_then_500_characters: "Message must be less then 500 characters",
      Enter_Your_Message: "Enter Your Message",
      Please_enter_amount: "Enter Amount",
      Please_enter_expire_time: "Please enter expire time",
      Invalid_email_address: "Invalid email address",
      Please_enter_email: "Please enter email",
      Invalid_IP_address_format: "Invalid IP address format. Default is 0.0.0.0",
      Please_select_status: "Please select status",
      Url_description_is_required: "Url description is required",
      Please_select_a_token: "Please select a token",
      Please_select_a_address: "Please select a address",
      Please_enter_name: "Please enter name",
      Please_enter_symbol: "Please Enter symbol",
      Please_enter_decimal: "Please enter decimal",
      Please_enter_valid_decimal_number: "Please enter a valid decimal number",
      Email_is_required: "Email is required",
      Password_is_required: "Password is required",
      Password_format_validation: "Password must contain at least 6 characters, one letter, one number, and one special character.",
      Confirm_Password_is_required: "Confirm Password is required",
      Passwords_must_match: "Passwords must match",
      Country_is_required: "Country is required",
      Name_is_required: "Name is required",
      UserName_is_required: "User Name is required",
      Mobile_is_required: "Mobile is required",
      Forgot_your_Password: "Forgot your Password?",
      Haven_registered_yet: "Haven't registered yet?",
      Login: "Login",
      Register_Here: "Register Here",
      check_your_mail_verify: "Please check your mail to verify!",
      Logged_successfull: "Logged in successfull!",
      Create_an_Account: "Create an Account",
      Country_of_Residence: "Country of Residence",
      Continue: "Continue",
      Already_account: "Already have an account?",
      Login_Here: "Login Here",
      Verify_Device: "Verify Device",
      Send_Again: "Send Again",
      History: "History",
      Clear_All: "Clear All",
      Search_History: "Search History",
      Backup_Phrase: "Backup Phrase",
      Simple_fast_and_secure: "Simple,Fast and Secure",
      way_to_play_and_get_paid: "way to play and get pay",
      Security: "Security",
      Profile: "Profile",
      Google_2_FA_Authentication: "Google 2 FA Authentication",
      Email_Authentication: "Email Authentication",
      Enable: "Enable",
      Disable: "Disable",
      Change_Account: "Change Wallet",
      Please_Wait: "Please Wait...",
      Load_more: "Load more...",
      Record_Not_Found: "Record Not Found!",
      Name: "Name",
      User_Name: "User Name",
      Mobile_Number: "Mobile Number",
      Upload_PNG_or_JPG_format_only: "Upload PNG or JPG format only",
      Enable_Google_Authenticator: "Enable Google Authenticator",
      Step_4_Enabled_Google_Authenticator: "Step 4: Enabled Google Authenticator",
      Enter_the_6_digit_code: "Enter the 6 digit code",
      Send_Code: "Send Code",
      Key_copied_to_clipboard: "Key copied to clipboard.",
      Address_copied: "Address copied!",
      Please_check_mail_to_verify: "Please check mail to verify",
      Ticket_sended: "Ticket sended!",
      Currency_Balance: "Currency Balance",
      Available_Balance: "Available Balance",
      Choose_Estimate_fee: "Choose Estimate fee",
      Select_currency_to_swap: "Select currency to swap",
      Please_Enter_amount_to_swap: "Please Enter amount to swap",
      Create_API: "Create API",
      One_or_more_IP_addresses_are_invalid: "One or more IP addresses are invalid",
      Payment_Link_Created: "Payment Link Created!",
      Select_expire_time: "Select expire time",
      Change_Wallet: "Change Wallet",
      Search_Tokens: "Search Tokens",
      Add_Custom: "Add Custom",
      Add_Custom: "Add Custom",
      Select_Token: "Select Token",
      symbol: "Symbol",
      Icon: "Icon",
      Decimals: "Decimals",
      Contract_Address: "Contract Address",
      enter_symbol: "Enter symbol",
      enter_name: "Enter name",
      Icon_is_required: "Icon is required",
      Search: "Search",
      Download_the_oppi_crypto_wallet_app_to_securely_Send_Recive_swap_and_spend_multiple_cryptocurrencies_Take_full_control_of_your_finances_with_oppiwallet: "Download the oppi crypto wallet app to securely Send, Recive, swap and spend multiple cryptocurrencies. Take full control of your finances with oppiwallet.",
      Use_Oppi_wallet_on_your_computer: "Use Oppi-wallet on your computer",
      Open_Oppi_wallet_on_your_phone: "Open Oppi-wallet on your phone",
      Go_to_home_page_click_on: " Go to home page , click on",
      icon_to_scan_the_code: "icon to scan the code.",
      Simple_Seamless: "Simple. Seamless.",
      Deposit_crypto: "Deposit crypto",
      easily_from: "easily from",
      Take_control_of_your_crypto_Avoid_complicated_steps_and_deposit_directly_to_your_wallet_from_exchanges_like_Binance_and_Coinbase: "Take control of your crypto. Avoid complicated steps and deposit directly to your wallet from exchanges like Binance and Coinbase.",
      Get_Started_With_Deposite: "Get Started With Deposite",
      Stay_private_and_secure: "Stay Private and Secure",
      Rest_easy_knowing_that_our_privacy_and_security_measures_keep_you_in_control_of_your_data_and_digital_assets_while_also_keeping_them_safe:
        "Rest easy knowing that our privacy and security measures keep you in control of your data and digital assets, while also keeping them safe",
      True_ownership_of_your: "True ownership of your",
      crypto_assets: "Crypto assets",
      We_secure_your_wallet_but_dont_control_or_have_access_to_your_private_keys_or_secret_phrase_only_you_do: "We secure your wallet, but don't control or have access to your private keys or secret phrase - only you do.",
      Get_Started: "Get Started",
      One_of_the_most_secured_wallet: "One of the most secured wallet",
      Always_use_our_cloudbase_backup_facility_which_is_incrypted_in_order_to_increase_your_wallet_security: "Always use our cloudbase backup facility which is incrypted in order to increase your wallet security",
      Hasslefree_solution_without_tracking: "Hasslefree solution without tracking",
      We_are_not_tracking_any_personal_information_of_user_including_address_IP_or_wallet_balances: "We are not tracking any personal information of user including address , IP or wallet balances",
      One_stop_solution_for_all_assets: "One stop solution for all assets",
      Buy_sell_and_swap_crypto_earn_rewards_manage_NFTs_and_discover_DApps_all_in_one_place: "Buy, sell, and swap crypto, earn rewards, manage NFTs, and discover DApps, all in one place.",
      Whats_new_at_Oppi_Crypto: "What’s new at Oppi Crypto ?",
      Download_our_App_and__get_start: "Download our App and  get start",
      send_recieve: "Send and receive",
      Crypto: "Crypto",
      Lets_Talk: "Let’s Talk !",
      curious_to_see_how_adjust_can_help_grow_your_app_Exponentially: "curious to see how adjust can help grow your app Exponentially ?",
      Request_Demo: "Request demo",
      Company: "Company",
      about_us: "About us",
      Privacy_Policy: "Privacy policy",
      AML_Policy: "AML policy",
      Cookies_Policy: "Cookies policy",
      Terms_of_use: "Terms of use",
      Support_Center: "Support center",
      Card_Activation: "Card Activation",
      API_Integration_Kit: "API integration kit",
      FAQ: "FAQ",
      Why_Us: "Why us",
      Oppi_for_business: "Oppi for business",
      Swap_your_assets: "Swap your assets",
      Store_your_crypto: "Store your crypto",
      Oppi_empowers_crypto_community_to_expand_their_wealth: "Oppi empowers crypto community to expand their wealth",
      Secure_Manage_and_Swap_cryptocurrency_on_web_and_mobile_wallets: "Secure, Manage, and Swap cryptocurrency on web and mobile wallets.",
      Explore_Oppi_Wallet: "Explore Oppi Wallet",
      Secure_Manage: "Secure, Manage",
      and: ", and ",
      crypto_currency: "crypto currency",
      At_Oppi_we_pour_our_hearts_into_every_detail_from_pixel_perfect_icons_to_subtle_sounds_creating_a_cryptocurrency_experience_that_works_for_everyone: "At Oppi we pour our hearts into every detail, from pixel-perfect icons to subtle sounds, creating a cryptocurrency experience that works for everyone.",
      Safe: "Safe",
      Trusted: "Trusted",
      Reliable: "Reliable"
    },
  },

  tr: {
    translation: {
      About_Oppi_Wallet: "Oppi-Cüzdan Hakkında",
      Features: "Özellikler",
      Support: "Destek",
      Sign_In: "Kayıt olmak",
      save: 'Kaydetmek',
      Wallet: 'Cüzdan',
      Send: 'Göndermek',
      Continue: 'Devam etmek',
      Receive: 'Almak',
      Transactions: 'İşlemler',
      Swap: 'Takas',
      swap: 'takas',
      API: "API'si",
      Payment_Link: "Ödeme Bağlantısı",
      Browser: "Tarayıcı",
      Setting: "Ayar",
      Please_complete_your_KYC_verification_to_unlock_all_features_and_services: "Tüm özelliklerin ve hizmetlerin kilidini açmak için lütfen KYC doğrulamanızı tamamlayın.",
      KYC_Now: "KYC Şimdi",
      Logout: "Çıkış Yap",
      Main_Wallet: "Ana Cüzdan",
      Total_Balance: "Toplam cüzdan bakiye",
      Top_Coins: "En İyi Paralar",
      NFTs: "NFT'ler",
      Add_Tokens: "Jeton Ekle",
      View_All: "Hepsini gör",
      Virtual_Card: "Sanal Kart",
      Apply_Virtual_Card: "Sanal Kartı Uygula",
      Set_Card_limit: "Kart limitini ayarla",
      Full_Card_Details: "Tam Kart Detayları",
      DEBIT_CARD: "BANKA KARTI",
      Set_PIN: "PIN'i ayarla",
      See_All_Transaction: "Tüm İşlemleri Gör",
      Insights: "Analizler",
      Address: "Adres",
      Select_Currency: "Para birimini seçin",
      Select_an_option: "Bir seçenek seçin",
      Address_or_name: "Adres Veya isim",
      Amount: "Miktar",
      Enter_Amount_in_INR: "Miktarı girin",
      Address_List: "Adres Listesi",
      Add_Address: "Adres Ekle",
      Back: "Geri",
      Select: "Seçme",
      Address_not_found: "Adres bulunamadı!",
      Coin: "Madeni para",
      Address_Origin: "Adres Menşei",
      Address_or_name: "Adres veya ad",
      wallet_lable: "cüzdan etiketi (İsteğe bağlı)",
      Share: 'Paylaşmak',
      Coins: 'Paralar',
      Maximum: 'Maksimum',
      Done: 'Tamamlamak',
      Apps: 'Uygulamalar',
      Daily_Payments: 'Günlük Ödemeler',
      Weekly_Payments: 'Haftalık Ödemeler',
      Revenue: 'Hasılat',
      Api_Request: 'API Talebi',
      Callbacks: 'Geri aramalar',
      Create_Apps: 'Uygulama Oluştur',
      Api_Details: 'API Ayrıntıları',
      Api_White_Listed_IP: 'Beyaz Listeye Alınan IP Adresi',
      Title: 'Başlık',
      App_ID: 'Uygulama kimliği',
      Integration_URL_Description: `Entegrasyon URL'si Açıklama`,
      Auth_Key: 'Kimlik Doğrulama Anahtarı',
      Send_a_notification_to_my_email_each_time_a_payment_is_received_with_this_app: "Bu uygulama ile her ödeme alındığında e-postama bir bildirim gönder",
      Provide_the_URL_or_description_where_you_plan_to_integrate_the_API_Using_the_API_on_a_different_website_or_multiple_websites_may_result_in_suspension_of_your_account: "API'yi entegre etmeyi planladığınız URL'yi veya açıklamayı sağlayın. API'yi farklı bir web sitesinde veya birden fazla web sitesinde kullanmak hesabınızın askıya alınmasına neden olabilir.",
      Type_Description: "Açıklama yaz",
      Enter_Your_API_Title: "API başlığınızı girin",
      Payment_Link_List: "Ödeme bağlantı listesi",
      Payment_Link: "Ödeme bağlantısı",
      Create_Link: "Bağlantı oluştur",
      Select_Currency: "Para birimi seçin",
      Amount: "Miktar",
      Expire_Time: "Son kullanma süresi",
      Email_Address: "E-posta adresi",
      Note: "Not",
      Create: "Oluşturmak",
      Please_enter_email: "Lütfen e-posta girin",
      Enter_Email_Address: "E-posta adresi girin",
      Enter_Message: "Mesaj girin",
      No_Search: "Arama yok",
      Update_Profile: "Profil güncelle",
      Save_Change: "Kaydet & Değiştir",
      Verified: "Doğrulandı",
      Email_Authentication: "E-posta doğrulama",
      check_4_digit_otp_email: "Lütfen 4 haneli OTP kodu için e-postanızı kontrol edin ve aşağıya girin",
      OTP_has_been_sent_on: "OTP gönderildi",
      didnt_get_code: "Kodu alamadınız mı? Kodu yeniden gönderin",
      Resend: "Yeniden gönder",
      Submit: "Gönder",
      Support: "Destek",
      Ticket_not_found: "Bilet bulunamadı!",
      Language: "Dil",
      Create_ticket: "Bilet oluştur",
      Create_New_ticket: "Yeni bilet oluştur",
      Ticket_Title: "Bilet başlığı",
      Write_Message: "Mesaj yazın",
      Title_is_required: "Başlık gereklidir",
      Confirm: "Onayla",
      loading: "Yükleniyor...",
      Upload_File: "Dosya yükle",
      Google_Authenticator: "Google Kimlik Doğrulayıcı",
      Download_App: "Uygulamayı İndir",
      Scan_QR_Code: "QR kodu tara",
      Backup_Key: "Yedekleme anahtarı",
      Enabled_Google_Authenticator: "Google Authenticator Etkinleştirildi",
      Enabled_Google: "Google'ı etkinleştirdi",
      Authenticator: "Kimlik Doğrulayıcı",
      install_google_auth_app: "Google Authenticator uygulamasını indirin ve yükleyin",
      Next: "Sonraki",
      Previous: "Önceki",
      Scan_QR_code_in_Google_auth_App: "Bu QR kodu Google Authenticator Uygulamasında tarayın",
      manully_type_code_in_app: "QR kodunu tarayamıyorsanız, lütfen bu kodu uygulamaya manuel olarak girin.",
      save_this_key_on_paper: "Lütfen bu anahtarı kağıda kaydedin. Bu Anahtar, telefonunuzun kaybolması durumunda Google Authenticator'ınızı kurtarmanızı sağlayacaktır.",
      Resetting_your_Google_Authentication_requires_opening_a_support_ticket: "Google Kimlik Doğrulamanızı sıfırlamak için bir destek bileti açmanız gerekir ve işlemin tamamlanması en az 7 gün sürer.",
      Enabled_Google_Authenticator: "Google Authenticator Etkinleştirildi",
      Enter_the_6_digit_code_sent_to: "Adresine gönderilen 6 haneli kodu girin",
      Google_verification_code: "Google doğrulama kodu",
      Google_Authenticator_has_been_set_up_successfully: "Google Authenticator başarıyla kuruldu.",
      You_have_successfully_enabled_Google_Authenticator_for_your_account: "Hesabınız için Google Authenticator'ı başarıyla etkinleştirdiniz.",
      Done: "Tamamlandı",
      Please_select_a_coin: "Lütfen bir madeni para seçin",
      Address_is_required: "Adres gereklidir",
      Wallet_Lable_is_required: "Cüzdan Etiketi gerekli",
      Wallet_Lable_must_be_at_most_20_characters: "Cüzdan Etiketi en fazla 20 karakterden oluşmalıdır",
      Please_select_a_address_origin: "Lütfen adres kökeni seçin",
      Please_select_currency: "Lütfen para birimi seçin",
      Please_enter_or_select_address_from_the_address_book: "Lütfen adres girin veya adres defterinden adres seçin",
      Please_enter_address: "Adres gerekli",
      Please_enter_amount_to_transfer: "Lütfen transfer edilecek tutarı girin",
      Message_is_required: "Mesaj gereklidir",
      Title_must_be_less_then_100_characters: "Başlık 100 karakterden kısa olmalıdır",
      Message_must_be_less_then_500_characters: "Mesaj 500 karakterden kısa olmalıdır",
      Enter_Your_Message: "Mesajınızı Girin",
      Please_enter_amount: "Lütfen tutarı giriniz",
      Please_enter_expire_time: "Lütfen sona erme zamanı girin",
      Invalid_email_address: "Geçersiz e-posta adresi",
      Please_enter_email: "Lütfen e-posta girin",
      Invalid_IP_address_format: "Geçersiz IP adresi biçimi. Varsayılan 0.0.0.0",
      Please_select_status: "Lütfen durum seçiniz",
      Url_description_is_required: "Url açıklaması gereklidir",
      Please_select_a_token: "Lütfen token seçiniz",
      Please_select_a_address: "Lütfen adres seçiniz",
      Please_enter_name: "Lütfen isim girin",
      Please_enter_symbol: "Lütfen sembol girin",
      Please_enter_decimal: "Lütfen ondalık girin",
      Please_enter_valid_decimal_number: "Lütfen geçerli bir ondalık sayı girin",
      Email_is_required: "E-posta gereklidir",
      Password_is_required: "Parola gereklidir",
      Password_format_validation: "Parola en az 6 karakter, bir harf, bir rakam ve bir özel karakter içermelidir.",
      Confirm_Password_is_required: "Şifre Onayı gereklidir",
      Passwords_must_match: "Parolalar eşleşmelidir",
      Country_is_required: "Ülke gereklidir",
      Name_is_required: "İsim gereklidir",
      UserName_is_required: "Kullanıcı adı gereklidir",
      Mobile_is_required: "Cep telefonu gereklidir",
      Forgot_your_Password: "Parolanızı mı unuttunuz?",
      Haven_registered_yet: "Henüz kayıt yaptırmadınız mı?",
      Login: "Giriş yap",
      Register_Here: "Buradan Kaydolun",
      check_your_mail_verify: "Doğrulamak için lütfen epostanızı kontrol edin!",
      Logged_successfull: "Giriş başarılı!",
      Create_an_Account: "Bir hesap oluşturun",
      Country_of_Residence: "İkamet Edilen Ülke",
      Continue: "Devam et",
      Already_account: "Zaten bir hesabınız var mı?",
      Login_Here: "Buradan giriş yapın",
      Verify_Device: "Cihazı Doğrula",
      Send_Again: "Yeniden gönder",
      History: "Geçmiş",
      Clear_All: "Tümünü temizle",
      Search_History: "Arama geçmişi",
      Backup_Phrase: "Yedekleme ifadesi",
      Simple_fast_and_secure: "Basit, hızlı ve güvenli",
      way_to_play_and_get_paid: "oynamanın ve para kazanmanın yolu.",
      Security: "Güvenlik",
      Profile: "Profil",
      Google_2_FA_Authentication: "Google 2 FA Kimlik Doğrulaması",
      Email_Authentication: "E-posta kimlik doğrulaması",
      Enable: "Olanak vermek",
      Disable: "Devre dışı bırakmak",
      Change_Account: "Hesabı Değiştir",
      Please_Wait: "Lütfen bekleyin...",
      Load_more: "Daha fazla yükle...",
      Record_Not_Found: "Kayıt bulunamadı!",
      Name: "İsim",
      User_Name: "Kullanıcı adı",
      Mobile_Number: "Cep numarası",
      Upload_PNG_or_JPG_format_only: "Yalnızca PNG veya JPG biçimini yükleyin",
      Enable_Google_Authenticator: "Google Authenticator'ı etkinleştirin",
      Step_4_Enabled_Google_Authenticator: "4. Adım: Google Authenticator'ı etkinleştirin",
      Enter_the_6_digit_code: "6 haneli kodu girin",
      Send_Code: "Kodu gönder",
      Key_copied_to_clipboard: "Anahtar panoya kopyalandı.",
      Address_copied: "Adres kopyalandı!",
      Please_check_mail_to_verify: "Lütfen doğrulamak için postayı kontrol edin",
      Ticket_sended: "Bilet gönderildi!",
      Currency_Balance: "Döviz Bakiyesi",
      Choose_Estimate_fee: "Tahmini ücreti seçin",
      Select_currency_to_swap: "Değiştirilecek para birimini seçin",
      Please_Enter_amount_to_swap: "Lütfen takas edilecek tutarı girin",
      Create_API: "API oluştur",
      One_or_more_IP_addresses_are_invalid: "Bir veya daha fazla IP adresi geçersiz",
      Payment_Link_Created: "Ödeme Bağlantısı Oluşturuldu!",
      Select_expire_time: "Sona erme süresini seçin",
      Change_Wallet: "Cüzdanı Değiştir",
      Search_Tokens: "Jeton Ara",
      Add_Custom: "Özel Ekle",
      Select_Token: "Jeton Seç",
      symbol: "sembol",
      Icon: "Simge",
      Decimals: "Ondalık Sayılar",
      Contract_Address: "Sözleşme Adresi",
      enter_symbol: "Sembol girin",
      enter_name: "isim giriniz",
      Icon_is_required: "Simge gerekli",
      Search: "Aramak",
      Download_the_oppi_crypto_wallet_app_to_securely_Send_Recive_swap_and_spend_multiple_cryptocurrencies_Take_full_control_of_your_finances_with_oppiwallet: "Birden fazla kripto parayı güvenli bir şekilde göndermek, almak, takas etmek ve harcamak için oppi kripto cüzdan uygulamasını indirin. Oppiwallet ile mali durumunuzun tam kontrolünü elinize alın.",
      Use_Oppi_wallet_on_your_computer: "Bilgisayarınızda Oppi cüzdanını kullanın",
      Open_Oppi_wallet_on_your_phone: "Telefonunuzda Oppi cüzdanını açın",
      Go_to_home_page_click_on: "Ana sayfaya gidin, tıklayın",
      icon_to_scan_the_code: "Kodu taramak için simge.",
      Simple_Seamless: "Basit. Sorunsuz.",
      Deposit_crypto: "Kripto yatır",
      easily_from: "kolayca",
      Take_control_of_your_crypto_Avoid_complicated_steps_and_deposit_directly_to_your_wallet_from_exchanges_like_Binance_and_Coinbase: "Kriptonuzun kontrolünü elinize alın. Karmaşık adımlardan kaçının ve Binance ve Coinbase gibi borsalardan doğrudan cüzdanınıza para yatırın.",
      Get_Started_With_Deposite: "Para Yatırma İşlemine Başlayın",
      Stay_private_and_secure: "Gizli ve güvende kalın",
      Rest_easy_knowing_that_our_privacy_and_security_measures_keep_you_in_control_of_your_data_and_digital_assets_while_also_keeping_them_safe:
        "Gizlilik ve güvenlik önlemlerimizin verilerinizi ve dijital varlıklarınızı kontrol altında tutarken aynı zamanda onları güvende tuttuğunu bilerek içiniz rahat olsun",
      True_ownership_of_your: "Gerçek mülkiyetiniz",
      crypto_assets: "kripto varlıkları",
      We_secure_your_wallet_but_dont_control_or_have_access_to_your_private_keys_or_secret_phrase_only_you_do: "Cüzdanınızı güvence altına alıyoruz, ancak özel anahtarlarınızı veya gizli ifadelerinizi kontrol etmiyoruz veya bunlara erişimimiz yok; yalnızca siz bunu yapabilirsiniz.",
      Get_Started: "Başlamak",
      One_of_the_most_secured_wallet: "En güvenli cüzdanlardan biri",
      Always_use_our_cloudbase_backup_facility_which_is_incrypted_in_order_to_increase_your_wallet_security: "Cüzdan güvenliğinizi artırmak için her zaman şifrelenmiş bulut tabanlı yedekleme olanağımızı kullanın",
      Hasslefree_solution_without_tracking: "Takip gerektirmeyen sorunsuz çözüm",
      We_are_not_tracking_any_personal_information_of_user_including_address_IP_or_wallet_balances: "Adres, IP veya cüzdan bakiyeleri dahil olmak üzere kullanıcının hiçbir kişisel bilgisini izlemiyoruz",
      One_stop_solution_for_all_assets: "Tüm varlıklar için tek noktadan çözüm",
      Buy_sell_and_swap_crypto_earn_rewards_manage_NFTs_and_discover_DApps_all_in_one_place: "Kripto satın alın, satın ve takas edin, ödüller kazanın, NFT'leri yönetin ve DApp'leri tek bir yerden keşfedin.",
      Whats_new_at_Oppi_Crypto: "Oppi Crypto'daki yenilikler neler?",
      Download_our_App_and__get_start: "Uygulamamızı indirin ve başlayın",
      send_recieve: "gönder ve al",
      Crypto: "Kripto",
      Lets_Talk: "Hadi Konuşalım !",
      curious_to_see_how_adjust_can_help_grow_your_app_Exponentially: "Ayarlamanın uygulamanızı katlanarak büyütmenize nasıl yardımcı olabileceğini merak mı ediyorsunuz?",
      Request_Demo: "Demo İste",
      Company: "Şirket",
      about_us: "Hakkımızda",
      Privacy_Policy: "Gizlilik Politikası",
      AML_Policy: "AML Politikası",
      Cookies_Policy: "Çerez Politikası",
      Terms_of_use: "Kullanım Şartları",
      Support_Center: "Destek Merkezi",
      Card_Activation: "Kart Aktivasyonu",
      API_Integration_Kit: "API Entegrasyon Kiti",
      FAQ: "SSS",
      Why_Us: "Neden biz",
      Oppi_for_business: "iş için hazırım",
      Swap_your_assets: "Varlıklarınızı değiştirin",
      Store_your_crypto: "Kriptonuzu saklayın",
      Oppi_empowers_crypto_community_to_expand_their_wealth: "Oppi, kripto topluluğuna servetlerini artırma gücü veriyor",
      Secure_Manage_and_Swap_cryptocurrency_on_web_and_mobile_wallets: "Web'de ve mobil cihazlarda kripto para birimini güvence altına alın, yönetin ve değiştirin cüzdanlar.",
      Explore_Oppi_Wallet: "Oppi Wallet'ı keşfedin",
      Secure_Manage: "Kripto para",
      and: ", Ve",
      crypto_currency: "kripto para",
      At_Oppi_we_pour_our_hearts_into_every_detail_from_pixel_perfect_icons_to_subtle_sounds_creating_a_cryptocurrency_experience_that_works_for_everyone: "Oppi'de, piksel mükemmelliğinde simgelerden incelikli seslere kadar her ayrıntıya kalbimizi döküyoruz ve herkes için işe yarayan bir kripto para birimi deneyimi yaratıyoruz.",
      Safe: "Güvenli",
      Trusted: "Güvenilir",
      Reliable: "Güvenilir",


    },
  },




  Portugal: {
    translation: {
      Wallet: 'Carteira',

    },
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Set the default language
  keySeparator: false, // Allow using dots in keys for nested translations
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
