import React from 'react';

const AmlPolicy = () => {
    return (
        <div className='w-full'>
            <div class="container">
                <div className='py-8'>
                    <h4 className='md:text-24 text-20 font-700 pb-8'>Anti-Money Laundering Policy</h4>
                    <div class="content-body bg-light-200 border border-light-gray rounded-[20px] lg:p-[50px] md:p-[24px] p-[20px]">
                        <div className="">
                            <h6 className='title'>Policy Statement</h6>
                            <p className='pera'>Encoin Limited is a legal, ethical and transparent company, and it takes the responsibility to ensure and maintain that its assets and resources are not being used for corruption, irregularities, or money laundering. The company has taken it upon itself to detect frauds, irregularities, abuse of position, and institutional gains.</p>
                        </div>
                        <div className="">
                            <h6 className='title'>Purpose</h6>
                            <p className='pera'>The purpose of this policy is to make that the company's financial processes and procedures are according to the anti-money laundering laws.</p>
                        </div>
                        <div className="">
                            <h6 className='title'>Scope</h6>
                            <p className='pera'>This policy applies to every entity related to Encoin Limited and its employees, directors, officers, contractors, or any third party working on behalf of the company.</p>
                            <p className='pera'>The policy is for internal use, and the administration is required to convey it to every concerned person or entity. Failure to comply with the policy will result in appropriate action.</p>
                        </div>
                        <div className="">
                            <h6 className='title'>Money Laundering</h6>
                            <p className='pera'>Money laundering refers to those assets that are money that is acquired in exchange for money or assets gained unlawfully. It also includes money spent for terror purposes, regardless of the means it was obtained.</p>
                            <p className='pera'>Under this policy, money earned by using the following means is considered money laundering, and it is prohibited;</p>
                            <ul className='ps-[40px] pb-[8px] custom_ul'>
                                <li className='text-[16px] ps-[20px]'>Money or assets received in exchange for criminal or unlawful acts. Money whose origin is not explicit or earned by assisting any activity in evading lawful means.</li>
                                <li className='text-[16px] ps-[20px]'>Property gained after any criminal activity and its origin, location, and disposition are not transparent.</li>
                                <li className='text-[16px] ps-[20px]'>Property which is promoting any unlawful activity</li>
                                <li className='text-[16px] ps-[20px]'>Terrorism financing</li>
                            </ul>
                        </div>
                        <div className="">
                            <h6 className='title'>Compliance</h6>
                            <p className='pera'>The company will establish a body responsible for the implementation of this policy.</p>
                            <p className='pera'>The company will carry out the procedure to identify any irregularity on behalf of any stakeholder under this policy. The company should;</p>
                            <ul className='ps-[40px] pb-[8px] custom_ul'>
                                <li className='text-[16px] ps-[20px]'>Identify all the financers of the company and verify their identity</li>
                                <li className='text-[16px] ps-[20px]'>Take special care where stakeholders want anonymity</li>
                                <li className='text-[16px] ps-[20px]'>Maintain proper records of the stakeholders</li>
                            </ul>
                            <p className='pera'>If anyone in the company knows or suspects that a person is involved in money laundering or terror financing, it is their responsibility to report such person to the body established by the company. In such a case, the company must</p>
                            <ul className='ps-[40px] pb-[8px] custom_ul' >
                                <li className='text-[16px] ps-[20px]'>Take the details of the people involved</li>
                                <li className='text-[16px] ps-[20px]'>Verify the type of transactions</li>
                                <li className='text-[16px] ps-[20px]'>Reason for suspicion</li>
                                <li className='text-[16px] ps-[20px]'>The amount involved</li>
                            </ul>
                            <p className='pera'>The company must consult with the legal department before embarking on business with a third party and carefully screen such interactions.</p>
                            <p className='pera'>The policy is subject to updates and amendments, which will be communicated to the stakeholder immediately.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AmlPolicy;
