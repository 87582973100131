import React from "react";

const IconDone = (props) => {
  return (
    <svg {...props} viewBox="0 0 11 8" fill="none">
      <path
        d="M10.0692 1.88074L4.6525 7.29731C4.49 7.45981 4.27668 7.5416 4.06336 7.5416C3.85004 7.5416 3.63672 7.45981 3.47422 7.29731L0.765937 4.58902C0.44 4.26324 0.44 3.73652 0.765937 3.41074C1.09172 3.08481 1.61828 3.08481 1.94422 3.41074L4.06336 5.52988L8.89094 0.702461C9.21672 0.376523 9.74328 0.376523 10.0692 0.702461C10.395 1.02824 10.395 1.5548 10.0692 1.88074Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default IconDone;
