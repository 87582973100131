import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react' // Import useState
import { Button } from '../ui/Button'
import Pending from '../../assets/gif/pending.json'
import Lottie from 'react-lottie-player'

const SendInformation = ({ open, setOpen, handleClose }) => {
    return (
        <Transition show={open} as={Fragment}>
            {/* Add the show prop to Transition */}
            <Dialog as="div" className="relative z-[100]" onClose={() => { return; }}> {/* Change setOpen(true) to setOpen(false) to close the dialog */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-[#000000cd] bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full text-white max-w-3xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                                <div className="p-8">
                                    <div className="flex items-center justify-center">
                                        <Lottie
                                            className='custome_lottie'
                                            loop
                                            animationData={Pending}
                                            play
                                            style={{ width: 120, height: 120 }}
                                        />
                                    </div>
                                    <div className="text-center">
                                        <p className='text-24 font-600 text-center mb-[8px]'>Confirmation</p>
                                        <p className='text-[15px] font-400'>Please accept confirmation request from your mobile app!</p>
                                        <p className='text-[15px] font-400'>Please wait your transaction is under process!</p>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default SendInformation
