import React from 'react'

const IcnRecharge = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.75 16.5176V15.0176C19.1625 15.0176 19.5 15.3551 19.5 15.7676H20.25C20.25 14.9426 19.575 14.2676 18.75 14.2676V13.5176H18V14.2676C17.175 14.2676 16.5 14.9426 16.5 15.7676C16.5 16.5926 17.175 17.2676 18 17.2676V18.7676C17.5875 18.7676 17.25 18.4301 17.25 18.0176H16.5C16.5 18.8426 17.175 19.5176 18 19.5176V20.2676H18.75V19.5176C19.575 19.5176 20.25 18.8426 20.25 18.0176C20.25 17.1926 19.575 16.5176 18.75 16.5176ZM17.25 15.7676C17.25 15.3551 17.5875 15.0176 18 15.0176V16.5176C17.5875 16.5176 17.25 16.1801 17.25 15.7676ZM18.75 18.7676V17.2676C19.1625 17.2676 19.5 17.6051 19.5 18.0176C19.5 18.4301 19.1625 18.7676 18.75 18.7676ZM2.25 11.2676H10.5V12.0176H2.25V11.2676ZM2.25 12.7676H9V13.5176H2.25V12.7676Z" fill="currentColor" />
            <path d="M18.375 12.7676C19.5 12.7676 20.55 13.2176 21.3 14.0051L20.325 14.4551L22.8 16.1426L23.025 13.1426L22.0125 13.6301C21.075 12.6176 19.8 12.0176 18.375 12.0176C15.975 12.0176 13.95 13.7426 13.575 16.0676L14.325 16.1801C14.625 14.2301 16.35 12.7676 18.375 12.7676ZM22.425 17.5676C22.125 19.5551 20.4 21.0176 18.375 21.0176C17.25 21.0176 16.2 20.5676 15.45 19.7801L16.425 19.3301L13.95 17.6426L13.725 20.6426L14.7375 20.1551C15.675 21.2051 16.95 21.8051 18.375 21.8051C20.775 21.8051 22.8 20.0801 23.175 17.7551L22.425 17.5676Z" fill="currentColor" />
            <path d="M13.0875 15.0176H1.5V9.76758H20.25V11.6051C20.5125 11.6801 20.775 11.7926 21 11.9426V3.01758H0.75V15.7676H12.8625C12.9 15.5051 12.975 15.2426 13.0875 15.0176ZM1.5 3.76758H20.25V6.01758H1.5V3.76758ZM1.5 6.76758H20.25V9.01758H1.5V6.76758Z" fill="currentColor" />
        </svg>

    )
}

export default IcnRecharge