import React, { useContext, useEffect, useState } from 'react';
import BinanceIcon from '../../assets/images/BinanceIcon.png';
import { RadioGroup } from '@headlessui/react';
import { Button } from '../../components/ui/Button';
import IconCoinReceive from "../../assets/svg/IconCoinReceive";
import NftIcon from "../../assets/images/NftListIcon.png"
import TransactionDetailModal from '../../components/model/TransactionDetailModal';
import { useQuery } from 'react-query';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { TRANSACTION_API_URL, WALLET_API_URL } from '../../api/api';
import { authStore } from '../../context/AuthProvider';
import IconCoinSend from '../../assets/svg/IconCoinSend';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { DateFomat, files, fixDecimal, seperateToken } from '../../Helper';
import Loader from '../../components/ui/Loader';
import { AuthSocketContext } from '../../context/AuthSocketProvider';
import { useLocation } from 'react-router-dom';
import defaultimage from '../../assets/images/logo.png'
import IcnEmpty from '../../assets/svg/IcnEmpty';


const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];


const ActiveCoins = () => {
  const { t } = useTranslation();
  const [plan, setPlan] = useState('coins');
  const axiosInstance = useAxiosPrivate();
  const [tokens, setTokens] = useState([])
  const [coin, setCoin] = useState();
  const [nfts, setNfts] = useState();
  const { user, userVaultAccount, tokenListLocal, userWalletAddress, userCurrencyRate } = authStore();
  const { currencyRate } = useContext(AuthSocketContext);

  const { state } = useLocation()
  const [coinList, setCoinList] = useState();
  useEffect(() => {
    if (state?.selectedTab == "topcoins") {
      setPlan('coins')
    } else {
      setPlan('nfts')
    }
  }, [state])

  // get coins]
  // async function getTokens() {
  //   const response = await axiosInstance.get(WALLET_API_URL.getToken);
  //   return response.data;
  // }
  // const { isLoading, data: tokenList, refetch } = useQuery(['tokenList'], () => getTokens(), { keepPreviousData: true, })

  const [isLoadingData, stIsLoading] = useState(false)
  useEffect(() => {
    if (tokenListLocal) {
      setTokens(tokenListLocal);
    } else {
      stIsLoading(true)
    }
  }, [tokenListLocal])


  async function getNfts() {
    const response = await axiosInstance.get(TRANSACTION_API_URL.nftList);
    return response.data;
  }
  const { data: nftList } = useQuery(['nftList'], () => getNfts(), { keepPreviousData: true, })


  async function getCoin() {
    const response = await axiosInstance.get(WALLET_API_URL.getCoinNew, {
      headers: {
        'encryptedkey': user?.encKey,
      }
    });

    response?.data?.forEach(token => {
      var matchingWallet = userWalletAddress.find(wallet => wallet.chainId === token.tokenId);
      if (token.tokenId === null) {
        matchingWallet = userWalletAddress.find(wallet => wallet.chainId === token.id);
      }
      if (matchingWallet) {
        token.walletAddress = matchingWallet.address;
      } else {
        token.walletAddress = null;
      }
    });

    for (const token of response.data) {
      if (token.tokenId === null) {
        const getBalance = await callMainTokenApi(token);
        token.balance = getBalance?.balance;
      } else {
        const getBalance = await callSubTokenApi(token);
        token.balance = getBalance?.balance;
      }
    }

    if (response.data && Object.keys(userCurrencyRate).length > 0) {
      response.data?.map((token) => {
        const tokenName = token?.symbol;
        const addUSDT = tokenName + "USDT";
        const comapreWithCurrencyRate = userCurrencyRate?.find((rate) => rate?.symbol == addUSDT)

        if (comapreWithCurrencyRate) {
          token.ratePrice = comapreWithCurrencyRate.price;
          return token;
        }
      })
    }
    if (response.data) {
      response.data?.map((token) => {
        const USDPrice = token?.balance * token?.ratePrice
        // if(USDPrice > 0){
        token.USDRateForUserBalance = USDPrice || 0;
        return token;
        // }

      })
    }
    return response.data;
  }

  const { isLoading, data: getCoinList, refetch } = useQuery(['getCoinList'], () => getCoin(), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: false, // Disable refetch on tab focus
  })

  const callMainTokenApi = async (token) => {
    try {
      const data = {
        'chain': token?.tokenSymbol ?? token?.symbol,
        'address': token?.walletAddress,
      }
      const response = await axiosInstance.post(WALLET_API_URL.getTokenBalance, data);
      return response.data;

    } catch (error) {
      console.log({ error })
    }
  }

  const callSubTokenApi = async (token) => {
    try {
      const data = {
        'chain': token?.tokenSymbol ?? token?.symbol,
        'address': token?.walletAddress,
        'contractAddress': token?.contractAddress,
      }
      const response = await axiosInstance.post(WALLET_API_URL.getSubTokenBalance, data);
      return response.data;
    } catch (error) {
      console.log({ error })
    }

  }

  useEffect(() => {
    if (getCoinList && Object.keys(userCurrencyRate).length > 0) {
      getCoinList?.map((token) => {
        const tokenName = token?.symbol;
        const addUSDT = tokenName + "USDT";
        const comapreWithCurrencyRate = userCurrencyRate?.find((rate) => rate?.symbol == addUSDT)
        if (comapreWithCurrencyRate) {
          token.ratePrice = comapreWithCurrencyRate.price;
          return token;
        }
      })
    }
    if (getCoinList) {
      getCoinList?.map((token) => {
        var newDecimal = ((token.tokenSymbol ?? "") === "TRX" && (token.symbol ?? "") === "USDT") || ((token.symbol ?? "") === "TRX")
          ? 1
          : Math.pow(10, token.decimal ?? 6);

        var newBalance = "";

        // Check if tokenSymbol is not empty
        if ((token.tokenSymbol ?? "").trim() !== "") {
          newBalance = (parseFloat(token?.balance) / newDecimal).toString();
        } else {
          newBalance = token?.balance;
        }

        // Set newBalance and calculate USD price
        token.newBalance = newBalance || 0;
        const USDPrice = parseFloat(newBalance) * parseFloat(token?.ratePrice);
        token.USDRateForUserBalance = USDPrice || 0;

        return token;
        // }

      })
    }

  }, [getCoinList])

  useEffect(() => {
    if (nftList) {
      setNfts(nftList?.data)
    }
  }, [nftList])

  const itemsPerPage = 6;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = (plan === "coins") ? tokens?.slice(itemOffset, endOffset) : nfts?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(currentItems?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % currentItems?.length;
    setItemOffset(newOffset);
  };

  const sortedCoinList = getCoinList?.sort((a, b) => {
    return parseFloat(b.USDRateForUserBalance) - parseFloat(a.USDRateForUserBalance);
  });

  return (
    <>
      {isLoading ? <Loader /> :
        <div className="p-12 bg-dark h-full">
          <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100 flex flex-col p-12'>
            <RadioGroup className="flex text-20 gap-2 p-3 bg-dark rounded-full w-[80%] mx-auto mb-12" value={plan} onChange={setPlan}>
              <RadioGroup.Option className="cursor-pointer w-[50%]" value="coins">
                {({ checked }) => (
                  <Button size="sm" className={`!text-14 py-4 w-full ${checked ? 'bg-theme' : 'bg-transparent text-gray'}`}>{t('Coins')}</Button>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option className="cursor-pointer w-[50%]" value="nfts">
                {({ checked }) => (
                  <Button size="sm" className={`!text-14 py-4 w-full ${checked ? 'bg-theme' : 'bg-transparent text-gray'}`}>{t('NFTs')}</Button>
                )}
              </RadioGroup.Option>
            </RadioGroup>
            <div className={`flex flex-col ${currentItems?.length === 0 ? "flex-grow justify-center items-center" : ""} gap-6`}>
              {
                plan === "coins" ?
                sortedCoinList.length > 0 ?  sortedCoinList?.some(token => 'newBalance' in token) == true ?
                    sortedCoinList?.map((token, index) => {
                      let decimalToDisplay = (token?.decimal && token?.decimal > 8) ? 8 : token?.decimal;
                      return (
                        <li key={index} className='p-[14px_0_14px] border-b border-b-light-300 flex justify-between last:border-0 w-full'>
                          <div className="flex gap-[14px] items-center">
                            {token?.icon ?
                              <img className='xxl:w-[48px] w-[36px] xxl:h-[48px] h-[36px] rounded-full object-cover' src={files(token?.icon, 'image')} alt="" />
                              : <div className='xxl:w-[48px] w-[36px] xxl:h-[48px] h-[36px] rounded-full bg-theme' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 600, borderRadius: '50%', fontSize: '26px', }}>{token?.name?.split(' ').map(name => name.slice(0, 1).toUpperCase())}</div>}
                            <div className="text">
                              <div className="sm:flex block items-center gap-[8px]">
                                <h5 className='text-white xxl:text-18 text-16'>{token?.name}</h5>
                                {token?.tokenId && <button className='text-gray sm:text-14 text-12 bg-dark p-[2px_6px] rounded-[6px]'>{token?.tokenName}</button>}
                                {/* <p className='text-green text-14'>+5.00%</p> */}
                              </div>
                              <p className='text-gray text-14'>{token?.code ? token?.code : token?.symbol ?? '-'}</p>

                            </div>
                          </div>
                          <div className="text-end ">
                            <h5 className='text-white xxl:text-18 text-16'>{parseFloat(fixDecimal(token?.newBalance)) || 0.00}</h5>
                            <p className='text-gray sm:text-14 text-12'>${token?.USDRateForUserBalance?.toFixed(2) || 0}</p>

                          </div>
                        </li>
                      )
                    }) : <Loader /> :
                    <div className="flex flex-col items-center justify-center">
                      {/* <h5 className='text-white flex items-center gap-[10px] text-[16px]'>{t('Record_Not_Found')}</h5> */}
                      <IcnEmpty className='h-[114px] w-[114px]' />
                      <p className='text-[14px] font-600 text-white'>There is nothing in the list.</p>
                    </div> :
                  nfts?.length ?
                    nfts?.map((data, index) => (
                      <li key={index} className='p-[14px_0_14px] border-b border-b-light-300 flex justify-between last:border-0 items-center'>
                        <div className="flex gap-[14px] items-center">
                          {/* <img className='xxl:w-[48px] w-[36px] xxl:h-[48px] h-[36px] object-cover' src={defaultimage} alt="" /> */}
                          {data?.media && data?.media.length === 0 ?
                            <img src={defaultimage} alt="OPPI" className='xxl:w-[48px] w-[36px] xxl:h-[48px] h-[36px] rounded-full ' />
                            :
                            <img className='xxl:w-[48px] w-[36px] xxl:h-[48px] h-[36px] rounded-full object-cover' src={data?.media?.[0]?.url} alt="" />
                          }
                          <div className="text">
                            <h5 className='text-white xxl:text-18 text-16'>{data?.name}</h5>
                            <p className='text-gray text-14'>#{data?.tokenId}</p>
                          </div>
                        </div>
                      </li>
                    )) :
                    <div className="flex flex-col items-center justify-center">
                      {/* <h5 className='text-white flex items-center gap-[10px] text-[16px]'>{t('Record_Not_Found')}</h5> */}
                      <IcnEmpty className='h-[114px] w-[114px]' />
                      <p className='text-[14px] font-600 text-white'>There is nothing in the list.</p>
                    </div>
              }
            </div>

            {
              currentItems?.length > 6 ?
                <div className="flex items-center justify-center mt-[25px]">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next  >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<  previous"
                    renderOnZeroPageCount={null}
                    className="text-white flex items-center gap-[10px] text-[16px]"
                    marginPagesDisplayed={2}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="text-white flex items-center gap-[10px] text-[16px]"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="bg-theme text-black flex items-center justify-center gap-[10px] text-[16px] w-[20px] h-[20px] rounded-full "
                  />
                </div> : ''
            }
          </div>
        </div >}
    </>
  )
}

export default ActiveCoins