import React from 'react'

const IconApi = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" >
      <g clipPath="url(#clip0_141_8099)">
        <mask id="mask0_141_8099"   maskUnits="userSpaceOnUse" x="-1" y="0" width="25" height="24">
          <path d="M23.625 0H-0.375V24H23.625V0Z" fill="currentcolor" />
        </mask>
        <g mask="url(#mask0_141_8099)">
          <path d="M9.51978 15.6225H3.78978C3.03819 15.622 2.30896 15.3668 1.72117 14.8984C1.13338 14.43 0.721756 13.7762 0.553533 13.0436C0.38531 12.3111 0.470424 11.5432 0.794969 10.8653C1.11951 10.1874 1.66432 9.63956 2.34041 9.31125C2.10517 8.68948 2.02429 8.01993 2.10472 7.36004C2.18515 6.70015 2.42448 6.06961 2.80218 5.52256C3.17988 4.97551 3.68468 4.52826 4.27324 4.2192C4.8618 3.91014 5.51657 3.74848 6.18134 3.74812C6.46615 3.74732 6.75023 3.7767 7.02884 3.83578C7.33768 2.85914 7.94964 2.00629 8.77595 1.40095C9.60225 0.795619 10.5999 0.469284 11.6242 0.469284C12.6485 0.469284 13.6462 0.795619 14.4725 1.40095C15.2988 2.00629 15.9108 2.85914 16.2196 3.83578C16.4982 3.77676 16.7823 3.74738 17.0671 3.74812C17.7321 3.74803 18.3872 3.90934 18.9762 4.21821C19.5651 4.52708 20.0703 4.97428 20.4483 5.5214C20.8263 6.06853 21.0659 6.69923 21.1465 7.35934C21.227 8.01946 21.1462 8.68926 20.9109 9.31125C21.5874 9.63925 22.1326 10.187 22.4575 10.8651C22.7823 11.5431 22.8676 12.3113 22.6993 13.044C22.531 13.7768 22.1192 14.4308 21.5311 14.8991C20.9429 15.3675 20.2133 15.6225 19.4615 15.6225H13.7287" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.0027 15.6225C15.2055 16.1676 15.2734 16.7538 15.2005 17.3308C15.1276 17.9079 14.9162 18.4587 14.5843 18.9364C14.2524 19.414 13.8098 19.8042 13.2943 20.0737C12.7789 20.3432 12.2059 20.4839 11.6242 20.4839C11.0426 20.4839 10.4696 20.3432 9.95413 20.0737C9.43868 19.8042 8.99609 19.414 8.66416 18.9364C8.33223 18.4587 8.1208 17.9079 8.04793 17.3308C7.97506 16.7538 8.04292 16.1676 8.24571 15.6225" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.9762 15.6225V17.618C4.97644 17.7568 5.03169 17.8898 5.12983 17.9879C5.22797 18.0861 5.361 18.1413 5.49979 18.1416H6.04823C6.19107 18.7783 6.44273 19.3857 6.79213 19.9369L6.40448 20.3245C6.35575 20.3732 6.31709 20.431 6.29072 20.4946C6.26434 20.5582 6.25077 20.6264 6.25077 20.6953C6.25077 20.7642 6.26434 20.8324 6.29072 20.896C6.31709 20.9596 6.35575 21.0174 6.40448 21.0661L7.44182 22.1053C7.5406 22.2028 7.67381 22.2575 7.8126 22.2575C7.9514 22.2575 8.0846 22.2028 8.18338 22.1053L8.57244 21.7172C9.1232 22.0659 9.72987 22.3172 10.3659 22.4602V23.0086C10.3663 23.1477 10.4217 23.281 10.5201 23.3794C10.6184 23.4778 10.7518 23.5332 10.8909 23.5336H12.359C12.498 23.533 12.6311 23.4775 12.7294 23.3791C12.8276 23.2808 12.883 23.1476 12.8835 23.0086V22.4602C13.5194 22.3169 14.1261 22.0656 14.677 21.7172L15.0651 22.1053C15.1637 22.2029 15.2969 22.2576 15.4356 22.2576C15.5744 22.2576 15.7076 22.2029 15.8062 22.1053L16.8454 21.0661C16.9429 20.9673 16.9976 20.8341 16.9976 20.6953C16.9976 20.5565 16.9429 20.4233 16.8454 20.3245L16.4573 19.9369C16.8057 19.3852 17.0572 18.778 17.2012 18.1416H17.7487C17.8876 18.1413 18.0207 18.0861 18.119 17.988C18.2173 17.8899 18.2727 17.7568 18.2732 17.618V15.6248" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16.5754 7.11797V11.7422" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.5312 7.11797V11.827" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14.1975 8.44781C14.1975 9.18234 13.5759 9.77624 12.8423 9.77624C12.4786 9.77624 11.5406 9.78328 11.5406 9.78328C11.5406 9.78328 11.535 8.81859 11.535 8.44781C11.535 8.14359 11.5312 7.1189 11.5312 7.1189H12.8438C13.5759 7.11796 14.1975 7.71421 14.1975 8.44781Z" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.43604 11.828L7.20416 7.18734C7.2119 7.16759 7.22541 7.15064 7.24293 7.13869C7.26044 7.12673 7.28116 7.12033 7.30237 7.12033C7.32357 7.12033 7.34429 7.12673 7.36181 7.13869C7.37933 7.15064 7.39283 7.16759 7.40057 7.18734L9.15322 11.828" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.98865 10.672H8.61365" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.6268 15.6225H11.624" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_141_8099">
          <rect width="23.25" height="24" fill="currentcolor" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconApi


