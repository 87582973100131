import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import IconDown from "../../assets/svg/IconDown";
import IconUs from "../../assets/svg/IconUs";
import IconTurkey from "../../assets/svg/IconTurkey";
import { authStore } from "../../context/AuthProvider";
import { useTranslation } from "react-i18next";
import IcnDot from "../../assets/svg/IcnDot";
import { useNavigate, useLocation } from "react-router-dom";

const versionList = [
  // {
  //   version: 'v0.1',
  //   page: '/api-documentation'

  // },
  {
    version: 'v1.0',
    page: '/v1/api-documentation'
  }
]


const VersionDropdown = () => {
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(versionList[0])
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const handleClick = (data) => {
    navigate(data.page);
    setOpen(false);
    setSelected(data);
  }

  useEffect(() => {
    pathname === '/api-documentation' && setSelected(versionList[0]);
    pathname === '/v2/api-documentation' && setSelected(versionList[1]);
  }, [pathname])

  return (
    <>
      <div className="relative">
        <div
          className="min-w-[70px] cursor-pointer outline-none text-[14px] flex items-center justify-between gap-3 border border-dark-gray py-[2px] ps-[8px] pe-[4px] rounded-[18px]"
          onClick={() => setOpen(!open)}>
          <span className="flex gap-1 items-center text-white">
            {selected.version}
          </span>
          <IconDown className="w-6 h-6 text-dark-gray" />
        </div>
        {
          open &&
          <div
            className="bg-white/20 backdrop-blur-sm border border-light-gray absolute w-full mt-2 rounded-lg px-2">
            {
              versionList.map((data, index) =>
                <span
                  className="flex items-center gap-2 cursor-pointer text-[14px] text-white py-2"
                  onClick={() => handleClick(data)}>{data.version} </span>
              )
            }
          </div>
        }
      </div>
    </>
  );
};

export default VersionDropdown;
