import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../../components/ui/Button'
import CustomSelect from '../../components/ui/CustomSelect'
import IconSwap from '../../assets/svg/IconSwap'
import SuccessMessageModal from '../../components/model/SuccessMessageModal'
import Input from '../../components/ui/Input'
import { useTranslation } from 'react-i18next'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { RECEIVE_API_URL, SEND_API_URL, SWAP_API_URL, WALLET_API_URL } from '../../api/api'
import { useMutation, useQuery } from 'react-query'
import { _randomString, fixDecimal, generateJwt, seperateToken } from '../../Helper'
import { authStore } from '../../context/AuthProvider'
import { useSnackbar } from 'notistack';
import SendConfirmationDialog from '../../components/ui/SendConfirmationDialog'
import { AuthSocketContext } from '../../context/AuthSocketProvider'
import Swal from 'sweetalert2'
import SendInformation from '../../components/ui/SendInformation'
import SwapToSelect from '../../components/ui/SwapToSelect'
import CustomSelectSwap from '../../components/ui/CustomSelectSwap'

const Swap = () => {
  const { t } = useTranslation();
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const axiosInstance = useAxiosPrivate();
  const [tokens, setTokens] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { user, encKey, set, userCurrencyBalance } = authStore();
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const [chnageAmount, setChangeAmount] = useState();
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [payinAddress, setPayinAddress] = useState();
  const [userVaultAccount, setUserVaultAccount] = useState([])
  const [feeLevel, setFeeLevel] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { setConfirmSwap, setApproveRejectSwap, approveRejectSwap } = useContext(AuthSocketContext)
  const [openSendInfo, setOpenSendInfo] = useState(false);
  const [toTokenList, setToTokenList] = useState([]);
  const [disableSwap, setDisableSwap] = useState(true);

  const [fromOptionList, setFromOptionList] = useState([]);
  const [toOptionList, setToOptionList] = useState([]);

  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true)
  }
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false)
    setFromAmount(null);
    setToAmount(null);
  }

  async function getTokens() {
    const response = await axiosInstance.get(WALLET_API_URL.getToken);
    return response.data;
  }

  const { data: tokenList, refetch } = useQuery(['tokenList'], () => getTokens(), { keepPreviousData: true, })

  useEffect(() => {
    setTokens(tokenList);
  }, [tokenList])


  useEffect(() => {
    if(userCurrencyBalance){
      setFromOptionList(userCurrencyBalance?.filter(item => item.changellyAsset));
    }
  }, [userCurrencyBalance])

  // async function getVaultAccount() {
  //   const response = await axiosInstance.get(WALLET_API_URL.getVaultAccount);
  //   return response.data;
  // }
  // const { isLoading, data: vaultAccountList, refetch: vaultAccount } = useQuery(['vaultAccount'], () => getVaultAccount(), { keepPreviousData: true })


  // useEffect(() => {
  //   if (vaultAccountList) {
  //     setUserVaultAccount(vaultAccountList);
  //   }
  // }, [vaultAccountList])

  // const fromBalance = userVaultAccount?.assets?.find(asset => asset.id === from?.symbol);
  // const toBalance = userVaultAccount?.assets?.find(asset => asset?.id === to?.toUpperCase());



  const getFromToken = (value) => {
    setToTokenList(null)
    setFromAmount("")
    setFrom(value);
    setTo(undefined)
    setToAmount(null);
    setChangeAmount(null)
  }
  const getToToken = (value) => {
    setTo(value);
  }

  useEffect(() => {
    const fetchToData = async () => {
      if (from) {
        const data = {
          // from: from?.code,
          tokenId: from?.id
        };
        await axiosInstance.post(SWAP_API_URL.getToTokenList, data).then((result) => {
          const toValues = result?.data?.result?.map(item => item.to);
          const matchedCurrencies = [];
          toValues.forEach((pairElement) => {
            userCurrencyBalance.forEach((currency) => {
              if ((currency.changellyAsset ?? "").toLowerCase() === pairElement.toLowerCase()) {
                matchedCurrencies.push(currency);
              }
            });
          });
          setToOptionList(matchedCurrencies);
          setToTokenList(result?.data?.result)
        }).catch((error) => {
          enqueueSnackbar(error?.response?.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          })
        });
      }
    };

    fetchToData();
  }, [from, axiosInstance]);



  useEffect(() => {
    const fetchData = async () => {
      if (from && to) {
        const data = {
          from: from?.id,
          to: to?.id
        };
        await axiosInstance.post(SWAP_API_URL.getChangeAmount, data).then((result) => {

          setChangeAmount(result?.data?.result?.[0]);
        }).catch((error) => {


          if (error?.response?.data.errors) {
            const errors = error?.response?.data.errors;
            Object.keys(errors).map(function (key) {
              enqueueSnackbar(errors[key], {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
              });
            });
          }
        });
      }
    };

    fetchData();
  }, [from, to, axiosInstance]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'from') {
      const toAmount = value * chnageAmount?.rate / 1;
      const convertedToAmount = toAmount;
      setToAmount(convertedToAmount);
      setFromAmount(value);
    } else if (name === 'to') {
      const fromAmountValue = value / chnageAmount?.rate;
      const convertedFromAmount = fromAmountValue;
      setFromAmount(convertedFromAmount);
      setToAmount(value);
    }
    setDisableSwap(false)
  }


  const handleSwap = async () => {
    if (!from && !to) {
      setLoading(false);
      enqueueSnackbar(t('Select_currency_to_swap'), {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
      return;
    }
    if (!fromAmount || !toAmount) {
      setLoading(false);
      enqueueSnackbar(t('Please_Enter_amount_to_swap'), {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
      return;
    }
    if (fromAmount && toAmount) {
      // Ensure fromAmount is less than balance && greater than min amount for transaction
      if (parseFloat(fromAmount) > parseFloat(from?.newBalance)) {
        setLoading(false);
        enqueueSnackbar(`From amount must be less than  ${fixDecimal(from?.newBalance)}.`, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        return;
      }
      if (parseFloat(fromAmount) < parseFloat(chnageAmount?.minFrom)) {
        setLoading(false);
        enqueueSnackbar(`From amount must be greater than minimum amount ${fixDecimal(chnageAmount?.minFrom)}.`, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        return;
      }
      setLoading(true)
      // const data = {
      //   assetId: to
      // }
      // await getAssetAddress(data);

      const data = {
        from: from?.id,
        to: to?.id,
        address: to?.address,
        amountFrom: fromAmount
      }
      await swapCoin(data);


    }
  }


  const { mutateAsync: getAssetAddress } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.get(RECEIVE_API_URL.getAccestAddress + '?assetId=' + data?.assetId);
        return response;
      } catch (error) {
        setLoading(false);
        if (error?.response?.data.message) {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          return
        }
      }
    },
    {
      onSuccess: async (response) => {
        const address = response?.data?.[0]?.address
        const data = {
          from: from?.id,
          to: to?.id,
          address: to?.address,
          amountFrom: fromAmount
        }
        await swapCoin(data)
      },
    }
  );


  const { mutateAsync: swapCoin } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(SWAP_API_URL.swapCoin, data);
        if (response) {

          const payinAddress = response?.data?.result?.payinAddress;
          const estimateAddData = {
            assetId: from?.symbol,
            amount: fromAmount,
            // sourceId: user?.vaultAccountAssetId,
            destinationIdOrAddress: payinAddress,
          };
          setPayinAddress(payinAddress);
          setOpenConfirmation(true)
          // await transactionEstimateFee(estimateAddData);
        }
      } catch (error) {
        setLoading(false);
        // throw error;
        if (error?.response?.data.message) {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    }
  );
  const { mutateAsync: transactionEstimateFee } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(SWAP_API_URL.transactionEstimateFee, data);
        if (response) {
          setFeeLevel(response?.data)
          setOpenConfirmation(true)
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error?.response?.data.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      }
    }
  );
  const onSwap = () => {
    const tempFrom = from;
    const tempTo = to;
    const tempFromAmount = toAmount;
    const tempToAmount = fromAmount;
    setFrom(tempTo);
    setTo(tempFrom);
    setFromAmount(tempFromAmount);
    setToAmount(tempToAmount);

  }
  useEffect(() => {
    document.title = 'Swap | Oppi Wallet'
  }, [])
  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
    setLoading(false)
  }


  const handleSubmitConfirm = () => {
    const data = {
      currencyName: (from?.tokenId ? `${from?.name?.toUpperCase()} (${from?.symbol})`:from?.name  ),
      // toCurrencyName: `${to.name?.toUpperCase()} (${to.symbol})`,
      toCurrencyName: (to?.tokenId ? `${to?.name?.toUpperCase()} (${to?.symbol})`:to?.name  ),
      receiverAddress: payinAddress,
      amount: fromAmount,
     
      encryptedKey: encKey,
      userId: user?._id,
      currencyCode: from?.symbol,
      toCurrencyCode: to?.symbol,
      // feeNativeCode: from?.tokenCode ? from?.tokenCode : from?.code ? from?.code : from?.symbol,
      tokenId: from?.id ?? null,
      totalReceivableAmount: fixDecimal(toAmount)
    }
    // console.log({data})
    // return false;
    set({ isSwapPayment: true })
    setConfirmSwap(data)
    setOpenConfirmation(false)
    setOpenSendInfo(true)
    setToAmount("");
    setFromAmount("");

    // resetForm()
  }
  useEffect(() => {
    if (approveRejectSwap !== null) {
      setOpenSendInfo(false)
      if (approveRejectSwap?.status == 1) {
        Swal.fire({
          icon: "success",
          title: "Swap Transaction?",
          text: "Your transaction request has been approved",
          showConfirmButton: true,
          timer: null,
          allowOutsideClick: false,
        });

      } else if (approveRejectSwap?.status == 2) {
        Swal.fire({
          icon: "error",
          title: "Swap Transaction?",
          text: "Your transaction request has been rejected",
          timer: null,
          showConfirmButton: true,
          allowOutsideClick: false,

        });
      }
      setApproveRejectSwap(null)
      setLoading(false);
      setFeeLevel(null)
      setConfirmSwap(null)
      setChangeAmount(null)
      setToAmount("");
      setFromAmount("");
      setFrom(undefined)
      setTo(undefined)
      setDisableSwap(true)

    }
  }, [approveRejectSwap])

  const handleCloseInfoDialog = () => {
    setOpenSendInfo(false)
  }

  // console.log({ tokens, fromOptionList, from, toOptionList, to, chnageAmount })

  return (
    <div className="p-12 bg-dark h-full">
      <div className='sm:w-[80%] max-w-[660px] bg-card min-h-full mx-auto rounded-[20px] border border-light-100 flex flex-col p-12'>
        <p className='text-white text-20 font-500 mb-12'>{t('Swap')}</p>
        <div className="p-4 pe-8 bg-dark md:rounded-full rounded-[12px] flex justify-between items-center md:flex-row flex-col">
          {/* <CustomSelect listButtonRootClass="justify-between rounded-full bg-dark py-[8.5px] md:min-w-[250px] min-w-[100%] md:mb-0 mb-[10px]" rootClass='w-full' data={tokens} getChildData={getFromToken} from={from} selectedCoin={from} /> */}
          <CustomSelectSwap listButtonRootClass="justify-between rounded-full bg-dark py-[8.5px] md:min-w-[250px] min-w-[100%] md:mb-0 mb-[10px]" rootClass='w-full' data={fromOptionList} getChildData={getFromToken} from={from} selectedCoin={from} />

          {/* <CustomSelect listButtonRootClass="w-full justify-between rounded-full bg-dark p-[14px] ps-[16px]" data={coins}
                getChildData={handleSelectedCoin} selectedCoin={selectedCoin} errorMsg={errors.coin && touched.coin ? errors.coin : null} type={'send'} /> */}


          <Input type='number' className="!bg-transparent p-[8.5px]" classWrapper="md:w-1/4 w-full" placeholder="0.00" value={fromAmount} name="from" /* readOnly={fromDisabled} */ onChange={(e) => handleInputChange(e)} />
        </div>
        <div className="flex items-start justify-between mt-4 sm:flex-row flex-col gap-[8px]">
          <div className="flex items-center gap-3">
            <div className="w-3 h-3 bg-linear-horizontal rounded-full"></div>
            <p className='text-white'>{from?.newBalance ? parseFloat(fixDecimal(from?.newBalance)) : '0.00'}</p>
          </div>

          <div>
            <p className='text-white text-14'>{t('Minimum')} : <span className='text_gradient'>{chnageAmount?.minFrom ? parseFloat(fixDecimal(chnageAmount?.minFrom)) : '0.00'} </span></p>
            <p className='text-white text-14'>{t('Maximum')} : <span className='text_gradient'>{chnageAmount?.maxFrom ? parseFloat(fixDecimal(chnageAmount?.maxFrom)) : '0.00'} </span></p>
          </div>
        </div>

        {
          from && (
            <>   <div className="relative py-24">
              <hr className='text-light-200' />
              <div className="p-[1px] bg-linear-horizontal sm:w-[70px] w-[45px] aspect-square rounded-full absolute left-[50%] sm:top-[20%] top-[26%] -translate-x-[50%] cursor-pointer">
                <div className="bg-dark h-full w-full p-2 rounded-full flex justify-center items-center">
                  <div className="bg-linear-horizontal h-full w-full rounded-full flex justify-center items-center">
                    <IconSwap className="sm:w-[36px] text-white" /* onClick={onSwap} */ />
                  </div>
                </div>
              </div>
            </div>
              <div className="p-4 pe-8 bg-dark md:rounded-full rounded-[12px] flex justify-between items-center md:flex-row flex-col">

                <CustomSelectSwap listButtonRootClass="justify-between rounded-full bg-dark py-[8.5px] md:min-w-[250px] min-w-[100%] md:mb-0 mb-[10px]" rootClass='w-full' data={toOptionList} getChildData={getToToken} to={to} selectedCoin={to} />

                <Input type='number' className="!bg-transparent p-[8.5px]" classWrapper="md:w-1/4 w-full" value={toAmount} placeholder="0.00" name="to" onChange={(e) => handleInputChange(e)} />

              </div>
              <div className="flex items-start justify-between mt-4 sm:flex-row flex-col gap-[8px]">
                <div className="flex items-center gap-3">
                  <div className="w-3 h-3 bg-linear-horizontal rounded-full"></div>
                  <p className='text-white'>{to?.newBalance ? parseFloat(fixDecimal(to?.newBalance)) : '0.00'}</p>
                </div>
                <div>
                  <p className='text-white text-14'>{t('Minimum')} : <span className='text_gradient'>{chnageAmount?.minTo ? parseFloat(fixDecimal(chnageAmount?.minTo)) : '0.00'} </span></p>
                  <p className='text-white text-14'>{t('Maximum')} : <span className='text_gradient'>{chnageAmount?.maxTo ? parseFloat((chnageAmount?.maxTo)) : '0.00'} </span></p>
                </div>
              </div></>
          )
        }

        <div className="mt-auto">
          <Button className={`w-full mt-20 text-[14px] ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} size="sm" disabled={disableSwap} onClick={()=>handleSwap()}>
            {loading ? 'Loading...' : t('Swap')}
          </Button>
        </div>
      </div>

      <SuccessMessageModal
        open={openSuccessModal}
        handleClose={handleCloseSuccessModal}
        message={"Swap Successfully"}
      />

      <SendConfirmationDialog open={openConfirmation} setOpen={setOpenConfirmation} from={from} to={to} feeLevel={feeLevel} amount={fromAmount} userBalance={from} handleClose={handleCloseConfirmation} address={payinAddress} handleSubmitConfirm={handleSubmitConfirm} />
      <SendInformation open={openSendInfo} setOpen={setOpenSendInfo} handleClose={handleCloseInfoDialog} />
    </div>
  )
}

export default Swap