import React from 'react'
import { Navigate } from 'react-router-dom';
import { authStore } from '../context/AuthProvider';
import BlockUser from '../components/common/BlockUser';

const AuthGuard = ({ children }) => {
    const state = authStore();
    const isAuthenticated = state.isAuthenticated;
    const isBlock = state.isBlock



    if ((!isAuthenticated)) {
        return <Navigate to={"/"} />;
    }

    // if(isBlock == 1){
    //     return <BlockUser />;
    // }

    return (<>{children}</>)


}

export default AuthGuard