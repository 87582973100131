import React from "react";
import { useRoutes } from "react-router-dom";
import AuthMaster from "../layouts/auth/AuthMaster";
import DashMaster from "../layouts/dashboard/DashMaster";
import PhraseMaster from "../layouts/auth/PhraseMaster";
import SettingMaster from "../layouts/dashboard/SettingMaster";
import WebMaster from "../layouts/website/WebMaster";
import GuestGuard from "../guards/GuestGuard";
import PhraseGuard from "../guards/PhraseGuard";
import AuthGuard from "../guards/AuthGuard";
import { authStore } from "../context/AuthProvider";
import BlockUser from "../components/common/BlockUser";
import KycPending from "../components/common/KycPending";
import DocumentationVersion2 from "../pages/Api-Doc/DocumentationVersion2";

// Import components directly
import Login from "../pages/auth/Login";
import VerifyDevice from "../pages/auth/VerifyDevice";
import Register from "../pages/auth/Register";
import VerifyKeyPhrase from "../pages/auth/VerifyKeyPhrase";
import CreatePhrase from "../pages/auth/CreatePhrase";
import SelectSecretPhrase from "../pages/auth/SelectSecretPhrase";
import ForgotPassword from "../pages/auth/ForgotPassword";
import DashBoard from "../layouts/dashboard/DashBoard";
import NftDetails from "../components/dash/NftDetails";
import Send from "../pages/send/Send";
import SelectAddress from "../pages/send/SelectAddress";
import Receive from "../pages/Receive/Receive";
import Transaction from "../pages/Transaction/Transaction";
import Swap from "../pages/Swap/Swap";
import Api from "../pages/Api/Api";
import CreateApi from "../pages/Api/CreateApi";
import Payment from "../pages/Payment/Payment";
import Browser from "../pages/Browser/Browser";
import BrowserHistory from "../pages/Browser/BrowserHistory";
import Setting from "../pages/Setting/Setting";
import GoogleAuth from "../pages/Setting/Security/GoogleAuth";
import BackupPhrase from "../pages/Setting/BackupPhrase/BackupPhrase";
import SelectBackupPhrase from "../pages/Setting/BackupPhrase/SelectBackupPhrase";
import BackupCloud from "../pages/Setting/BackupPhrase/BackupCloud";
import BackupManually from "../pages/Setting/BackupPhrase/BackupManually";
import Support from "../pages/Setting/Support/Support";
import SupportDetail from "../pages/Setting/Support/SupportDetail";
import Language from "../pages/Setting/Language/Language";
import Kyc from "../pages/Kyc/Kyc";
import LandingPage from "../pages/Website/LandingPage/LandingPage";
import AboutUs from "../pages/Website/AboutUs/AboutUs";
import Swaps from "../pages/Website/Swaps/Swaps";
import StoreCrypto from "../pages/Website/StoreCrypto/StoreCrypto";
import CryptoDetail from "../pages/Website/StoreCrypto/CryptoDetail";
import Business from "../pages/Website/Business/Business";
import Faq from "../pages/Website/Faq";
import ContactSupport from "../pages/Website/ContactSupport";
import ContactUs from "../pages/Website/ContactUs/ContactUs";
import PrivacyPolicy from "../pages/Website/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "../pages/Website/CookiePolicy/CookiePolicy";
import TermCondition from "../pages/Website/TermandCondition/TermCondition";
import AmlPolicy from "../pages/Website/AML_policy/AmlPolicy";
import SwitchWallet from "../pages/Account/SwitchWallet";
import Notification from "../pages/notification";
import ActiveCoins from "../pages/auth/ActiveCoins";
import WebLogin from "../pages/auth/WebLogin";
import Documentation from "../pages/Api-Doc/Documentation";
import CardActivation from "../pages/Website/Card_Activation/CardActivation";

const Router = () => {
  const { isBlock, user } = authStore();

  const element = useRoutes([
    {
      path: "/login",
      element: (
        <GuestGuard>
          <AuthMaster />
        </GuestGuard>
      ),
      children: [
        { index: true, element: <WebLogin /> },
        { path: "verify-device", element: <VerifyDevice /> },
      ],
    },
    {
      path: "/register",
      element: (
        <GuestGuard>
          <AuthMaster />
        </GuestGuard>
      ),
      children: [
        { index: true, element: <Register /> },
        { path: "verify-device", element: <VerifyDevice /> },
        { path: "verify-key", element: <VerifyKeyPhrase /> },
      ],
    },
    {
      path: "/create-phrase",
      element: <PhraseMaster />,
      children: [
        { index: true, element: <CreatePhrase /> },
        { path: "select", element: <SelectSecretPhrase /> },
        { path: "verify-key", element: <VerifyKeyPhrase /> },
      ],
    },
    {
      path: "/forgot-password",
      element: <AuthMaster />,
      children: [{ index: true, element: <ForgotPassword /> }],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashMaster />
        </AuthGuard>
      ),
      children: [
        { index: true, element: isBlock == 1 ? <BlockUser /> : <DashBoard /> },
        { path: "nft-detail", element: isBlock == 1 ? <BlockUser /> : <NftDetails /> },
        { path: "send", element: isBlock == 1 ? <BlockUser /> : <Send /> },
        { path: "select-address", element: isBlock == 1 ? <BlockUser /> : <SelectAddress /> },
        { path: "receive", element: isBlock == 1 ? <BlockUser /> : <Receive /> },
        { path: "transactions", element: isBlock == 1 ? <BlockUser /> : <Transaction /> },
        { path: "swap", element: isBlock == 1 ? <BlockUser /> : <Swap /> },
        { path: "api", element: isBlock == 1 ? <BlockUser /> : user?.isKyc == 0 ? <KycPending /> : <Api /> },
        { path: "create-api", element: isBlock == 1 ? <BlockUser /> : <CreateApi /> },
        // { path: "payment", element: isBlock == 1 ? <BlockUser /> : user?.isKyc == 0 ? <KycPending /> : <Payment /> },
        { path: "payment", element: isBlock == 1 ? <BlockUser /> : <Payment /> },
        { path: "browser", element: isBlock == 1 ? <BlockUser /> : <Browser /> },
        { path: "browser-history", element: isBlock == 1 ? <BlockUser /> : <BrowserHistory /> },
        { path: "active-coins", element: isBlock == 1 ? <BlockUser /> : <ActiveCoins /> },
        { path: "switch-account", element: isBlock == 1 ? <BlockUser /> : <SwitchWallet /> },
        { path: "notification", element: isBlock == 1 ? <BlockUser /> : <Notification /> },
        { path: "user-blocked", element: <BlockUser /> },
        {
          path: "setting",
          element: isBlock == 1 ? <BlockUser /> : <SettingMaster />,
          children: [
            { index: true, element: <Setting /> },
            { path: "google-auth", element: <GoogleAuth /> },
            { path: "backup-phrase", element: <BackupPhrase /> },
            { path: "select-backup-phrase", element: <SelectBackupPhrase /> },
            { path: "backup-cloud", element: <BackupCloud /> },
            { path: "backup-manually", element: <BackupManually /> },
            { path: "support", element: <Support /> },
            { path: "support-detail", element: <SupportDetail /> },
            { path: "language", element: <Language /> },
          ],
        },
        { path: "kyc", element: isBlock == 1 ? <BlockUser /> : <Kyc /> },
      ],
    },
    {
      path: "/",
      element: <WebMaster />,
      children: [
        { path: "/", element: <LandingPage /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/swap", element: <Swaps /> },
        { path: "/store-crypto", element: <StoreCrypto /> },
        { path: "/crypto-detail", element: <CryptoDetail /> },
        { path: "/business", element: <Business /> },
        { path: "/faq", element: <Faq /> },
        { path: "/api-documentation", element: <Documentation /> },
        { path: "/v1/api-documentation", element: <DocumentationVersion2 /> },
        { path: "/support", element: <ContactSupport /> },
        { path: "/contact-us", element: <ContactUs /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/cookie-policy", element: <CookiePolicy /> },
        { path: "/term-condition", element: <TermCondition /> },
        { path: "/aml-policy", element: <AmlPolicy /> },
        { path: "/card-activation", element: <CardActivation /> },
      ],
    },
  ]);

  return <>{element}</>;
};

export default Router;
