import React from 'react'

const IcnEmpty = (props) => {
    return (
        <svg {...props} viewBox="0 0 114 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path d="M93.7619 44.8956L57 44.8953L67.722 31.0461C68.5227 30.0119 70.248 29.3506 72.1457 29.3506H101.97C103.754 29.3506 104.935 30.4636 104.182 31.4358L93.7619 44.8956Z" fill="url(#paint0_linear_1771_5389)" />
                <path d="M20.2368 44.8956H56.9986L46.2768 31.0461C45.4761 30.0119 43.7508 29.3506 41.8531 29.3506H12.0285C10.2447 29.3506 9.06401 30.4636 9.81663 31.4358L20.2368 44.8956Z" fill="url(#paint1_linear_1771_5389)" />
            </g>
            <path opacity="0.6" d="M20.2451 63.8954C20.2451 57.2447 20.2451 53.9195 21.5394 51.3793C22.6779 49.1449 24.4945 47.3282 26.7291 46.1898C29.2692 44.8955 32.5945 44.8955 39.245 44.8955H93.7531V76.5679C93.7531 83.2184 93.7531 86.5438 92.4588 89.0839C91.3203 91.3185 89.5037 93.1351 87.2693 94.2735C84.729 95.5678 81.4039 95.5678 74.7531 95.5678H39.245C32.5945 95.5678 29.2692 95.5678 26.7291 94.2735C24.4945 93.1351 22.6779 91.3185 21.5394 89.0839C20.2451 86.5438 20.2451 83.2184 20.2451 76.5679V63.8954Z" fill="url(#paint2_linear_1771_5389)" />
            <path d="M20.2451 44.8955H56.9992V95.5678H32.4433C25.7064 95.5678 20.2451 90.1065 20.2451 83.3696V44.8955Z" fill="url(#paint3_linear_1771_5389)" />
            <g opacity="0.5">
                <path d="M20.2353 44.8955H56.9972L46.2405 68.0095C45.4612 69.6843 43.7815 70.7552 41.9341 70.7552H11.9257C10.1892 70.7552 9.03976 68.9526 9.77243 67.3782L20.2353 44.8955Z" fill="url(#paint4_linear_1771_5389)" />
                <path d="M93.7638 44.8955H57.002L67.7585 68.0095C68.538 69.6843 70.2177 70.7552 72.0651 70.7552H102.073C103.81 70.7552 104.959 68.9526 104.227 67.3782L93.7638 44.8955Z" fill="url(#paint5_linear_1771_5389)" />
            </g>
            <g opacity="0.5">
                <path d="M73.5114 24.7554C72.9354 24.423 72.6475 24.2567 72.4923 24.0175C72.3556 23.8073 72.2893 23.5591 72.3024 23.3088C72.3173 23.024 72.4834 22.736 72.8161 22.1601L77.7969 13.5331C78.1293 12.9571 78.2957 12.6691 78.5348 12.5139C78.745 12.3773 78.9932 12.3108 79.2437 12.3239C79.5283 12.3388 79.8164 12.5051 80.3922 12.8376L82.2433 13.9064C82.8194 14.2389 83.1073 14.4052 83.2627 14.6443C83.3992 14.8546 83.4657 15.1028 83.4526 15.3532C83.4377 15.6379 83.2714 15.9259 82.9389 16.5018L77.9581 25.1288C77.6255 25.7048 77.4594 25.9927 77.2202 26.1481C77.0098 26.2846 76.7618 26.3512 76.5113 26.3381C76.2265 26.3231 75.9386 26.1568 75.3626 25.8242L73.5114 24.7554Z" fill="url(#paint6_linear_1771_5389)" />
                <path d="M56.1157 20.5692C55.4507 20.5692 55.1182 20.5692 54.8642 20.4398C54.6406 20.3259 54.459 20.1443 54.3452 19.9208C54.2158 19.6668 54.2158 19.3343 54.2158 18.6692V8.70761C54.2158 8.04254 54.2158 7.71002 54.3452 7.45599C54.459 7.23256 54.6406 7.05089 54.8642 6.93705C55.1182 6.80762 55.4507 6.80762 56.1157 6.80762H58.2532C58.9183 6.80762 59.251 6.80762 59.5048 6.93705C59.7283 7.05089 59.91 7.23256 60.0238 7.45599C60.1532 7.71002 60.1532 8.04254 60.1532 8.70761V18.6692C60.1532 19.3343 60.1532 19.6668 60.0238 19.9208C59.91 20.1443 59.7283 20.3259 59.5048 20.4398C59.251 20.5692 58.9183 20.5692 58.2532 20.5692H56.1157Z" fill="url(#paint7_linear_1771_5389)" />
                <path d="M32.0751 13.7228C31.4991 14.0553 31.2112 14.2216 31.056 14.4606C30.9193 14.671 30.8529 14.9191 30.8658 15.1696C30.8808 15.4543 31.0471 15.7423 31.3797 16.3182L36.3605 24.9452C36.693 25.5213 36.8593 25.8091 37.0984 25.9643C37.3086 26.101 37.5569 26.1674 37.8074 26.1545C38.0919 26.1396 38.38 25.9732 38.9558 25.6406L40.807 24.5718C41.383 24.2394 41.6709 24.0731 41.8263 23.834C41.9628 23.6238 42.0294 23.3755 42.0162 23.125C42.0013 22.8405 41.835 22.5524 41.5026 21.9765L36.5218 13.3495C36.1891 12.7735 36.0228 12.4855 35.7839 12.3303C35.5735 12.1937 35.3252 12.1272 35.0749 12.1403C34.7902 12.1552 34.5023 12.3215 33.9262 12.654L32.0751 13.7228Z" fill="url(#paint8_linear_1771_5389)" />
            </g>
            <path d="M38.9141 84.88C38.9141 84.2241 39.4458 83.6924 40.1015 83.6924H49.8683C50.524 83.6924 51.0557 84.2241 51.0557 84.88V88.4425C51.0557 89.0983 50.524 89.63 49.8683 89.63H40.1015C39.4458 89.63 38.9141 89.0983 38.9141 88.4425V84.88Z" fill="url(#paint9_linear_1771_5389)" />
            <defs>
                <linearGradient id="paint0_linear_1771_5389" x1="49.8628" y1="14.5827" x2="49.8628" y2="57.6954" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C3C3C3" stop-opacity="0.91" />
                    <stop offset="1" stop-color="#F1F1F1" stop-opacity="0.62" />
                </linearGradient>
                <linearGradient id="paint1_linear_1771_5389" x1="49.8631" y1="14.5827" x2="49.8631" y2="57.6954" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C3C3C3" stop-opacity="0.91" />
                    <stop offset="1" stop-color="#F1F1F1" stop-opacity="0.62" />
                </linearGradient>
                <linearGradient id="paint2_linear_1771_5389" x1="1.30887" y1="113.101" x2="106.732" y2="44.8955" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8D8D8D" stop-opacity="0.91" />
                    <stop offset="1" stop-color="#D1D1D1" stop-opacity="0.62" />
                </linearGradient>
                <linearGradient id="paint3_linear_1771_5389" x1="8.69349" y1="44.8955" x2="63.7152" y2="88.8726" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ECECEC" />
                    <stop offset="1" stop-color="#BFBFBF" />
                </linearGradient>
                <linearGradient id="paint4_linear_1771_5389" x1="14.4104" y1="86.8079" x2="41.1664" y2="31.1251" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8F8F8F" stop-opacity="0.91" />
                    <stop offset="1" stop-color="#D2D2D2" stop-opacity="0.62" />
                </linearGradient>
                <linearGradient id="paint5_linear_1771_5389" x1="14.4103" y1="86.8079" x2="41.1662" y2="31.1251" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8F8F8F" stop-opacity="0.91" />
                    <stop offset="1" stop-color="#D2D2D2" stop-opacity="0.62" />
                </linearGradient>
                <linearGradient id="paint6_linear_1771_5389" x1="49.249" y1="62.7653" x2="88.6565" y2="-10.1533" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8F8F8F" stop-opacity="0.91" />
                    <stop offset="1" stop-color="#D2D2D2" stop-opacity="0.29" />
                </linearGradient>
                <linearGradient id="paint7_linear_1771_5389" x1="49.2495" y1="62.7656" x2="88.657" y2="-10.153" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8F8F8F" stop-opacity="0.91" />
                    <stop offset="1" stop-color="#D2D2D2" stop-opacity="0.29" />
                </linearGradient>
                <linearGradient id="paint8_linear_1771_5389" x1="49.249" y1="62.7659" x2="88.6565" y2="-10.1527" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8F8F8F" stop-opacity="0.91" />
                    <stop offset="1" stop-color="#D2D2D2" stop-opacity="0.29" />
                </linearGradient>
                <linearGradient id="paint9_linear_1771_5389" x1="54.4038" y1="94.063" x2="33.9949" y2="71.9533" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.85" />
                    <stop offset="1" stop-color="white" stop-opacity="0.37" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default IcnEmpty