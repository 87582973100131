import React from 'react'
import { Button } from '../../components/ui/Button'
import { useNavigate } from 'react-router-dom'

const VerifyKeyPhrase = () => {
  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    navigate("/login/verify-device")
  }
  return (
    <section className='login-screen bg-light-200 sm:p-20 p-10 rounded-[30px] max-w-[600px] mx-auto'>
      <h4 className='sm:mb-8 mb-10 font-600'>
        Verify Key phrase
      </h4>
      <form action="" onSubmit={handleSubmit}>
        <p className='text-light-600 my-6 font-400'>Enter your twelve word Secret Private Key Recovery Phrase (Seed Phrase) to restore wallet. Separate each word with a space.</p>
        <textarea cols={3} rows={4} className="w-full p-6 bg-light-300 border-gray rounded-3xl text-gray-600 text-14 outline-0 font-400" placeholder='Enter Email Name' />
        <Button className="w-full mt-20" size={"sm"}>
          Submit
        </Button>
      </form>
    </section>
  )
}

export default VerifyKeyPhrase