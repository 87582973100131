import React from 'react';
import NftIcon from "../../assets/images/NftListIcon.png"

const BrowserHistory = () => {
  return (
    <div className="p-12 bg-dark h-full">
      <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100 flex flex-col p-12'>
        <div className="flex justify-between items-center mb-8">
          <p className='text-white text-20 font-500'>Search History</p>
          <div className="py-2 px-8 bg-dark rounded-full border border-light-100 cursor-pointer">
            <p className='text-white'>Clear All</p>
          </div>
        </div>
        <div className="flex flex-col">
          {
            [...Array(10)].map((data, index) =>
              <div className="py-4 w-full flex justify-between items-center border-b border-b-light-100">
                <div className="token-detail flex items-center w-full">
                  <img className='sm:w-[44px] w-[35px] aspect-square rounded-full mr-4' src={NftIcon} alt="" />
                  <div className="flex justify-between items-center w-full">
                    <div className="text">
                      <h6 className='text-14 font-500 text-white mb-2'>Google Search</h6>
                      <p className='text-12 font-400 text-gray'>Lorem Ipsum is simply dummy text of the printing and</p>
                    </div>
                    <p className='text-12 text-gray'>10 Jan 2022</p>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div >
  )
}

export default BrowserHistory