import React, { useEffect, useState } from "react";
import { HOME_API_URL } from "../../../api/api";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import { useQuery } from "react-query";
import { files } from "../../../Helper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NewFeature = () => {
  const [blog, setBlog] = useState();
  const axiosInstance = useAxiosPrivate();
  const navigate = useNavigate();
  const {t} = useTranslation();

  async function getBlogs() {
    const response = await axiosInstance.get(HOME_API_URL.blogList);
    return response.data;
  }
  const { isLoading, data: blogList, refetch } = useQuery(['blogList'], () => getBlogs(), { keepPreviousData: true, })

  useEffect(() => {
    if (blogList) {
      setBlog(blogList);
    }

  }, [blogList])

  return (
    <section className="container pt-24">
 
      <h4 className="text-card md:text-40 sm:text-32 text-28 font-600 text-center pb-14" data-aos="fade-up" data-aos-duration="1500">{t('Whats_new_at_Oppi_Crypto')}</h4>
  
      <div className="flex items-start justify-center flex-wrap -p-[8px]">
        {
          blog?.map((item, index) => {
            return (
              <div className="p-[8px] mb-[24px] md:w-1/3 w-full" data-aos="zoom-in-right" data-aos-duration="1500">
                <div className="rounded-[8px] overflow-hidden w-full cursor-pointer" onClick={() => navigate("/crypto-detail", { state: { blogDetail: item } })}>
                  <img src={files(item?.image, 'image')} alt="attachment" className="w-full h-full aspect-[9/6]" />
                </div>
                <h6 className="text-card text-14 font-400 md:py-[24px] py-[10px]">{item?.categoriesName}</h6>
                <h2 className="text-card text-22 font-600 line-clamp-2">{item?.title}</h2>
              </div>
            )
          }
          )
        }
      </div>
    </section>
  );
};

export default NewFeature;
