import React from 'react'

const IcnGoldChip = (props) => {
    return (
        <svg {...props} viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <g opacity="0.9" filter="url(#filter0_d_6908_12760)">
                    <rect x="5.11683" y="4.95924" width="34.4853" height="26.1442" rx="4.10539" fill="url(#paint0_linear_6908_12760)" stroke="#9F7E3B" stroke-width="0.547386" />
                    <path d="M5.3905 12.8965H14.6961C15.9053 12.8965 16.8856 13.8768 16.8856 15.086V19.3283M16.8856 30.8507V25.76M16.8856 25.76V19.3283M16.8856 25.76H5.3905M16.8856 19.3283H5.3905" stroke="black" stroke-opacity="0.76" stroke-width="0.547386" />
                    <path d="M39.3284 12.8963H28.3807M28.3807 12.8963V19.3281M28.3807 12.8963V5.23291M28.3807 30.8506V25.7599M28.3807 25.7599V19.3281M28.3807 25.7599H39.3284M28.3807 19.3281H39.3284" stroke="black" stroke-opacity="0.76" stroke-width="0.547386" />
                </g>
            </g>
            <defs>
                <filter id="filter0_d_6908_12760" x="0.464055" y="0.306641" width="43.7908" height="45.9804" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6.56863" />
                    <feGaussianBlur stdDeviation="2.18954" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6908_12760" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6908_12760" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_6908_12760" x1="4.84314" y1="4.68555" x2="39.8758" y2="31.5074" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E6AC3C" />
                    <stop offset="1" stop-color="#FFDA93" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default IcnGoldChip