import React, { useEffect, useState } from 'react'
import { Button } from '../../components/ui/Button'
import { generate } from 'random-words';
import { isEmpty, shuffle } from 'lodash';
import { useSnackbar } from 'notistack';
import {  handleEncrypt } from '../../Helper';
import { USER_API_URL } from '../../api/api';
import { useMutation } from 'react-query';
import { authStore } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../api/useAxiosPrivate';


const SelectSecretPhrase = () => {
  const [selectPhrase, setSelectPhrase] = useState(false)
  const [phrases, setPhrases] = useState([]);
  const [shuffledWords, setShuffledWords] = useState([]);
  const [arrangedWords, setArrangedWords] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const setHasEncyptionWordKey = authStore((state) => state.setHasEncyptionWordKey);
  const navigate = useNavigate();
  const axiosInstance = useAxiosPrivate();


  // generate random 12 words
  useEffect(() => {
    if (isEmpty(phrases)) {
      const words = generate({ exactly: 12 });
      setPhrases(words);
    }
  })

  const handleCopy = () => {
    const wordsToCopy = phrases.join(', ');
    navigator?.clipboard?.writeText(wordsToCopy).then(() => {
      console.log('Words copied to clipboard:', wordsToCopy);
    });
  }

  useEffect(() => {
    if (selectPhrase && isEmpty(shuffledWords)) {
      const shuffledWords = shuffle(phrases);
      setShuffledWords(shuffledWords);
    }

  }, [selectPhrase])

  const arrangeWordsByOrder = (clickedWord) => {
    // Check if the word is already arranged
    if (arrangedWords.includes(clickedWord)) {
      // Remove from arrangedWords and append to shuffledWords
      const updatedArrangedWords = arrangedWords.filter((word) => word !== clickedWord);
      setArrangedWords(updatedArrangedWords);
      setShuffledWords([...shuffledWords, clickedWord]);
    } else {
      // Remove from shuffledWords and append to arrangedWords
      const updatedShuffledWords = shuffledWords.filter((word) => word !== clickedWord);
      setShuffledWords(updatedShuffledWords);
      setArrangedWords([...arrangedWords, clickedWord]);
    }
  }

  const handleWords = async () => {
    const arrayMatched = await arrayMatch();
    if (arrayMatched) {
      setShuffledWords([])
      const getSecretKey = handleEncrypt(arrangedWords);
      const data = {
        encyption_word_key: getSecretKey,
        isBackup: 1
      }
      await storeSecreat(data)
    }
  }


  const { mutateAsync: storeSecreat } = useMutation(
    async (data) => {
     try {
      const response = await axiosInstance.post(USER_API_URL.storeKey, data).then((res) => res.data)
      if(response){
        setHasEncyptionWordKey(1)
        enqueueSnackbar(response?.data.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        navigate("/dashboard");
      }
     } catch (error) {
       if (error?.response?.data.errors) {
         const errors = error?.response?.data.errors;
         Object.keys(errors).map(function (key) {
           enqueueSnackbar(errors[key], {
             variant: "error",
             anchorOrigin: { vertical: "top", horizontal: "right" },
             autoHideDuration: 2000,
           });
         });
       }
      }
    },
  );

  const arrayMatch = () => {
    // Check if the lengths are not equal
    if (arrangedWords.length !== phrases.length) {
      enqueueSnackbar("Select all words!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });

      return false;
    }

    // Check if words are in correct order as phrases
    for (let i = 0; i < phrases.length; i++) {
      if (phrases[i] !== arrangedWords[i]) {
        enqueueSnackbar("Selected Words are not matched! Please select words in order!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        return false;
      }
    }

    return true;
  };

  return (
    <div className="p-12 bg-[#000] h-full">
      <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100'>
        <p className='text-white p-8 border-b border-b-light-100 font-500'>Your Secret phrase</p>


        {/* selecte phrase */}
        {
          selectPhrase ?
            <div className="py-12 sm:px-20 px-12 flex flex-col min-h-[72vh]">
              <div className="flex-grow h-full">
                <p className='text-gray font-400 mb-12'>tap the words to put them next to each other in the correct order.</p>
                <div className="flex gap-6 flex-wrap bg-[#000] py-12 px-6 rounded-3xl mb-8">
                  {
                    arrangedWords.map((phrase, i) => {
                      return <div className="phrase flex gap-2 bg-card p-[4px] pr-[20px] rounded-full b order border-light-100">
                        <span className='w-[28px] h-[28px] bg-theme rounded-full flex justify-center items-center text-14 font-500'>{i + 1}</span>
                        <span className='text-white text-20 font-400' onClick={() => arrangeWordsByOrder(phrase)}>{phrase}</span>
                      </div>
                    })
                  }
                </div>
                <div className="flex gap-x-6 gap-y-8 flex-wrap mb-12">
                  {
                    shuffledWords.map((phrase, i) => {
                      return <div className="phrase flex gap-2">
                        <span className='text-white text-20 font-400 border border-light-100 py-3 px-8 rounded-[20px]' onClick={() => arrangeWordsByOrder(phrase)}>{phrase}</span>
                      </div>
                    })
                  }
                </div>
              </div>
              <Button className="w-full" size="sm" onClick={() => handleWords()}>Continue</Button>
            </div> :
            <div className="py-12 sm:px-20 px-12 flex flex-col min-h-[72vh]">
              <div className="flex-grow h-full">
                {/* phrase to cpy */}
                <p className='text-gray font-400 mb-12'>write down the 12-word recover seed phrase in the correct order. it is require to restore your wallet</p>
                <div className="flex sm:gap-x-16 gap-x-6 gap-y-8 flex-wrap">
                  {
                    phrases.map((phrase, i) => {
                      return <div className="phrase flex gap-2">
                        <span className='w-[28px] h-[28px] bg-white rounded-full flex justify-center items-center text-14 font-500'>{i + 1}</span>
                        <span className='text-white text-20 font-400'>{phrase}</span>
                      </div>
                    })
                  }
                </div>
                <Button size="sm" className="w-[50%] mx-auto block mt-12" onClick={handleCopy} >Copy</Button>
              </div>
              <ul style={{ listStyle: "initial" }} className="p-8 ps-16 bg-[#000] rounded-3xl mb-12 mt-12">
                <li className='text-white text-12 mb-8'>Do Not share your phrase to anyone as this gives full access to your wallet!</li>
                <li className='text-white text-12'>Wallet support will NEVER reach out to ask for it</li>
              </ul>
              <Button className="w-full" size="sm" onClick={() => setSelectPhrase(true)}>Continue</Button>
            </div>
        }
      </div>
    </div>
  )
}

export default SelectSecretPhrase