import React from "react";
import { Button } from "../../../components/ui/Button";
import { useNavigate } from "react-router-dom";

const BackupCloud = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-card min-h-full">
      <div className="py-12 px-8 flex flex-col min-h-[100vh]">
        <div className="flex-grow h-full">
          <p className="text-gray font-400 mb-8">Your Recovery Phrase</p>
          <div className="bg-dark h-[200px] rounded-[25px] flex justify-center items-center">
            <img
              src={require("../../../assets/images/Hidden.svg").default}
              alt=""
            />
          </div>
          <Button size="sm" className="w-[50%] mx-auto block my-12 py-[9px]">
            Copy
          </Button>
          <p className="text-gray font-400">
            These 12 words give you access to your Oppi Crypto. Please back them
            up to the cloud or write them down manually.
          </p>
        </div>
        <div className="flex gap-8">
          <Button
            className="w-full bg-transparent border border-white !text-white text-14"
            size="sm"
            onClick={() => navigate("/dashboard/setting/backup-manually")}
          >
            Back Up Manually
          </Button>
          <Button className="w-full text-14 font-600" size="sm">
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BackupCloud;
