import React, { forwardRef } from 'react'
import { cn } from '../../lib/utils'
import IconEye from '../../assets/svg/IconEye';
import IconEyeClose from '../../assets/svg/IconEyeClose';
import { Colors } from 'chart.js';

const AuthInput = forwardRef(({ className, lableClass, errorClass, lable, icon, errorMsg, placeholder, prefix, type, ...props }, ref) => {
  return (
    <div className="form-group flex flex-col">
      {lable && <label className={cn('text-black text-16 font-500 mb-4 ', lableClass)} style={{ textAlign: 'left' }}>{lable}</label>}
      <div className="relative">
        <input ref={ref} type={type} className={cn(`w-full text-light-600 text-14 font-400 bg-light-300 rounded-full border border-light-400 ${prefix ? "!ps-[48px] pe-[14px]" : "ps-[28px] pe-[14px]"} py-[14px] outline-0`, className)} placeholder={placeholder} {...props} />
        {prefix && <span className='absolute left-[18px] top-[14px]'>{prefix}</span>}
      </div>
      {errorMsg && <p className={cn('text-12 text-red-500 font-normal', errorClass)} style={{ color:"red" , fontSize : '15px', margin:'8px' }}>{errorMsg}</p>}
    </div>
  )
})

export default AuthInput