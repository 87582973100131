import React from 'react'

const IconShield = (props) => {
  return (
    <svg {...props} viewBox="0 0 21 21" fill="none">
      <g clipPath="url(#clip0_209_9323)">
        <path d="M19.0174 5.78636C19.0071 5.24639 18.9973 4.73629 18.9973 4.2428C18.9973 4.05713 18.9236 3.87907 18.7923 3.74779C18.661 3.6165 18.483 3.54275 18.2973 3.54275C15.3034 3.54275 13.0239 2.68234 11.1239 0.834948C10.9933 0.707838 10.8182 0.636719 10.6359 0.636719C10.4536 0.636719 10.2785 0.707838 10.1478 0.834948C8.248 2.68234 5.96892 3.54275 2.97522 3.54275C2.78955 3.54275 2.61149 3.6165 2.48021 3.74779C2.34892 3.87907 2.27517 4.05713 2.27517 4.2428C2.27517 4.73638 2.26546 5.24667 2.2551 5.78674C2.15914 10.8118 2.02772 17.6939 10.4066 20.5981C10.5551 20.6496 10.7166 20.6496 10.8651 20.5981C19.2446 17.6938 19.1134 10.8115 19.0174 5.78636ZM10.6359 19.1938C3.44995 16.5834 3.55859 10.8649 3.65501 5.81343C3.6608 5.51026 3.6664 5.21643 3.67032 4.92838C6.47426 4.81003 8.71648 3.96035 10.6359 2.28825C12.5556 3.96035 14.7981 4.81012 17.6023 4.92838C17.6062 5.21634 17.6118 5.50998 17.6176 5.81297C17.7139 10.8646 17.8225 16.5833 10.6359 19.1938Z" fill="currentcolor" />
        <path d="M13.0466 8.20497L9.66777 11.5836L8.22594 10.1418C8.09391 10.0141 7.91701 9.94349 7.73338 9.94505C7.54975 9.94661 7.37408 10.0203 7.24423 10.1501C7.11438 10.28 7.04075 10.4557 7.03921 10.6393C7.03767 10.8229 7.10833 10.9998 7.23598 11.1318L9.17279 13.0686C9.23772 13.1337 9.31488 13.1853 9.39983 13.2205C9.48477 13.2557 9.57583 13.2737 9.66777 13.2736C9.75971 13.2737 9.85077 13.2557 9.93571 13.2205C10.0207 13.1853 10.0978 13.1337 10.1628 13.0686L14.0365 9.19503C14.1015 9.13004 14.1531 9.05288 14.1883 8.96795C14.2235 8.88302 14.2416 8.79199 14.2416 8.70006C14.2416 8.60813 14.2235 8.5171 14.1883 8.43216C14.1531 8.34723 14.1016 8.27006 14.0366 8.20506C13.9053 8.07378 13.7272 8.00002 13.5416 8C13.3559 7.99998 13.1779 8.07371 13.0466 8.20497Z" fill="currentcolor" />
      </g>
      <defs>
        <clipPath id="clip0_209_9323">
          <rect width="20" height="20" fill="currentcolor" transform="translate(0.636353 0.636719)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconShield