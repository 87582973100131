import React from 'react'
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer'
import {DateFomat, toFixedWithoutZeros} from "../../Helper";

// Create styles
const styles = StyleSheet.create({
    container: {
        padding: 26,
        backgroundColor: '#FFFFFF',
        maxWidth: 600,
        margin: 'auto',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        marginTop: 20,
    },
    logoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20
    },
    logo: {
        width: 40,
        height: 40,
    },
    text: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#000000',
    },
    subText: {
        fontSize: 8,
        color: '#b3b3b3',
        marginBottom: 2
    },
    borderTop: {
        borderTopWidth: 2,
        borderTopColor: '#CCCCCC',
        paddingTop: 6,
        paddingBottom: 8,
    },
    breakAll: {
        fontSize: 12,
        wordBreak: 'break-all',
    },
    themeText: {
        color: '#0000FF', // Example color, replace with your theme color
    },
});

const TransactionView = ({ transactionData,plan }) => {
    return (
        <Document>
            <Page size="A4" style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.logoWrapper}>
                        <Image src={require("../../assets/images/logo.png")} style={styles.logo} />
                        <View>
                            <Text style={styles.text}>{transactionData?.code ?? transactionData?.assetId}</Text>
                            <Text style={{ color: "#000", fontSize: 12 }}>Receive</Text>
                        </View>
                    </View>
                    <Text style={{ color: 'green', fontSize: 14, fontWeight: 'bold' }}>{toFixedWithoutZeros(transactionData?.amount,8)}</Text>
                </View>
                <View style={[styles.borderTop, { paddingTop: 10 }]}>
                    <Text style={styles.subText}>Transaction Hash</Text>
                    <Text
                        style={[styles.breakAll]}>{transactionData?.txHash}</Text>
                </View>
                <View style={[styles.borderTop, { paddingTop: 10 }]}>
                    <Text style={styles.subText}>To</Text>
                    <Text
                        style={[styles.breakAll]}>{transactionData?.destinationAddress}</Text>
                </View>
                <View style={[styles.borderTop, { paddingTop: 12 }]}>
                    <Text style={styles.subText}>From</Text>
                    <Text
                        style={[styles.breakAll]}>{transactionData?.sourceAddress}</Text>
                </View>
                <View style={[styles.borderTop, { paddingTop: 12 }]}>
                    <Text style={styles.subText}>Fee</Text>
                    <Text style={[styles.breakAll]}>{parseFloat(transactionData?.fee).toFixed(8)}</Text>
                     {plan === 'nft' &&  <Text style={[styles.breakAll]}>{transactionData?.assetId} </Text>}
                </View>
                <View style={[styles.borderTop, { paddingTop: 12 }]}>
                    <Text style={styles.subText}>Date & Time</Text>
                    <Text style={[styles.breakAll]}>{DateFomat(transactionData?.createdAt || 'N/A')}</Text>
                </View>
                <View style={[styles.borderTop, { paddingTop: 12 }]}>
                    <Text style={styles.subText}>Status</Text>
                    <Text style={{ color: 'green', fontSize: 14, fontWeight: 'bold' }}>{transactionData?.status}</Text>
                </View>
            </Page>
        </Document>
    )
}

export default TransactionView