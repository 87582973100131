import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs) => {
  return twMerge(clsx(inputs));
};

export const createRipple = (event) => {
  const ripple = document.createElement("span");
  const rippleSize = Math.max(
    event.target.clientWidth,
    event.target.clientHeight
    
  );
  const rect = event.target.getBoundingClientRect();
  const x = event.clientX - rect.left - rippleSize / 2;
  const y = event.clientY - rect.top - rippleSize / 2;

  ripple.style.width = ripple.style.height = `${rippleSize}px`;
  ripple.style.left = `${x}px`;
  ripple.style.top = `${y}px`;

  ripple.classList.add("ripple");

  event.target.appendChild(ripple);

  ripple.addEventListener("animationend", () => {
    ripple.remove();
  });
};
