

import { create } from 'zustand';
import createContext from 'zustand/context'
import { devtools, persist } from "zustand/middleware";
import { USER_API_URL } from '../api/api';
import axiosInstance from '../api/useAxiosPrivate';  // Import axiosInstance



const { Provider, useStore: UseStore } = createContext()


const middleware_ = (f) => create(devtools(persist(f, { name: "auth-storage" })));

const authStore = middleware_((set, get) => ({
    isAuthenticated: false,
    isInitialized: true,
    user: null,
    accessToken: "",
    refreshToken: "",
    method: "jwt",
    role: "",
    isRefreshing: false,
    hasEncyptionWordKey: 0,
    language: "en",
    canBackUp: 0,
    isBlock: 0,
    isVerified2FA: 0,
    userVaultAccount: {},
    encKey: "",
    walletName: "",
    tokenListLocal: [],
    set: set,
    isSendPayment: false,
    isCardRecharge: false,
    isSwapPayment: false,
    isNftSend: false,
    userTotalBalance : '0.00',
    userCurrencyRate : {},
    userWalletAddress:[],
    userWalletAmount:'0.00',
    userCurrencyBalance:[],
   
    setAccessToken: (value) => set({ accessToken: value }),
    setRefreshToken: (value) => set({ refreshToken: value }),
    setHasEncyptionWordKey: (value) => set({ hasEncyptionWordKey: value }),
    setUser: (value) => set({ user: value }),
    setIsAuthenticated: (value) => set({ isAuthenticated: value }),
    setUserWalletAddress: (value) => set({ userWalletAddress: value }),
    setUserWalletAmount: (value) => set({ userWalletAmount: value }),
    setUserCurrencyBalance: (value) => set({ userCurrencyBalance: value }),
    setCanBackUp: (value) => set({ canBackUp: value }),
    setIsVerified2FA: (value) => set({ isVerified2FA: value }),

    setLoginResponse: async (loginResponse) => {
        try {
            if (loginResponse?.status === 200) {
                set({
                    accessToken: loginResponse?.data?.accessToken,
                    refreshToken: loginResponse?.data?.refreshToken,
                    // hasEncyptionWordKey: loginResponse?.data?.hasEncyptionWordKey,
                    is2faEnable: loginResponse?.data?.is2faEnable,
                    user: loginResponse?.data?.user,
                    isAuthenticated: true,
                    walletName: loginResponse?.data?.walletName
                })
            }
        } catch (err) {
            console.log(err, 'err');
        }
    },

    setLogoutResponse: async (logoutResponse) => {
        try {
            if (logoutResponse?.status === 204) {
                set({
                    accessToken: "",
                    hasEncyptionWordKey: 0,
                    user: null,
                    isAuthenticated: false,
                    language: "English",
                    userCurrencyBalance:[],
                    userWalletAddress:[],
                    userWalletAmount:'0.00',
                    canBackUp: 0,
                    userTotalBalance : '0.00',
                    userCurrencyRate : {},
                    isVerified2FA: 0,
                    encKey: "",
                    // isBlock: 0,
                    walletName: "",
                    tokenListLocal: [],
                    userVaultAccount: {}

                })
            }
        } catch (error) {
            console.log(error);
        }
    },

    setLanguage: async (language) => {
        set({ language: language })
    }
}));

const AuthProvider = ({ children }) => {
    return <Provider createStore={() => authStore}>{children}</Provider>;
};

const refreshStore = create((set, get) => ({
    isRefreshing: false,
}))

export { AuthProvider, authStore, refreshStore };
export default UseStore;
