import React from 'react'

const IconAddressGlobal = (props) => {
  return (
    <svg {...props} width="24" height="24" fill="none">
      <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="currentcolor" />
      <path d="M8.99999 21.75H7.99999C7.58999 21.75 7.24999 21.41 7.24999 21C7.24999 20.59 7.56999 20.26 7.97999 20.25C6.40999 14.89 6.40999 9.11 7.97999 3.75C7.56999 3.74 7.24999 3.41 7.24999 3C7.24999 2.59 7.58999 2.25 7.99999 2.25H8.99999C9.23999 2.25 9.46999 2.37 9.60999 2.56C9.74999 2.76 9.78999 3.01 9.70999 3.24C7.82999 8.89 7.82999 15.11 9.70999 20.77C9.78999 21 9.74999 21.25 9.60999 21.45C9.46999 21.63 9.23999 21.75 8.99999 21.75Z" fill="currentcolor" />
      <path d="M14.9999 21.7499C14.9199 21.7499 14.8399 21.7399 14.7599 21.7099C14.3699 21.5799 14.1499 21.1499 14.2899 20.7599C16.1699 15.1099 16.1699 8.88994 14.2899 3.22994C14.1599 2.83994 14.3699 2.40994 14.7599 2.27994C15.1599 2.14994 15.5799 2.35994 15.7099 2.74994C17.6999 8.70994 17.6999 15.2699 15.7099 21.2199C15.6099 21.5499 15.3099 21.7499 14.9999 21.7499Z" fill="currentcolor" />
      <path d="M12 17.2C9.21 17.2 6.43 16.81 3.75 16.02C3.74 16.42 3.41 16.75 3 16.75C2.59 16.75 2.25 16.41 2.25 16V15C2.25 14.76 2.37 14.53 2.56 14.39C2.76 14.25 3.01 14.21 3.24 14.29C8.89 16.17 15.12 16.17 20.77 14.29C21 14.21 21.25 14.25 21.45 14.39C21.65 14.53 21.76 14.76 21.76 15V16C21.76 16.41 21.42 16.75 21.01 16.75C20.6 16.75 20.27 16.43 20.26 16.02C17.57 16.81 14.79 17.2 12 17.2Z" fill="currentcolor" />
      <path d="M21 9.75011C20.92 9.75011 20.84 9.74011 20.76 9.71011C15.11 7.83011 8.88003 7.83011 3.23003 9.71011C2.83003 9.84011 2.41003 9.63011 2.28003 9.24011C2.16003 8.84011 2.37003 8.42011 2.76003 8.29011C8.72003 6.30011 15.28 6.30011 21.23 8.29011C21.62 8.42011 21.84 8.85011 21.7 9.24011C21.61 9.55011 21.31 9.75011 21 9.75011Z" fill="currentcolor" />
    </svg>
  )
}

export default IconAddressGlobal


