import React from 'react'

const IconAdd = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" >
      <path d="M12 20.6241C11.3527 20.6241 10.8281 20.0995 10.8281 19.4522V4.54782C10.8281 3.90048 11.3527 3.37595 12 3.37595C12.6473 3.37595 13.1719 3.90048 13.1719 4.54782V19.4522C13.1719 20.0995 12.6473 20.6241 12 20.6241Z" fill="currentcolor" />
      <path d="M19.4522 13.1719H4.54785C3.90051 13.1719 3.37598 12.6473 3.37598 12C3.37598 11.3527 3.90051 10.8281 4.54785 10.8281H19.4522C20.0996 10.8281 20.6241 11.3527 20.6241 12C20.6241 12.6473 20.0996 13.1719 19.4522 13.1719Z" fill="currentcolor" />
    </svg>
  )
}

export default IconAdd


