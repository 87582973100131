import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import IconClose from '../../assets/svg/IconClose';
import NftQrCodeIcon from '../../assets/images/NftQrCodeIcon.png';
import BinanceIcon from '../../assets/images/BinanceIcon.png'
import IconCopy from "../../assets/svg/IconCopy";
import { Button } from '../ui/Button';
import QRCode from 'react-qr-code';
import { add, debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import Loader from '../ui/Loader';
import { useSnackbar } from 'notistack';
import { files } from '../../Helper';
import IcnExclamation from '../../assets/svg/IcnExclamation';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { RECEIVE_API_URL } from '../../api/api';
import { useQuery } from 'react-query';
import { authStore } from '../../context/AuthProvider';

const ReceiveModal = ({ open, handleClose, assetAddress, setAssetAddress, token, loading, decimalId }) => {
  
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const axiosInstance = useAxiosPrivate()
  const [address, setAddress] = useState();
  const { userWalletAddress } = authStore();
  const [isLoadning, setIsLoading] = useState(false)
  const [test, setTest] = useState();

  useEffect(() => {
    if (token) {
      const matchingAddress = userWalletAddress.find(item => {
        // First, check if token.id matches chainId
        if (item.chainId === token.id) {
          return true;
        }
        // If tokenId is not null, check if it matches chainId
        if (token.tokenId && item.chainId === token.tokenId) {
          return true;
        }
        return false;
      });

      // Set the found address or handle if no match is found
      if (matchingAddress) {
        setTest(matchingAddress);
        setAddress(matchingAddress);
      } else {
        console.log('No matching address found');
      }

    }
  }, [token]);



  useEffect(() => {
    if (open == false) {
      setAddress("")
    }
  }, [open, setAssetAddress])

  const handleCopy = (address) => {
    navigator?.clipboard?.writeText(address)
      .then(() => {
        enqueueSnackbar(t('Address_copied'), {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        console.error('Unable to copy text to clipboard', error);
        // Handle the error or provide user feedback
      });
  }

  
  const handleCloseModal = () => {
    handleClose();
    setAddress("")
  };


  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => { return }} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >

          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>{t('Receive')}</p>
                  <IconClose onClick={handleCloseModal} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <div className="token-detail flex gap-4 justify-center items-center mt-12">
                  <div className=" rounded-full bg-light-200 w-[44px] h-[44px]">
                    {token?.icon ?
                      <img className='rounded-full h-full w-full object-cover' src={files(token?.icon, 'image')} alt="" />
                      : <div style={{ width: '44px', height: '44px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500, borderRadius: '50%', fontSize: '24px', }} className='bg-linear-horizontal'>{token?.name?.slice(0, 1).toUpperCase()}</div>
                    }
                  </div>
                  <div className="text">
                    <h6 className='text-16 font-500 text-white mb-2'>{token?.name}</h6>
                    <div className="flex item-center gap-[8px]">
                      <p className='text-14 font-400 text-gray'>{token?.code ? token?.code : token?.symbol || "-"}</p>
                      {(token?.tokenId !== null) && <p className='text-14 font-400 text-gray'>({(token?.tokenName)})</p>}
                    </div>
                  </div>
                </div>

                <div className="p-[20px]">
                  <div className='max-w-[280px] aspect-square flex justify-center items-center bg-card rounded-3xl mx-auto border border-light-100'>

                    {
                      !test ? <IcnExclamation className='h-[80px] w-[80px] text-theme' /> : (
                        <QRCode
                          size={220}
                          style={{ height: "auto" }}
                          value={test?.address}
                          viewBox={`0 0 256 256`}
                        />
                      )
                    }
                  </div>
                  <div className="flex justify-between items-center  bg-card p-2 rounded-full mt-10 border border-light-100">
                    <p className='text-white text-14 ps-3 break-all pe-[4px] mx-auto text-center'>{!test ? "Address Not Found" : test?.address}</p>
                    <div className="p-4 bg-linear-horizontal rounded-full cursor-pointer">
                      <IconCopy className="w-[20px] h-[20px] text-white" onClick={() => handleCopy(test?.address)} />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>

            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ReceiveModal
