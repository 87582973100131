import React from 'react'

const IcnWifi = (props) => {
    return (
        <svg {...props} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6623 6.06104C17.2969 10.7686 17.2969 16.8883 13.6623 21.5958" stroke="currentColor" stroke-width="1.64216" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.4721 2.87549C22.5956 9.5098 22.5956 18.1366 17.4721 24.7709" stroke="currentColor" stroke-width="1.64216" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.66625 8.11963C12.3375 11.5682 12.3375 16.0677 9.66625 19.5162" stroke="currentColor" stroke-width="1.64216" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.65929 10.9766C6.99492 12.7063 6.99492 14.9506 5.65929 16.6803" stroke="currentColor" stroke-width="1.64216" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default IcnWifi