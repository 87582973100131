import React, { useContext, useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { _randomString } from '../../Helper';
import Loader from '../../components/ui/Loader';
import { AuthSocketContext } from '../../context/AuthSocketProvider';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const WebLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [key, setKey] = useState();
    const { setAuthKey, setIsLogin } = useContext(AuthSocketContext)
    const {t} = useTranslation();

    useEffect(() => {
        const updateKeys = () => {
            const newKey = _randomString(16);
            setKey(newKey);
            setAuthKey(newKey);
        };
        updateKeys();
        setIsLoading(false);
        setIsLogin(1);
        // chnage key in every 30 seconds
        const timeoutId = setTimeout(() => {
            updateKeys();
            setInterval(updateKeys, 30000);
        }, 30000);

        return () => clearInterval(timeoutId);
    }, []);



    return (
        <>
            {
                isLoading ? <Loader className="bg-white" /> : <>
                    <section className='login-screen bg-light-200 sm:p-20 p-10 rounded-[30px] max-w-[600px] mx-auto'>
                        <Helmet prioritizeSeoTags>
                            <title>Sign in | Oppi Wallet
                            </title>
                            <meta name="description" content="Sign in with your correct login credential to access the Dasborad of Oppi Wallet.." />
                            <link rel="canonical" href="https://oppiwallet.com/login" />

                            <meta property="og:title" content="Sign in | Oppi Wallet" />
                            <meta property="og:description" content="Sign in with your correct login credential to access the Dasborad of Oppi Wallet.." />
                            <meta property="og:url" content="https://oppiwallet.com/login" />
                        </Helmet>

                        <h4 className='sm:mb-12 mb-10 font-500 text-dark text-center'>{t('Use_Oppi_wallet_on_your_computer')}</h4>
                        <div className='max-w-[200px] aspect-square flex justify-center items-center bg-card mx-auto mb-10'>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={key || ""}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <h6 className='text-dark-gray mb-2 text-16'>1.{t('Open_Oppi_wallet_on_your_phone')}</h6>
                        <h6 className='text-dark-gray mb-2 text-16 flex items-center gap-2'>2.{t('Go_to_home_page_click_on')} <img src={require("../../assets/images/scan.png")} className='w-12 h-12' alt="" /> {t('icon_to_scan_the_code')}</h6>
                    </section>
                </>
            }
        </>

    )
}

export default WebLogin