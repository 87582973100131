import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../api/useAxiosPrivate";
import { WALLET_API_URL } from "../api/api";
import { useQuery } from "react-query";
import { AuthSocketContext } from "./AuthSocketProvider";
import { authStore } from "./AuthProvider";
import Loader from "../components/ui/Loader";

export const HelperContext = createContext();

const HelperContextProvider = ({ children }) => {
    const [tokenList, setTokenList] = useState();
    // const 
    const axiosInstance = useAxiosPrivate();
    const [userVaultAccount, setUserVaultAccount] = useState()
    const [matchedToken, setMatchedToken] = useState([]);
    const [updatedTokens, setUpdatedTokens] = useState();
    const { tokenListLocal, isAuthenticated, userTotalBalance, set, userCurrencyRate } = authStore();
    const [userBalance, setUserBalance] = useState();


    // async function getVaultAccount() {
    //     if (isAuthenticated) {
    //         const response = await axiosInstance.get(WALLET_API_URL.getVaultAccount);
    //         return response.data;
    //     }
    // }

    // const { data: vaultAccountList } = useQuery(['vaultAccount'], () => getVaultAccount(), { keepPreviousData: true })

    // useEffect(() => {
    //     if (vaultAccountList && tokenListLocal) {
    //         const updatedTokens = tokenListLocal.map(item1 => {
    //             const matchingItem = vaultAccountList?.assets?.find(item2 => item2?.id === item1?.symbol);
    //             if (matchingItem) {
    //                 return { ...item1, ...matchingItem };
    //             }
    //             return item1;
    //         });
    //         setMatchedToken(updatedTokens);
    //     }

    // }, [tokenListLocal, vaultAccountList]);


    useEffect(() => {
        if (matchedToken && Object.keys(userCurrencyRate).length > 0) {
            matchedToken?.map((token) => {
                const tokenName = token?.code;
                const addUSDT = tokenName + "USDT";
                const comapreWithCurrencyRate = userCurrencyRate?.find((rate) => rate?.symbol == addUSDT)
                if (comapreWithCurrencyRate) {
                    token.ratePrice = comapreWithCurrencyRate.price;
                    token.userUsdBalance = token?.balance * comapreWithCurrencyRate?.price
                    return token;
                }
            })
        }

    }, [matchedToken])



    useEffect(() => {
        let userBalance = 0;
        if (matchedToken && Object.keys(userCurrencyRate).length > 0) {
            matchedToken?.forEach((token) => {
                if (token?.userUsdBalance) {
                    userBalance += token?.userUsdBalance;
                }
            })
        }
        setUserBalance(userBalance);
    }, [matchedToken])

    return (
        <HelperContext.Provider value={{ tokenList, setTokenList, userBalance }}>
            {!userCurrencyRate ? <Loader /> : children}
        </HelperContext.Provider>
    );
};

export default HelperContextProvider;
