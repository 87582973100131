import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import * as Yup from "yup";
import { useFormik, FormikProvider, ErrorMessage, Form } from 'formik';
import { cn } from '../../lib/utils'
import { QueryClient } from 'react-query'
import { axiosInstance } from '../../api/axiosInstance'
import { USER_API_URL } from '../../api/api'
import { enqueueSnackbar } from 'notistack'
import { authStore } from '../../context/AuthProvider'

const ApplyVirtualCardModal = ({ open, handleClose, setStep, userCardListApi }) => {
  const [countryCode, setCountryCode] = useState("90");
  const { user } = authStore();
  const [userMail, setUserMail] = useState();
  const [phone, setPhone] = useState(user?.mobile);

  const handleClick = () => {
    setStep("2");
    handleClose();
  }

  useEffect(() => {
    if (user?.countryCode !== null && user?.mobile !== null) {
      setPhone(user?.countryCode + user?.mobile);
    }
  }, [user])


  const handlePhoneChange = (value, data, event, formattedValue) => {
    const countryCode = data?.dialCode;
    const phoneNumber = value?.replace(`+${countryCode}`, '');
    setCountryCode(countryCode);
    setPhone(phoneNumber);
    setValues({
      ...formik.values,
      mobile_code: `+${countryCode}`,
      mobile: phoneNumber,
    });
  };

  const addCardValidation = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Invalid email format"), // Validates email format

    first_name: Yup.string()
      .trim()
      .required("First name is required")
      .matches(/^[a-zA-Z\s]+$/, "First name must contain only letters"), // Ensures only letters

    last_name: Yup.string()
      .trim()
      .required("Last name is required")
      .matches(/^[a-zA-Z\s]+$/, "Last name must contain only letters"), // Ensures only letters

    mobile_code: Yup.string()
      .trim()
      .required("Mobile code is required")
      .matches(/^\+\d{1,3}$/, "Mobile code must be in the format +123"),

    mobile: Yup.string()
      .trim()
      .required("Mobile number is required")
      .matches(/^\d+$/, "Mobile number must contain only digits")
      .test('valid-mobile', 'Invalid mobile number', function (value) {
        const { mobile_code } = this.parent;
        const numberLength = value ? value.length : 0;
        // You can add different validations based on country code here
        return mobile_code === "+90" ? numberLength === 10 : numberLength >= 8 && numberLength <= 15; // Example for Turkey (+90) and general case
      }) // Validates country code format like +1, +91, etc.
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email ?? '',
      first_name: "",
      last_name: "",
      mobile: user?.mobile ?? "",
      mobile_code: user?.countryCode ?? ""
    },
    validationSchema: addCardValidation,
    onSubmit: async (values, resetForm) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.applyForVirtualCard, { base_info: values });
        if (response) {
          enqueueSnackbar('Your virtual card is created successfully ', {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 1000,
          });
          handleClose();
          userCardListApi();
        }
      } catch (error) {
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    },
  });


  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    setValues,
  } = formik;

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all"> */}
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b rounded-t-[30px]">
                  <p className='text-white ms-auto'>Apply Virtual Card</p>
                  <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <div className="p-12">
                  <FormikProvider value={formik}>
                    <Form onSubmit={handleSubmit} className="theme-form" autoComplete="off">

                      <Input type="text" lable="First Name" {...getFieldProps('first_name')} className="mb-8" placeholder="Enter first name" autoComplete="off" errorMsg={errors.first_name && touched.first_name ? errors.first_name : null} />

                      <Input type="text" lable="Last Name" {...getFieldProps('last_name')} className="mb-8" placeholder="Enter first name" autoComplete="off" errorMsg={errors.last_name && touched.last_name ? errors.last_name : null} />

                      <Input type="email" disabled={true} {...getFieldProps('email')} className="mb-8" lable="Email Address" placeholder="Enter New Email Address" suffix="Verified" errorMsg={errors.email && touched.email ? errors.email : null} />

                      <label htmlFor="" className='!text-white text-16 font-500 mb-4 block'>
                        Phone Number
                      </label>
                      <div className="bg-card rounded-full p-3 flex items-center w-full relative border border-light-100">
                        <PhoneInput
                          country={'us'}
                          className="w-full"
                          value={phone}
                          onChange={handlePhoneChange}
                          inputClass='!bg-transparent hover:bg-transparent !border-0 text-white w-full p-[14px] rounded-full'
                          buttonClass='!bg-transparent !border-0 hover:!bg-transparent'
                        />

                        <span className='absolute bg-linear-horizontal text-white text-12 p-1 px-2 rounded-full right-[18px] top-[13px]'>Verified</span>


                      </div>
                      {errors?.mobile && <p className={cn('text-12 text-red-500 font-normal')} style={{ color: "red", fontSize: '13px', margin: '8px' }}>{errors?.mobile}</p>}
                      <Button type='submit' className="w-full text-[14px] mt-12 text-white" >Submit</Button>
                    </Form>
                  </FormikProvider>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ApplyVirtualCardModal
