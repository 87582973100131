import React from "react";
import { Button } from "../../ui/Button";
import IconArrowRight from "../../../assets/svg/IconArrowRight";

const WalletLimit = () => {
  return (
    <section className="lg:pt-[100px] pt-[40px]">
      <div className="container">
        <div className="grid md:grid-cols-2 items-center md:gap-0 gap-8">
          <div className="p-16">
            <h5 className="font-600 mb-8" data-aos="fade-down" data-aos-duration="1500">Virtual Card Limit</h5>
            <img
              src={require("../../../assets/images/VirtualCardLimitChart.webp")}
              className=""
              alt=""
              data-aos="fade-right" data-aos-duration="1500"
            />
          </div>
          <div className="sm:max-w-[425px] mx-auto" data-aos="fade-left" data-aos-duration="1500">
            <h5 className="sm:text-32 md:text-left text-center capitalize font-700 mb-6">
              Set Daily, Monthly Limits to your Virtual Card
            </h5>
            <p className="text-16 font-500 md:text-left text-center capitalize md:mb-20 mb-12">
              You can Now Set spending limits on your card to control how much
              money leaves your account daily and monthly
            </p>
            <Button className="flex gap-4 text-14 md:mx-0 mx-auto font-700 items-center w-max">
              Explore Oppi Wallet
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WalletLimit;
