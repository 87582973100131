import React, {forwardRef} from 'react'
import {cn} from '../../lib/utils'
import {checkFileSize, checkFileType, fileSizeExceedMsg, fileTypeMismatchMsg} from "../../Helper";
import {useSnackbar} from "notistack";

const FileUploadDragAndDrop = forwardRef(({
                                              lableClass,
                                              errorClass,
                                              lable,
                                              icon,
                                              errorMsg,
                                              placeholder,
                                              prefix,
                                              type,
                                              setIsFilePreview,
                                              fileInputRef,
                                              accept = "image/*",
                                              setUploadFile,
                                              ...props
                                          }, ref) => {


    const {enqueueSnackbar} = useSnackbar();

    const Imageupload = async (event) => {
        const file = event.target.files?.[0];

        if (checkFileType(['image/jpg', 'image/jpeg', 'image/png'], file?.type)) {
        } else {
            enqueueSnackbar(fileTypeMismatchMsg, {
                variant: "error",
                anchorOrigin: {vertical: "top", horizontal: "right"},
                autoHideDuration: 2000,
            });
            return false;
        }

        if (!checkFileSize(file?.size)) {
            enqueueSnackbar(fileSizeExceedMsg, {
                variant: "error",
                anchorOrigin: {vertical: "top", horizontal: "right"},
                autoHideDuration: 2000,
            });
            return false;
        }

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setIsFilePreview(imageUrl);
            setUploadFile(file)
        }
    }

    const isInvalidFileType = (file) => {
        return !file.type.match(/^image\/(jpeg|png|webp)$/);
    };

    // const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ onDrop })

    return (
        <div className="form-group flex flex-col">
            {lable && <label className={cn('text-black text-16 font-500 mb-4 ', lableClass)}
                             style={{textAlign: 'left'}}>{lable}</label>}
            <div className="relative">
                <div
                    className='w-full rounded-[10px] border bg-gray-light text-14 font-400 outline-0 border-light-gray bg-white '>
                    <label htmlFor="dropzone-file"
                           className='flex items-center justify-center flex-col gap-sm h-full cursor-pointer p-5'>
                        <h4 className='text-14 text-gray'><span className='text-black underline mr-2'>Add file</span>or
                            drop files here</h4>
                        {/* {acceptedFiles && acceptedFiles[0] ? (
              <div className='bg-transparent mt-4 underline'>
                <div className='h-full text-lg truncate'>
                  {acceptedFiles[0].name}
                </div>
              </div>
            ) : null} */}
                        <input
                            ref={fileInputRef}
                            type='file'
                            accept={accept}
                            id='dropzone-file'
                            className='hidden'
                            onChange={(ev) => {
                                    Imageupload(ev)
                            }}
                            // onClick={(event) => event.preventDefault()}
                        />
                    </label>
                </div>
            </div>
        </div>
    )
})

export default FileUploadDragAndDrop