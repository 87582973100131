import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import IconClose from "../../assets/svg/IconClose";
import { Button } from "../ui/Button";
import Input from "../ui/Input";
import FileUpload from "../ui/FileUpload";
import { Form, FormikProvider, useFormik } from "formik";
import { supportTicketSchema } from "../../validation/CommonValidation";
import { SUPPORT_TICKET_API_URL, USER_API_URL } from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { checkFileSize, checkFileType, fileSizeExceedMsg, fileTypeMismatchMsg } from "../../Helper";
import { useTranslation } from "react-i18next";


const CreateNewTicketModal = ({ open, handleClose }) => {
    const fileInputRef = useRef();
    const [previewFile, setPreviewFile] = useState();
    const [uploadFile, setUploadFile] = useState();
    const [isFilePreview, setIsFilePreview] = useState();
    const axiosInstance = useAxiosPrivate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const [selectedFileIsImage, setSelectedFileIsImage] = useState(false);
    const { t } = useTranslation();

    // File upload
    const handleFileUpload = (e) => {

        setSelectedFileIsImage(false);
        const selectedFile = fileInputRef.current.files[0];

        if (checkFileType(['image/jpg', 'image/jpeg', 'image/png'], selectedFile?.type)) {
            setSelectedFileIsImage(true);
        } else {
            enqueueSnackbar(fileTypeMismatchMsg, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            return false;
        }

        if (!checkFileSize(selectedFile?.size)) {
            enqueueSnackbar(fileSizeExceedMsg, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            return false;
        }

        setUploadFile(selectedFile)
        let uploadImagePreview = URL.createObjectURL(selectedFile);
        setPreviewFile(uploadImagePreview);
        setIsFilePreview(true);
        setValues({ ...formik.values, file: selectedFile });

        e.target.value = null;

    }

    const { mutateAsync: uploadImage } = useMutation(
        async (data) => {
            try {
                const response = await axiosInstance.post(USER_API_URL.uploadUserImage, data, {
                    headers: {
                        'Content-type': 'multipart/form-data',
                    },
                });
                return response;
            } catch (error) {
                if (error?.response?.data.errors) {
                    const errors = error?.response?.data.errors;
                    Object.keys(errors).map(function (key) {
                        enqueueSnackbar(errors[key], {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
            }
        }
    );
    const { mutateAsync: createSupportTicket } = useMutation(
        async (data) => {
            try {
                const response = await axiosInstance.post(SUPPORT_TICKET_API_URL.createTicket, data);
                if (response) {
                    enqueueSnackbar(t('Ticket_sended'), {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 1000,
                    });
                    queryClient.invalidateQueries('ticketList')
                    setSubmitting(false);
                    handleClose();
                    resetForm();
                    setUploadFile(null);
                    setPreviewFile(null);
                    setIsFilePreview(false);
                    setLoading(false);
                }
            } catch (error) {
                if (error?.response?.data.errors) {
                    const errors = error?.response?.data.errors;
                    Object.keys(errors).map(function (key) {
                        enqueueSnackbar(errors[key], {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
            }
        }
    );


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: "",
            message: "",
            file: null,
        },
        validationSchema: supportTicketSchema(t),
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);

            try {
                let response = '';
                if (uploadFile) {
                    const formData = new FormData();
                    formData.append("images", uploadFile)
                    response = await uploadImage(formData)
                }
                const data = {
                    subject: values?.title,
                    description: values?.message,
                    files: response.data ? response?.data : [],
                }
                await createSupportTicket(data);
                setLoading(false);
            } catch (e) {
                enqueueSnackbar("Something went wrong!", {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                setLoading(false);
            }

        }
    });
    const {
        handleSubmit,
        getFieldProps,
        errors,
        touched,
        resetForm,
        setSubmitting,
        setValues
    } = formik;
    
    const removeImage = () => {
        setIsFilePreview(false);
        setUploadFile(null);
    }


    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={() => {
                    handleClose();
                    resetForm();
                    setPreviewFile(null);
                    setUploadFile(null);
                    setIsFilePreview(false)
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                                    <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                                        <p className="text-white ms-auto">{t('Create_New_ticket')}</p>
                                        <IconClose
                                            onClick={() => {
                                                handleClose();
                                                resetForm();
                                                setPreviewFile(null);
                                                setUploadFile(null);
                                                setIsFilePreview(false)

                                            }}
                                            className="text-white w-[18px] ms-auto cursor-pointer"
                                        />
                                    </div>

                                    <FormikProvider value={formik}>
                                        <Form autoComplete="off" onSubmit={handleSubmit}>
                                            <div className="p-12">
                                                <Input
                                                    lable={t('Ticket_Title')}
                                                    lableClass="text-14 mt-8"
                                                    className="bg-card"
                                                    placeholder={t("Title")}
                                                    maxLength={100}
                                                    {...getFieldProps('title')}
                                                    errorMsg={errors.title && touched.title ? errors.title : null}
                                                />
                                                <label
                                                    htmlFor=""
                                                    className="!text-white text-14 font-500 mb-4 block mt-8"
                                                >
                                                    {t('Write_Message')}
                                                </label>
                                                <textarea
                                                    cols={3}
                                                    rows={4}
                                                    maxLength={500}
                                                    className="w-full p-2 bg-card rounded-3xl border border-light-100 text-white text-14 outline-0 font-400 resize-none theme-scrollbar mb-8" // Reduce margin from 8 to 2
                                                    placeholder={t("Enter_Your_Message")}
                                                    {...getFieldProps('message')}
                                                />
                                                {errors.message && touched.message && (
                                                    <span className="text-red-500 text-xs mt-1 mb-2" style={{
                                                        color: "red",
                                                        fontSize: '13px',
                                                        margin: '8px'
                                                    }}>{errors.message}</span>
                                                )}
                                                {
                                                    (isFilePreview && selectedFileIsImage) && (
                                                        <div item xs={12}
                                                             onClick={()=>removeImage()}
                                                            style={{
                                                                textAlign: 'left',
                                                                margin: '7px',
                                                                position: 'relative',
                                                                width: '100%',
                                                                maxWidth: "150px",
                                                            }}>
                                                            <img
                                                                src={previewFile}
                                                                alt="Selected Preview"
                                                                style={{
                                                                    borderRadius: '8px',
                                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                                                    marginLeft: '0',
                                                                }}
                                                            />
                                                            <IconClose className="w-10 h-10 border p-1 rounded-full absolute -top-4 -right-4 text-white cursor-pointer" />
                                                        </div>
                                                    )
                                                }
                                                <FileUpload
                                                    handleFileUpload={(e) => handleFileUpload(e)}
                                                    ref={fileInputRef}
                                                    accept={'.png,.jpg,.jpeg'}
                                                    errorMsg={errors.file && touched.file ? errors.file : null} />

                                                <Button size="sm"
                                                    className={`w-full text-[14px] text-white mt-12 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                    disabled={loading}>
                                                    {loading ? t("loading") : t("Confirm")}
                                                </Button>

                                            </div>
                                        </Form>
                                    </FormikProvider>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default CreateNewTicketModal;
