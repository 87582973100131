import axios from "axios";
import Security from "../security/Security";
import { axiosInstance } from "./axiosInstance";
import { useEffect } from "react";
import UseStore, { authStore, refreshStore } from "../context/AuthProvider";
import { USER_API_URL } from "./api";
import { useNavigate } from "react-router-dom";

const useAxiosPrivate = () => {
    const state = UseStore();
    const isAuthenticated = state?.isAuthenticated;
    const accessToken = state?.accessToken;
    const refreshToken = state?.refreshToken;
    const setAccessToken = state?.setAccessToken;
    const setRefreshToken = state?.setRefreshToken;
    const navigate = useNavigate();
    const { language, encKey } = authStore()

    useEffect(() => {


        const requestIntercept = axiosInstance.interceptors.request.use(
            (config) => {

                if (!config.headers['Authorization'] && isAuthenticated) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                    config.headers['encryptedkey'] = `${encKey}`;
                    config.headers['lang'] = language;
                }
                if (config.method === 'post' && !config.data?._encrypted &&
                    config.env !== "test" &&
                    !config.url.includes('env=test') &&
                    config.headers['Content-Type'] !== "multipart/form-data"
                ) {
                    config.data = new Security().encrypt(config.data);
                    config.data._encrypted = true;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = axiosInstance.interceptors.response.use(
            function (response) {
                if (response?.data?.mac !== undefined) {
                    const decryptedData = new Security().decrypt(response.data);
                    response.data = decryptedData;
                }
                return response;
            },
            async function (error) {
                if (error?.response?.status === 401) {
                    localStorage.clear();
                    window.location.href = "/"
                }
                if (error?.response?.status === 401) {
                    window.location.href = "/";
                    localStorage.clear();
                }

                /* if (error?.response?.status === 502) {
                    localStorage.clear();
                    // window.location.href = "/"
                } */
                return Promise.reject(error);
            }
        );
        return () => {
            axiosInstance.interceptors.request.eject(requestIntercept);
            axiosInstance.interceptors.response.eject(responseIntercept);
        };
    }, [isAuthenticated, accessToken, refreshToken]);

    return axiosInstance;
};

export default useAxiosPrivate;
