import { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { cn } from '../../lib/utils';
import IconRing from '../../assets/svg/IconRing';
import IconCheck from '../../assets/svg/IconCheck';
import { authStore } from '../../context/AuthProvider';
import { useTranslation } from 'react-i18next';

const BasicRadio = ({ data, lable, className, lableClass, ...props }) => {
    const [plan, setPlan] = useState();
    const { setLanguage } = authStore()
    const { i18n } = useTranslation();
    const { language } = authStore();

    useEffect(() => {
        if (language) {
            setPlan(language);
        }
    }, [language])

    useEffect(() => {
        if (plan) {
            i18n.changeLanguage(plan);
            setLanguage(plan)
        }
    }, [plan])
    return (
        <RadioGroup value={plan} onChange={setPlan} className='flex flex-col gap-5'>
            {data?.map((time, index) => (
                <RadioGroup.Option key={index} value={time?.id} className={"cursor-pointer"} >
                    {({ checked }) => (
                        <div className={cn(`flex items-center justify-between ps-[11px] pe-[20px] py-[16px] rounded-full bg-card border border-light-100`, className)}>
                            <span className='text-[14px] font-500 text-gray'>{time?.lang}</span>
                            <span>{checked ? <IconCheck className="h-[18px] w-[18px]" /> : <IconRing className="h-[18px] w-[18px]" />}</span>
                        </div>
                    )}
                </RadioGroup.Option>
            ))}
        </RadioGroup>
    )
}

export default BasicRadio