import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DownloadWebApp = () => {
  const { t } = useTranslation();
  return (
    <section className="container md:pt-10 pt-20">
      <div className="grid md:grid-cols-2 col-span-1 items-center">
        <div className="text-center">
          <div className="md:text-start text-center lg:max-w-[75%] w-full lg:mx-auto" data-aos="zoom-in-right" data-aos-duration="1500">
            <h3 className="sm:text-32 text-30 font-600 text-card">{t('Download_our_App_and__get_start')} <span className="text-greenweb">
              {t('send_recieve')}</span> {t('Crypto')}</h3>
            <p className="text-14 font-400 my-8">
              {t('Take_control_of_your_crypto_Avoid_complicated_steps_and_deposit_directly_to_your_wallet_from_exchanges_like_Binance_and_Coinbase')}
            </p>
            <div className="flex md:justify-start justify-center gap-6">
              <Link to={"https://play.google.com/store/apps/details?id=com.oppi.wallet"} target="_blank" className=" w-[45%] max-w-[180px]">
                <img
                  src={require("../../../assets/images/PlayStoreWeb.svg").default}
                  className="rounded-lg"
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration="1000"
                />
              </Link>
              <Link to={"https://apps.apple.com/app/oppi-wallet/id6502544148"} target="_blank" className="w-[45%] max-w-[180px]">
                <img
                  src={require("../../../assets/images/AppStoreWeb.svg").default}
                  className="rounded-lg"
                  alt=""
                  data-aos="fade-left"
                  data-aos-duration="1000"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="text-center">
          <img src={require("../../../assets/images/download_pic.webp")} alt="" className="" data-aos="zoom-in" data-aos-duration="1500" />
        </div>
      </div>
    </section>
  );
};

export default DownloadWebApp;
