import React from 'react'

const IconHistory = (props) => {
  return (
    <svg {...props} viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_188_6565)">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.9012 10.6117C17.007 10.0913 17.0625 9.552 17.0625 9C17.0625 4.55025 13.4497 0.9375 9 0.9375C4.55025 0.9375 0.9375 4.55025 0.9375 9C0.9375 13.4497 4.55025 17.0625 9 17.0625C9.552 17.0625 10.0913 17.007 10.6117 16.9012C10.7565 16.8702 10.8832 16.7833 10.9643 16.6594C11.0454 16.5355 11.0744 16.3846 11.045 16.2395C11.0155 16.0944 10.9301 15.9667 10.8072 15.8842C10.6842 15.8017 10.5337 15.771 10.3883 15.7987C9.93128 15.8912 9.46622 15.9376 9 15.9375C5.17125 15.9375 2.0625 12.8287 2.0625 9C2.0625 5.17125 5.17125 2.0625 9 2.0625C12.8287 2.0625 15.9375 5.17125 15.9375 9C15.9375 9.4755 15.8895 9.93975 15.7987 10.3883C15.771 10.5337 15.8017 10.6842 15.8842 10.8072C15.9667 10.9301 16.0944 11.0155 16.2395 11.045C16.3846 11.0744 16.5355 11.0454 16.6594 10.9643C16.7833 10.8832 16.8702 10.7565 16.9012 10.6117Z" fill="currentcolor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.81239 4.875V8.39175L5.97739 11.2275C5.92519 11.2797 5.88379 11.3417 5.85554 11.4099C5.82728 11.4781 5.81274 11.5512 5.81274 11.625C5.81274 11.6988 5.82728 11.7719 5.85554 11.8401C5.88379 11.9083 5.92519 11.9703 5.97739 12.0225C6.02959 12.0747 6.09157 12.1161 6.15977 12.1444C6.22797 12.1726 6.30107 12.1871 6.37489 12.1871C6.44872 12.1871 6.52182 12.1726 6.59002 12.1444C6.65822 12.1161 6.72019 12.0747 6.77239 12.0225L9.77239 9.0225C9.82474 8.97039 9.86627 8.90844 9.89459 8.84023C9.9229 8.77201 9.93745 8.69886 9.93739 8.625V4.875C9.93739 4.72582 9.87813 4.58274 9.77264 4.47725C9.66715 4.37176 9.52408 4.3125 9.37489 4.3125C9.22571 4.3125 9.08264 4.37176 8.97715 4.47725C8.87166 4.58274 8.81239 4.72582 8.81239 4.875ZM12.7499 9.9375C11.1974 9.9375 9.93739 11.1975 9.93739 12.75C9.93739 14.3025 11.1974 15.5625 12.7499 15.5625C14.3024 15.5625 15.5624 14.3025 15.5624 12.75C15.5624 11.1975 14.3024 9.9375 12.7499 9.9375ZM12.7499 11.0625C13.1976 11.0627 13.627 11.2408 13.9434 11.5575C14.2599 11.8743 14.4376 12.3037 14.4374 12.7515C14.4372 13.1993 14.2591 13.6286 13.9424 13.9451C13.6256 14.2615 13.1961 14.4392 12.7484 14.439C12.3006 14.4388 11.8713 14.2607 11.5548 13.944C11.2384 13.6272 11.0607 13.1978 11.0609 12.75C11.0611 12.3022 11.2392 11.8729 11.5559 11.5564C11.8726 11.24 12.3021 11.0623 12.7499 11.0625Z" fill="currentcolor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.8974 16.1021L15.0224 14.2271C14.917 14.1217 14.774 14.0625 14.6249 14.0625C14.4758 14.0625 14.3328 14.1217 14.2274 14.2271C14.122 14.3326 14.0627 14.4756 14.0627 14.6246C14.0627 14.7737 14.122 14.9167 14.2274 15.0222L16.1024 16.8972C16.2078 17.0026 16.3508 17.0618 16.4999 17.0618C16.649 17.0618 16.792 17.0026 16.8974 16.8972C17.0028 16.7917 17.062 16.6487 17.062 16.4996C17.062 16.3506 17.0028 16.2076 16.8974 16.1021Z" fill="currentcolor" />
      </g>
      <defs>
        <clipPath id="clip0_188_6565">
          <rect width="18" height="18" fill="currentcolor" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconHistory