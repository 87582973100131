import React from "react";
import HeroSection from "../../../components/website/LandingPage/HeroSection";
import DepositeAndSecurity from "../../../components/website/LandingPage/DepositeAndSecurity";
import FeatureStep from "../../../components/website/LandingPage/FeatureStep";
import NewFeature from "../../../components/website/LandingPage/NewFeature";
import DownloadWebApp from "../../../components/website/LandingPage/DownloadWebApp";
import LetsTalk from "../../../components/website/LandingPage/LetsTalk";
import PrivateAndSecure from "../../../components/website/LandingPage/PrivateAndSecure";

const LandingPage = () => {
  return (
    <div>
      <HeroSection />
      <DepositeAndSecurity />
      <PrivateAndSecure />
      <FeatureStep />
      <NewFeature />
      <DownloadWebApp />
      <LetsTalk />
    </div>
  );
};

export default LandingPage;
