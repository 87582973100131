import React, { useEffect, useState } from "react";
import IcnBell from "../../assets/svg/IcnBell";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { USER_API_URL } from "../../api/api";
import { useQuery } from "react-query";
import Loader from "../../components/ui/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button } from '../../components/ui/Button';
import TransactionDetailModal from "../../components/model/TransactionDetailModal";
import { createRipple } from "../../lib/utils";
import FullCardDetailModal from "../../components/model/FullCardDetailModal";

const Notification = () => {
    const axiosInstance = useAxiosPrivate();
    const [notification, setNotification] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isdataLoading, setIsdataLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [openTransactionDetailModal, setOpenTransactionDetailModal] = useState(false);
    const [nevData, setNevData] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [limit, setLimit] = useState(5);
    const [loadingMoreData, setLoadingMoreData] = useState(false);
    const [openCardDetail, setOpenCardDetail] = useState(false);
    const [detail, setDetail] = useState(false);
    const [balance, setBalance] = useState(0);

    const handleOpenCardDetail = () => {
        setOpenCardDetail(true)
    }
    const handleCloseCardDetail = () => {
        setOpenCardDetail(false)
    }

    const getNotification = async () => {
        try {
            const response = await axiosInstance.get(`${USER_API_URL.getNotificationWeb}?limit=${5}&page=${currentPage + 1}`);
            if (response.status == 200) {
                return response?.data;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const { isLoading: isLoadingTran, data: notificationData, refetch } = useQuery(
        ['notificationData', limit, currentPage],
        getNotification,
        { keepPreviousData: true }
    );

    useEffect(() => {
        if (notificationData) {

            setNotification(notifications => [...notifications, ...notificationData?.notifications]);
            setTotalData(notificationData?.metaData?.total);
            setLoadingMoreData(false);
            setIsLoading(false);
        }
    }, [notificationData]);

    const handleLoadMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
        setLoadingMoreData(true);
    };

    const handleIsReadApi = async (data) => {
        try {
            const bodyData = { 'notificationId': data?.id, 'isRead': data?.isRead }
            const result = await axiosInstance.post(USER_API_URL.markAsRead, bodyData)
            if (result.status === 200) {
                const updatedNotifications = notification.map(notification => {
                    if (notification.id === data?.id) {
                        return {
                            ...notification,
                            isRead: true
                        };
                    }
                    return notification;
                });
                setNotification(updatedNotifications);
            }
        } catch (error) {
            console.log({ error })
        }
    }
    const handleCardDeatlApi = async (data) => {
        try {
            const result = await axiosInstance.get(USER_API_URL.hyperCardDetailsSingle, {
                params: {
                    'card_id': data?.data?.cardID
                }
            })
            if (result.status === 200) {
                setDetail(result?.data);
                handleOpenCardDetail();
            }
        } catch (error) {
            console.log({ error })
        }
    }

    const handleNavigation = async (data) => {
        if (data?.isRead == false) {
            handleIsReadApi(data);
        }
        if (data?.data?.type == 9) {
            handleCardDeatlApi(data);
        }

        if (data?.data?.type == 7 && (data?.data?.status == 3 || data?.data?.status == 5)) {
            navigate('/dashboard/kyc');
        }
        if (data?.data?.type == 5 || data?.data?.type == 6) {
            const response = await axiosInstance.get(`${USER_API_URL.broadcastTransactionsDetails}?txId=${data?.data?.txId}`)
            if (response) {
                setOpenTransactionDetailModal(true)
                setNevData(response?.data);
            }
        }

    }

    return (
        <div className="p-12 px-20 bg-dark h-full ">
            {isLoading ? <Loader /> :
                notification?.length > 0 ?
                    <div className="bg-card h-full rounded-[20px] border border-light-100 p-[8px] max-h-[calc(100vh-140px)] overflow-auto hidden-scrollbar">
                        {
                            notification.map((data, index) =>

                                <div className={`rounded-[20px] border border-light-100 py-[6px] px-[8px] mb-[10px] last:mb-0 ${data?.data?.type != '11' ? 'cursor-pointer' : 'cursor-unset'}`} onClick={(e) => {
                                    if (data?.type != 11) {
                                        handleNavigation(data)
                                        createRipple(e);
                                    }
                                }
                                }>
                                    <div className="flex items-start gap-[16px]">
                                    </div>


                                    <div className="flex items-start gap-[16px]"
                                    >
                                        <div className="h-[40px] w-[40px] bg-white/10 rounded-full text-white flex items-center justify-center flex-shrink-0">
                                            <IcnBell className={`h-[24px] w-[24px] ${data?.isRead ? 'text-gray' : 'text-white'}`} />
                                        </div>
                                        <div className="">
                                            <h6 className={`${data?.isRead ? 'text-gray' : 'text-white'} text-[16px] font-500`}>{data?.notification?.title}</h6>
                                            <p className="text-gray text-[14px] font-400">{data?.notification?.body}</p>

                                        </div>
                                    </div>
                                    {/* <h1 className="text-white">{data?.data?.type}</h1> */}
                                    <p className={`${data?.isRead ? 'text-gray' : 'text-white'} text-end`}>{moment(data?.createdAt).format('DD-MM-YYYY h:mm')}</p>
                                </div>
                            )
                        }
                        <div className='flex items-center justify-center'>
                            {/* <div className='text-white cursor-pointer text-[14px] font-300 m-5 p-[6px_14px] rounded-[24px] bg-theme' onClick={handleLoadMore} disabled={loadingMoreData} style={{ float: 'right' }}>
                                {isdataLoading ? "Please wait..." : "Load more..."}
                            </div> */}
                            {notification?.length !== totalData &&
                                <Button className="text-[12px]" onClick={handleLoadMore} disabled={loadingMoreData}>
                                    {loadingMoreData ? 'Loading...' : 'Load More'}
                                </Button>
                            }
                        </div>
                    </div>
                    : <div className=" w-full text-center"><h5 className='text-white gap-[10px] text-[16px] m-auto'>Record Not Found!</h5></div>

            }
            <TransactionDetailModal
                open={openTransactionDetailModal}
                handleClose={() => setOpenTransactionDetailModal(false)}
                detailData={nevData}
                plan='coins'
            />
            {detail && <FullCardDetailModal open={openCardDetail} handleClose={handleCloseCardDetail} detail={detail} balance={balance} />}


        </div>

    )
}


export default Notification