import React from 'react'

const IcnUser = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 12C14.8995 12 17.25 9.6495 17.25 6.75C17.25 3.85051 14.8995 1.5 12 1.5C9.10051 1.5 6.75 3.85051 6.75 6.75C6.75 9.6495 9.10051 12 12 12Z" fill="currentColor" />
            <path d="M10.6305 16.3193L12 17.6895L13.3702 16.3193L12.2422 13.5H11.7577L10.6305 16.3193Z" fill="currentColor" />
            <path d="M22.3282 19.8547C22.0161 18.1465 21.2842 16.5427 20.1986 15.1874C19.1129 13.8322 17.7074 12.7681 16.1085 12.0907C15.4101 12.6296 14.6128 13.0264 13.7617 13.2584L14.763 15.7619C14.8721 16.0345 14.8988 16.333 14.8399 16.6205C14.781 16.9081 14.639 17.1721 14.4315 17.3797L13.0605 18.7499C12.7792 19.0312 12.3977 19.1891 12 19.1891C11.6022 19.1891 11.2208 19.0312 10.9395 18.7499L9.56923 17.3797C9.36177 17.1722 9.21971 16.9084 9.16066 16.621C9.1016 16.3336 9.12814 16.0352 9.23698 15.7627L10.2382 13.2584C9.38716 13.0264 8.58986 12.6296 7.89148 12.0907C6.29256 12.7681 4.88707 13.8322 3.80139 15.1874C2.71571 16.5427 1.98389 18.1465 1.67173 19.8547C1.6127 20.1768 1.62544 20.5079 1.70906 20.8245C1.79268 21.1411 1.94511 21.4353 2.15548 21.6862C2.36723 21.941 2.63252 22.146 2.93247 22.2866C3.23243 22.4273 3.55969 22.5001 3.89098 22.4999H20.109C20.4403 22.5001 20.7675 22.4273 21.0675 22.2866C21.3674 22.146 21.6327 21.941 21.8445 21.6862C22.0549 21.4353 22.2073 21.1411 22.2909 20.8245C22.3745 20.5079 22.3873 20.1768 22.3282 19.8547Z" fill="currentColor" />
        </svg>
    )
}

export default IcnUser