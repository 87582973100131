import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../../components/ui/Button'
import SettingIcon from "../../assets/images/SettingIcon.png"
import OffIcon from "../../assets/svg/IconOff"
import SecurityIcon from "../../assets/svg/IconSecurity"
import VirtualImg from "../../assets/images/VirtualCard.png"
import DebitCardFillImg from "../../assets/images/DebitCardFill.png"
import DebitCardPayPass from "../../assets/images/DebitCardPayPass.png"
import MasterCardImg from "../../assets/images/Mastercard.png"
import Chart from "chart.js/auto";
// import { Bar } from "react-chartjs-2";
import { Menu, RadioGroup } from '@headlessui/react'
import TopCoins from '../../components/dash/TopCoins'
import SetCardLimitModal from '../../components/model/SetCardLimitModal'
import FullCardDetailModal from '../../components/model/FullCardDetailModal'
import SetCardPinModal from '../../components/model/SetCardPinModal'
import AddTokenModal from '../../components/model/AddTokenModal'
import AddCustomToken from '../../components/model/AddCustomToken'
import ApplyVirtualCardModal from '../../components/model/ApplyVirtualCardModal'
import Nfts from '../../components/dash/Nfts'
import IconRight from '../../assets/svg/IconRight'
import IconAddToken from '../../assets/svg/IconAddToken'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { USER_API_URL, WALLET_API_URL } from '../../api/api'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { useMutation, useQuery } from 'react-query'
import { authStore } from '../../context/AuthProvider'
import { AuthSocketContext } from '../../context/AuthSocketProvider'
import { useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { HelperContext } from '../../context/HelperContext'
import CardFront from '../../components/common/card/CardFront'
import VirtualCard from '../../components/common/card/VirtualCard'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/pagination'
import IconPlus from '../../assets/svg/IconPlus'
import Loader from '../../components/ui/Loader'
import IcnRecharge from '../../assets/svg/IcnRecharge'
import SetTransectionListModal from '../../components/model/SetTransectionListModal'
import moment from 'moment/moment'
import { fixDecimal } from '../../Helper'
import SetPhoneInboxModal from '../../components/model/SetPhoneInboxModal'
import TranDetailModal from '../../components/model/TranDetailModal'

var cardTypeJson = {
  1: "Consume",
  2: "Recharge",
  3: "Withdrawal",
  4: "Transfer (In)",
  5: "Transfer (Out)",
  6: "Other",
  7: "Settlement adjustment",
  8: "Refund",
  9: "Payment reversal",
  10: "Fee",
  11: "Fee reversal",
  12: "OTC refund",
  13: "OTC refund reversal",
  14: "Consumption failure",
  15: "Binding card verification transaction",
  16: "Transaction service fee",
  17: "Rescission",
  100: "Credit card bill reconciliation",
  101: "Purchase Crypto Coin",
  102: "Cancel Card",
};

var cardStatus = {
  0: 'Processing',
  1: 'success',
  2: 'fail'
}

const monthNumberMapping = {
  "January": "01",
  "February": "02",
  "March": "03",
  "April": "04",
  "May": "05",
  "June": "06",
  "July": "07",
  "August": "08",
  "September": "09",
  "October": "10",
  "November": "11",
  "December": "12",
};

const DashBoard = () => {
  const { t } = useTranslation();
  const [plan, setPlan] = useState('topcoins');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState("1")
  const [detail, setDetail] = useState(false);
  const [openAddCustomTokenModal, setOpenAddCustomTokenModal] = useState(false);
  const [openApplyVirtualCardModal, setOpenApplyVirtualCardModal] = useState(false);
  const [openCardLimitModal, setOpenCardLimitModal] = useState(false);
  const [openCardDetail, setOpenCardDetail] = useState(false);
  const [openCardPinModal, setOpenCardPinModal] = useState(false);
  const [openInboxModal, setOpenInboxModal] = useState(false);
  const [openTransectionListModal, setOpenTransectionModal] = useState(false);
  const navigate = useNavigate();
  const axiosInstance = useAxiosPrivate();
  const setUser = authStore((state) => state.setUser)
  const { user, walletName, userTotalBalance, set, userWalletAmount } = authStore();
  const setUserWalletAddress = authStore((state) => state.setUserWalletAddress)
  const { setAuthUser } = useContext(AuthSocketContext)
  const { enqueueSnackbar } = useSnackbar();
  const [cardListData, setCardListData] = useState([]);
  const [cardMetaData, setCardMeataData] = useState();
  const [balance, setBalance] = useState();
  const [transactionList, setTransactionList] = useState([]);
  const [isDivView, setIsDivView] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();
  const [cardBalnace, setCardBalance] = useState(0);
  const { userBalance } = useContext(HelperContext)
  const [isApply, setIsApply] = useState(false);
  async function getUserProfile() {
    const response = await axiosInstance.get(USER_API_URL.userProfile);
    const updatedUser = {
      ...user,
      ...response?.data
    };
    setUser(updatedUser);
    setAuthUser(updatedUser)
    return response.data;
  }
  const { isLoading, data: userData, refetch } = useQuery(['userProfile'], () => getUserProfile(), { keepPreviousData: true, })


  const getCardBalance = async () => {
    try {
      const result = await axiosInstance.get(USER_API_URL.getCardBalance);
      setCardBalance(result?.data?.balance)
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    getCardBalance()
  }, [])


  const getwalletaddress = async () => {
    try {
      const response = await axiosInstance.get(WALLET_API_URL.getWalletAddress, {
        headers: {
          'encryptedkey': user?.encKey,
        }
      });

      const newArray = response?.data?.map(item => ({
        address: item.address,
        chainId: item.chainId,
      }));
      setUserWalletAddress(newArray);

    } catch (error) {
      console.log({ error })
    }
  }

  const { isLoadingCard, data: cardList, refetch: userCardListApi } = useQuery(
    [],
    async () => {
      try {
        const response = await axiosInstance.get(`${USER_API_URL.userCardList}`);
        if (response) {
          setCardListData(response?.data?.list);
          setDetail('');

          setCardMeataData(response?.data?.metaData);
          setLoading(false);
        }
        return response?.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      enableReinitialize: true,
    }
  )


  useEffect(() => {
    getwalletaddress();
  }, [])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpenAddCustomTokenModal = () => {
    setOpenAddCustomTokenModal(true)
    handleClose()
  }
  const handleCloseAddCustomTokenModal = () => {
    setOpenAddCustomTokenModal(false)
  }
  const handleOpenApplyVirtualCardModal = () => {
    setOpenApplyVirtualCardModal(true)
  }
  const handleCloseApplyVirtualCardModal = () => {
    setOpenApplyVirtualCardModal(false)
  }
  const handleOpenCardLimitModal = () => {
    setOpenCardLimitModal(true)
  }
  const handleCloseCardLimitModal = () => {
    setOpenCardLimitModal(false)
  }
  const handleOpenCardDetail = () => {
    setOpenCardDetail(true)
  }
  const handleCloseCardDetail = () => {
    setOpenCardDetail(false)
  }
  const handleOpenCardPinModal = () => {
    setOpenCardPinModal(true)
  }
  const handleOpenInboxModal = () => {
    setOpenInboxModal(true)
  }
  const handleOpenTransactionListModal = () => {
    setOpenTransectionModal(true)
  }
  // const handleCloseCardPinModal = () => {
  //   setOpenCardPinModal(false)
  // }
  const handleCloseTransectionListModal = () => {
    setOpenTransectionModal(false)
  }

  const handleCloseInboxListModal = () => {
    setOpenInboxModal(false)
  }

  const labels = ["January", "February", "March", "April", "May"];
  const months = [
    'January',
    'Fabruary',
    'March',
    'April'
  ]
  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: "#555555",
        borderColor: "rgb(255, 99, 132)",
        data: [25, 35, 60, 35, 25],
        borderRadius: 10
      },
    ]
  };
  const options = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  }
  const navigateToTrancaction = () => {
    navigate('/dashboard/active-coins', { state: { selectedTab: plan } })
  }

  const [tokensData, setTokensData] = useState();

  const getUpdatedToken = (value) => {
    setTokensData(value);
  }



  useEffect(() => {
    document.title = 'Dashboard | Oppi Wallet'
  }, [])


  useEffect(() => {
    set({ userTotalBalance: userBalance })
  }, [userBalance])

  const [isFreeze, setIsFreeze] = useState(false);
  const { mutateAsync: manageFreezApi } = useMutation(
    async (data) => {
      try {


        setIsFreeze(true)
        const body = { card_id: detail?.cardId, type: detail?.cardFreezeStatus == 1 ? 2 : 1 }

        return await axiosInstance.post(USER_API_URL.cardOprationRequest, body).then((res) =>
          (false), setDetail((prevDetail) => ({
            ...prevDetail,
            cardFreezeRequestStatus: 0
          })));

      } catch (error) {
        setLoading(false)
        // cardFreezeRequestStatus
        // if (error?.response?.data.message) {
        enqueueSnackbar((detail?.cardFreezeRequestStatus == 0) ? 'Your freez card request is in under process.' : (detail?.cardFreezeRequestStatus == 2 && isFreeze !== true) ? 'Your freez card request has been rejected' : detail?.cardFreezeRequestStatus == 99 ? 'Please waiting for third party' : detail?.cardFreezeRequestStatus == 99 ? 'Pending payment' : '', {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        // }
      }
    },
    {
      onSuccess: (data) => {
      },
      onError: (error) => {
      },

    }
  );


  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [selectedMonth, setSelectedMonth] = useState(monthNames[currentMonthIndex]);
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [mySelectedTime, setMySelectedTime] = useState('');
  const yearsArray = Array.from({ length: 25 }, (_, i) => (new Date().getFullYear() - i).toString());
  const [apply, setApply] = useState(false);

  const selectMonth = () => {
    setSelectedMonth(monthNames[currentMonthIndex]);
    setApply(true)
  }


  // useEffect(() => {
  //   setSelectedMonth(monthNames[currentMonthIndex]); // Set the current month
  //   setSelectedYear(currentYear.toString()); // Set the current year
  // }, [currentMonthIndex, currentYear, monthNames]);


  const handleApply = () => {
    if (selectedMonth && selectedYear) {
      const monthNumber = monthNumberMapping[selectedMonth]; // Get two-digit month
      const formattedDate = `${monthNumber}${selectedYear}`; // Concatenate month and year
      setMySelectedTime(formattedDate);
      getAllTransaction(formattedDate);
      setIsApply(false)
    }
  };

  const { mutateAsync: getAllTransaction } = useMutation(
    async (data) => {
      try {
        setLoading(true)
        const body = { card_id: detail?.cardId, end_time: data, start_time: data }
        return await axiosInstance.post(USER_API_URL.transactionList, body).then((res) => { setTransactionList(res?.data?.[0]?.bank_tx_list); setLoading(false) })
      } catch (error) {
        setLoading(false)
        if (error?.response?.data.message) {
          enqueueSnackbar(error?.response?.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    },
    {
      onSuccess: (data) => {
      },
      onError: (error) => {
      },
    }
  );


  useEffect(() => {
    if (detail?.cardId)
      handleApply();

  }, [detail])

  const currentMonthTransactions = transactionList?.filter((data) => {
    const transactionDate = moment(data?.transaction_date * 1000); // Convert to milliseconds
    const currentMonth = moment().month();
    const currentYear = moment().year();
    return transactionDate.month() === currentMonth && transactionDate.year() === currentYear;
  }) || [];

  const [tranDetail, setTranDetail] = useState();
  const [openTransectionDetailModal, setOpenTransactionDetailModal] = useState(false);

  const handleCloseTransectionDetailModal = () => {
    setOpenTransactionDetailModal(false);
    setOpenTransectionModal(true)
  }

  return (
    <>
      <section className='h-full'>
        <div className="flex md:flex-row flex-col w-full h-full bg-[#000] sm:p-12 p-8 gap-12">
          <div className="flex-grow w-full">
            <div className="flex justify-between items-center mb-8">
              <h5 className='font-600 text-white md:text-[24px] text-[18px]'>{walletName ? walletName : t('Main_Wallet')}</h5>
              {/* <Button className="!text-white flex gap-4 items-center md:text-[16px] text-[14px] border border-light-400 md:py-[12px] py-[8px] md:px-[24px] px-[14px]" variant="gray" size="sm" onClick={handleOpen}><IconAddToken className="h-[18px] w-[18px] txet-grey" /> {t('Add_Tokens')}</Button> */}
            </div>
            <div className={`w-full relative z-[1] bg-linear-horizontal rounded-[20px] before:bg-[url('./../src/assets/images/WalletBg.png')] before:bg-no-repeat before:bg-contain before:bg-right before:w-full before:h-full before:-z-[1] overflow-x-hidden before:top-0 before:end-0  before:absolute`}>
              <div className="p-[30px]">
                <p className='text-[18px] font-600 text-white '>{t('Total_Balance')}</p>
                <h1 className='text-[30px] font-700 text-white mb-[14px]'>{parseFloat(userWalletAmount > 0.00 ? userWalletAmount : 0.00).toFixed(2)} USD</h1>
                <p className='text-[18px] font-600 text-white '>Total card balance</p>
                <h1 className='text-[30px] font-700 text-white'>{parseFloat(cardBalnace > 0.00 ? cardBalnace : 0.00).toFixed(2)} USD</h1>
              </div>
            </div>
            <div className="flex justify-between items-center mt-12 mb-8">
              <RadioGroup className="flex text-white text-18 gap-12" value={plan} onChange={setPlan}>
                <RadioGroup.Option className="cursor-pointer" value="topcoins">
                  {({ checked }) => (
                    <span className={checked ? 'relative before:absolute before:-bottom-[10px] before:h-[3px] before:w-full before:rounded-[10px] before:bg-linear-horizontal' : ''}>{t('Top_Coins')}</span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option className="cursor-pointer" value="nft">
                  {({ checked }) => (
                    <span className={checked ? 'relative before:absolute before:-bottom-[10px] before:h-[3px] before:w-full before:rounded-[10px] before:bg-linear-horizontal' : ''}>{t('NFTs')}</span>
                  )}
                </RadioGroup.Option>
              </RadioGroup>
              <button className='text-gray text-16 flex items-center gap-4' onClick={navigateToTrancaction}>{t('View_All')}<IconRight className="h-[18px] w-[10px] text-grey" /></button>
            </div>
            {
              plan === "topcoins" ? <TopCoins /> : <Nfts />
            }

          </div>
          <div className="w-full md:min-w-[450px] min-w-[unset] md:max-w-[calc(25%+15px)] max-w-full flex-shrink-0">
            {
              loading && !isApply ?
                <div className="min-h-[350px] flex items-center justify-center bg-card rounded-[20px] border border-light-400">
                  <Loader innarClass='mt-0' />
                </div> :
                <div className="virtual-card md:p-[24px] sm:p-[18px] p-[10px] bg-card rounded-[20px] mb-12 border border-light-400 ">
                  <div className="flex justify-between items-center mb-8">
                    <div className="flex items-center gap-[8px]">
                      {detail && <span className='cursor-pointer' onClick={() => { setDetail(false); setBalance() }}><IconRight className="h-[18px] w-[10px] text-grey rotate-180" /></span>}
                      <h5 className='text-white text-20 font-500'>{t('Virtual_Card')}</h5>
                    </div>
                    {/* {
                      (cardListData?.length && !detail) && <Button className="!text-white border border-light-400 h-[30px] w-[30px] p-0 rounded-[8px] flex items-center justify-center" variant="gray" size="sm" onClick={handleOpenApplyVirtualCardModal}><IconPlus className="h-[18px] w-[18px] txet-grey" /></Button>
                    } */}
                    {/* {detail &&
                      <Menu as={"div"} className="flex flex-col justify-end relative">
                        <Menu.Button className="w-max ms-auto">
                          <img src={SettingIcon} alt="" />
                        </Menu.Button>
                        <Menu.Items className="flex flex-col p-6 text-white absolute top-[100%] z-[60] bg-[#262626] w-max rounded-2xl right-0">
                          <Menu.Item>
                            <p
                              className="text-12 pb-4 border-b border-b-light-100 cursor-pointer"
                              onClick={handleOpenCardLimitModal}
                            >
                              {t('Set_Card_limit')}
                            </p>
                          </Menu.Item> 
                          <Menu.Item>
                            <p
                              className="text-12 pt-4 cursor-pointer"
                              onClick={handleOpenCardDetail}
                            >
                              {t('Full_Card_Details')}
                            </p>
                          </Menu.Item>
                        </Menu.Items>
                      </Menu>
                    } */}
                  </div>
                  {
                    !cardListData?.length ?
                      <>
                        <img className='w-[180px] aspect-square mx-auto mb-8' src={VirtualImg} alt="" onClick={() => setDetail(!detail)} />
                        <p className='text-gray text-12 font-400 mb-8'>Oppi Card that helps you to spend your crypto on various merchant outlets. Spend , Store and Leverage your crypto with virtual card in below.</p>
                        {/* <Button className="w-full xl:px-[24px] lg:px-[14px] px-[8px] xl:text-[16px] text-[14px]" size="sm" onClick={handleOpenApplyVirtualCardModal}>{t('Apply_Virtual_Card')}</Button> */}
                      </> : detail ?
                        <div className='flex flex-col gap-[16px]'>
                          <VirtualCard detail={detail} setBalance={setBalance} setDetail={setDetail} />
                          <div className="flex justify-center gap-4">
                            <Button onClick={manageFreezApi} className="p-4 w-[50%] flex gap-2 items-center border border-light-100 !text-14 text-white" variant="gray">
                              <OffIcon className="w-[24px] h-[24px]" />
                              {detail?.cardFreezeStatus == 2 ? 'Freeze' : 'Unfreez'}</Button>
                            <Button className="p-4 w-[50%] flex gap-2 items-center border border-light-100 !text-14 text-white" variant="gray" onClick={handleOpenCardPinModal}>
                              <IcnRecharge className="w-[24px] h-[24px] text-white" />
                              Recharge</Button>
                            {/* <Button className="p-4 w-[50%] flex gap-4 items-center border border-light-100 !text-14 text-white" variant="gray" onClick={handleOpenInboxModal}> */}
                            <Button className="p-4 w-[50%] flex gap-2 items-center border border-light-100 !text-14 text-white" variant="gray" onClick={handleOpenCardDetail}>
                              <IcnRecharge className="w-[24px] h-[24px] text-white" />
                              Card Detail</Button>
                          </div>

                          {detail?.cardFreezeRequestStatus !== undefined &&
                            (
                              detail?.cardFreezeRequestStatus !== 1 &&
                              <ul className='flex items-center justify-center list-disc'>
                                <li className={`${detail?.cardFreezeRequestStatus === 2 ? 'text-red' : 'text-[#cfba54]'} text-[16px]`}>{detail?.cardFreezeRequestStatus === 0 ? 'Your freez card request is in under process.' : detail?.cardFreezeRequestStatus === 2 ? 'Your freez card request has been rejected' : detail?.cardFreezeRequestStatus === 99 ? 'Please waiting for third party' : detail?.cardFreezeRequestStatus === 98 ? 'Pending payment' : ''} </li>
                              </ul>)
                          }
                          {balance && <div className="flex justify-between rounded-[10px] p-[10px] bg-dark"><p className='text-white text-[16px]'>Available Balance</p><p className='text-white'>{balance ? balance : ''} {detail?.hypercardtype?.cardCurrency}</p></div>}
                          {/* <div className="mb-[14px]">
                            <p className='text-white text-[16px] pb-[8px]'>Recharge Info</p>
                            <div className="bg-dark p-[12px] rounded-[10px] flex item-center flex-col gap-[12px]">
                              <div className="flex justify-between">
                                <p className='text-white text-[16px]'>One time min recharge</p>
                                <p className='text-white'>{detail?.hypercardtype?.minSingleRechargeAmount ? detail?.hypercardtype?.minSingleRechargeAmount.toFixed(2) : ''} {detail?.hypercardtype?.cardCurrency}</p>
                              </div>
                              <div className="flex justify-between">
                                <p className='text-white text-[16px]'>One time max recharge</p>
                                <p className='text-white'>{detail?.hypercardtype?.maxSingleRechargeAmount ? detail?.hypercardtype?.maxSingleRechargeAmount?.toFixed(2) : ''} {detail?.hypercardtype?.cardCurrency}</p>
                              </div>
                              <div className="flex justify-between">
                                <p className='text-white text-[16px]'>Max recharge</p>
                                <p className='text-white'>{detail?.hypercardtype?.maxRechargeAmount ? detail?.hypercardtype?.maxRechargeAmount?.toFixed(2) : ''} {detail?.hypercardtype?.cardCurrency}</p>
                              </div>
                            </div>
                          </div> */}
                          {currentMonthTransactions.length > 0 ? (
                            <div className="bg-card rounded-[10px] p-[10px] border border-light-100">
                              <div className="flex justify-between">
                                <p className='text-white text-[16px]'>{t('See_All_Transaction')}</p>
                                <div className="flex items-center gap-[8px]">
                                  {isDivView && (
                                    <Button
                                      className="!text-white text-[12px] border border-light-400 p-[2px_4px] rounded-[8px] flex items-center justify-center"
                                      variant="gray"
                                      size="sm"
                                      onClick={handleOpenTransactionListModal}
                                    >
                                      {moment().format('MMMM')} - {selectedYear}
                                    </Button>
                                  )}
                                  <IconRight
                                    className={`h-[18px] w-[10px] text-grey cursor-pointer ${isDivView ? 'rotate-90' : 'rotate-0'}`}
                                    onClick={() => setIsDivView(!isDivView)}
                                  />
                                </div>
                              </div>

                              <ul>
                                {isDivView && currentMonthTransactions?.slice(0, 5).map((data, index) => {
                                  const timestamp = data?.transaction_date * 1000; // Convert to milliseconds
                                  const formattedDate = moment(timestamp).format('DD MMM, yyyy');

                                  return (
                                    <div key={index}>
                                      <li className='p-[12px_10px_12px] mb-[10px] last:mb-0 flex justify-between last:border-0 cursor-pointer first:mt-[12px] bg-dark rounded-[10px]' onClick={() => {
                                        setOpenTransactionDetailModal(true);
                                        // setTranDetail(...data,formattedDate);
                                        setTranDetail({
                                          ...data,
                                          formattedDate: formattedDate,
                                          status: data?.status
                                        });
                                      }}>
                                        <div className="flex flex-col">
                                          <h5 className='text-white sm:text-[14px] text-[12px]'>{cardTypeJson[data?.type]}</h5>
                                          <p className='text-gray text-[12px]'>{formattedDate}</p>
                                        </div>
                                        <div className="flex items-end flex-col">
                                          <h5 className='text-white sm:text-14 text-12 uppercase'>
                                            {data?.tx_amount ? parseFloat(data?.tx_amount).toFixed(2) : '0.0'} {data?.tx_currency?.toUpperCase()}
                                          </h5>
                                          <p className={`text-[12px] ${data?.status === 0 ? 'text-[#cfba54]' : data?.status === 1 ? 'text-green' : 'text-red'}`}>
                                            {cardStatus[data?.status]}
                                          </p>
                                        </div>
                                      </li>
                                    </div>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            <>
                              <p className='text-white text-center text-[14px]'>
                                You have no transactions for this month. If you want to see previous month transactions, tap on the "View Transactions" button.
                              </p>
                              <Button
                                className={`w-full text-[14px] mt-20 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                size="sm"
                                onClick={handleOpenTransactionListModal}
                              >
                                View Transactions
                              </Button>
                            </>
                          )}
                        </div>
                        :
                        <Swiper
                          spaceBetween={50}
                          slidesPerView={1}
                          modules={[Pagination]}
                          pagination={{ clickable: true, dynamicBullets: true, dynamicMainBullets: 3 }}
                          className='card_slider'
                        >
                          {cardListData?.map((item, index) => (
                            <SwiperSlide><CardFront setDetail={setDetail} detail={item} /></SwiperSlide>
                          ))}
                        </Swiper>
                  }
                </div>
            }
            {/* <div className="insights p-[24px] bg-card rounded-[20px] border border-light-400">
              <div className="flex justify-between items-center mb-8">
                <h5 className='text-white text-20 font-500'>{t('Insights')}</h5>
                {/* <CustomSelect data={months} className="ps-[16px]" />
              </div>
              <h5 className='text-white font-700 mb-4'>$32,251.00</h5>
              <p className='text-gray text-12'>+8,251/last month</p>
              <div className="w-full h-[226px]">
                <Bar className='!h-[100%]' data={data} options={options} />
              </div>
            </div> */}
          </div>
        </div>
        <AddTokenModal
          open={open}
          handleClose={handleClose} openAddCustomTokenModal={handleOpenAddCustomTokenModal} handleCloseAddCustomTokenModal={handleCloseAddCustomTokenModal}
        />
        <AddCustomToken open={openAddCustomTokenModal} handleClose={handleCloseAddCustomTokenModal} />
        <ApplyVirtualCardModal open={openApplyVirtualCardModal} userCardListApi={userCardListApi} handleClose={handleCloseApplyVirtualCardModal} setStep={setStep} />
        <SetCardLimitModal userCardListApi={userCardListApi} open={openCardLimitModal} handleClose={handleCloseCardLimitModal} detail={detail} setDetail={setDetail} />
        {detail && <FullCardDetailModal open={openCardDetail} handleClose={handleCloseCardDetail} detail={detail} balance={balance} />}
        <SetCardPinModal balance={balance} open={openCardPinModal} setOpenCardPinModal={setOpenCardPinModal} detail={detail} getAllTransaction={getAllTransaction} formattedDate={mySelectedTime} />
        <SetTransectionListModal open={openTransectionListModal} handleClose={handleCloseTransectionListModal} detail={detail} transactionList={transactionList} setTransactionList={setTransactionList} handleApply={handleApply}
          setSelectedMonth={setSelectedMonth}
          setSelectedYear={setSelectedYear}
          setMySelectedTime={setMySelectedTime}
          loading={loading}
          setOpenTransectionModal={setOpenTransectionModal}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear} monthNames={monthNames}
          cardStatus={cardStatus}
          setIsApply={setIsApply}
          isApply={isApply}
          mySelectedTime={mySelectedTime} yearsArray={yearsArray}
          cardCurrency={detail?.hypercardtype?.cardCurrency}
        />
        <SetPhoneInboxModal open={openInboxModal} handleClose={handleCloseInboxListModal} detail={detail}
          loading={loading}

        />
        {tranDetail && <TranDetailModal tranDetail={tranDetail} open={openTransectionDetailModal} handleClose={handleCloseTransectionDetailModal} cardCurrency={detail?.hypercardtype?.cardCurrency} cardStatus={cardStatus} />}
      </section>
    </>
  )
}

export default DashBoard