import React from 'react'

const IconAttention = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none">
      <path d="M17.9423 6.05758C16.7673 4.88358 15.2705 4.08432 13.6413 3.76082C12.0121 3.43733 10.3235 3.60412 8.78904 4.24012C7.25459 4.87612 5.94314 5.95278 5.02047 7.33398C4.09781 8.71519 3.60535 10.3389 3.60535 12C3.60535 13.661 4.09781 15.2848 5.02047 16.666C5.94314 18.0472 7.25459 19.1238 8.78904 19.7598C10.3235 20.3958 12.0121 20.5626 13.6413 20.2391C15.2705 19.9156 16.7673 19.1164 17.9423 17.9424C19.5161 16.3651 20.3999 14.2281 20.3999 12C20.3999 9.77191 19.5161 7.63482 17.9423 6.05758ZM17.0939 17.094C16.0865 18.1013 14.8029 18.7873 13.4056 19.0652C12.0082 19.3431 10.5599 19.2004 9.24363 18.6552C7.92739 18.1099 6.80239 17.1866 6.01088 16.002C5.21937 14.8174 4.79691 13.4247 4.79691 12C4.79691 10.5753 5.21937 9.18257 6.01088 7.99796C6.80239 6.81335 7.92739 5.89005 9.24363 5.3448C10.5599 4.79955 12.0082 4.65685 13.4056 4.93474C14.8029 5.21262 16.0865 5.89862 17.0939 6.90598C18.443 8.25803 19.2006 10.09 19.2006 12C19.2006 13.91 18.443 15.7419 17.0939 17.094ZM12.5999 8.38798V13.7712C12.5999 13.9303 12.5367 14.0829 12.4242 14.1954C12.3117 14.308 12.1591 14.3712 11.9999 14.3712C11.8408 14.3712 11.6882 14.308 11.5757 14.1954C11.4632 14.0829 11.3999 13.9303 11.3999 13.7712V8.38678C11.3999 8.22765 11.4632 8.07504 11.5757 7.96252C11.6882 7.84999 11.8408 7.78678 11.9999 7.78678C12.1591 7.78678 12.3117 7.84999 12.4242 7.96252C12.5367 8.07504 12.5999 8.22885 12.5999 8.38798ZM12.5999 15.6408C12.5999 15.7594 12.5647 15.8755 12.4988 15.9741C12.4329 16.0728 12.3392 16.1497 12.2295 16.1951C12.1199 16.2405 11.9993 16.2524 11.8829 16.2293C11.7665 16.2061 11.6596 16.149 11.5757 16.065C11.4918 15.9811 11.4346 15.8742 11.4115 15.7578C11.3883 15.6414 11.4002 15.5208 11.4456 15.4112C11.491 15.3015 11.5679 15.2078 11.6666 15.1419C11.7653 15.076 11.8813 15.0408 11.9999 15.0408C12.1591 15.0408 12.3117 15.104 12.4242 15.2165C12.5367 15.329 12.5999 15.4817 12.5999 15.6408Z" fill="currentcolor" />
    </svg>
  )
}

export default IconAttention