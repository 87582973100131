import React from 'react';

const IconRing = (props) => {
    return (
        <svg {...props} viewBox="0 0 18 18" fill="none">
            <g clipPath="url(#clip0_226_14486)">
                <circle cx="9" cy="9" r="8.5" stroke="#D2D2D2" />
            </g>
            <defs>
                <clipPath id="clip0_226_14486">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconRing
