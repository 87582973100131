import React from 'react'

const IconRight = (props) => {
    return (
        <svg {...props} viewBox="0 0 9 18" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.469582 0.549672C0.176688 0.842565 0.176688 1.31744 0.469582 1.61033L6.98958 8.13033C7.46669 8.60744 7.46669 9.39256 6.98958 9.86967L0.469582 16.3897C0.176688 16.6826 0.176688 17.1574 0.469582 17.4503C0.762474 17.7432 1.23735 17.7432 1.53024 17.4503L8.05024 10.9303C9.11314 9.86744 9.11314 8.13257 8.05024 7.06967L1.53024 0.549672C1.23735 0.256779 0.762474 0.256779 0.469582 0.549672Z" fill="#B3B3B3" />
        </svg>
    )
}

export default IconRight
