import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../components/ui/Button";
import IconCopy from "../../../assets/svg/IconCopy";
import QRCode from "react-qr-code";
import { AUTH_API_URL } from "../../../api/api";
import { authStore } from "../../../context/AuthProvider";
import { useMutation } from "react-query";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import { useSnackbar } from "notistack";
import Loader from "../../../components/ui/Loader";
import { useTranslation } from "react-i18next";

const ScanQr = ({ setStep, setSecretKey }) => {
  const { user } = authStore();
  const axiosInstance = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const [secret, setSecret] = useState();
  const { t } = useTranslation();
  const [qrCode, setQrcode] = useState();

  const handleCopy = () => {
    const wordsToCopy = secret || null;
    navigator?.clipboard?.writeText(wordsToCopy).then(() => {
      console.log('Key copied to clipboard:', wordsToCopy);
      enqueueSnackbar(t('Key_copied_to_clipboard'), {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    });
  }


  const isFirstRun = useRef(true);
  const [isLoading, setIsLoading] = useState(false); useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await generate2FAQr(user?.email);
      } catch (error) {
        console.log('error', error);
        // Handle any errors here
      }
    };

    fetchData();

    // Ensure to handle cleanup if needed
    return () => {
      // Cleanup code here if necessary
    };
  }, [user]);

  const { mutateAsync: generate2FAQr } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(AUTH_API_URL.handleTwoFactorAuthWeb, data, {
          initialData: secret, // Provide initial data
          staleTime: 5000,
        })
        if (response) {
          isFirstRun.current = false;
          setSecret(response?.data?.newSecret)
          setQrcode(response?.data?.qrcode)
          setIsLoading(false)

        }
      } catch (error) {
        setIsLoading(false)
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        } else if (error?.response?.data?.message) {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    }
  );


  const handleNext = () => {
    setStep(3);
    setSecretKey(secret);
  }
  return (
    <>
      <div className="flex flex-col justify-center items-center h-full py-20 my-10">
        <p className="text-gray max-w-[340px] text-center mb-8">
          {t('Scan_QR_code_in_Google_auth_App')}
        </p>
        {/* <img
          src={require("../../../assets/images/NftQrCodeIcon.png")}
          className="w-[200px]"
          alt=""
        /> */}
        {
          isLoading ? <Loader /> :
            (<div className="w-[180px]">
              <img src={qrCode} alt="" srcset="" />
            </div>)
        }
        <p className="text-gray max-w-[350px] text-center mt-8">
          {t('manully_type_code_in_app')}
        </p>
        <div className="flex justify-between items-center w-full max-w-[340px] bg-dark p-2 rounded-full mt-10 border border-light-100">
          <p className="text-white text-14 font-400 ps-6 truncate">
            {secret}
          </p>
          <div className="p-4 bg-linear-horizontal rounded-full cursor-pointer">
            <IconCopy className="w-[20px] h-[20px] text-white" onClick={handleCopy} />
          </div>
        </div>
      </div>
      <div className="py-8 flex gap-8">
        <Button className="w-full border border-white !text-white text-14 bg-transparent" size="sm" onClick={() => setStep(1)}>
          {t('Previous')}
        </Button>
        <Button className="w-full text-[14px] text-white" size="sm" onClick={handleNext}>
          {t('Next')}
        </Button>
      </div>
    </>
  );
};

export default ScanQr;
