import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from '../ui/Button'

const LogoutModal = ({ open, handleClose, message, message2, messageClass,handleLogout }) => {

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-white overflow-hidden border border-light-100 shadow-xl transition-all">
                < div className='dialog-body p-8' >
                  <img src={require("../../assets/gif/logout.gif")} className='w-[230px] mx-auto' alt="" />
                  <p className={'text-center text-black text-20 font-600 mb-8'}>Are you sure want to logout?</p>
                  <Button size={"sm"} className="w-full text-[14px]" onClick={handleLogout}>Done</Button>
                </div >
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )


}

export default LogoutModal
