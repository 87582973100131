import React, { forwardRef, useState } from 'react'
import { cn } from '../../lib/utils'
import IconShow from '../../assets/svg/IconShow';
import IconHide from '../../assets/svg/IconHide';

const AuthInputPassword = forwardRef(({ className, lableClass, errorClass, lable, icon, errorMsg, placeholder, suffix, prefix, type, suffixClass, ...props }, ref) => {
  const [togglePassword, setTogglePassword] = useState(false);

  return (
    <div className="form-group flex flex-col">
      {lable && <label className={cn('text-black text-16 font-500 mb-2', lableClass)}>{lable}</label>}
      <div className="relative">
        <span className='toogle-pass-btn absolute right-[20px] top-[14px] cursor-pointer' onClick={() => setTogglePassword(!togglePassword)}>{togglePassword ? <IconHide className="w-[24px] h-[24px] text-gray" /> : <IconShow className="w-[24px] h-[24px] text-gray" />}</span>
        <input ref={ref} type={`${!togglePassword ? "password" : "text"}`} className={cn(`w-full text-light-600 text-[14px] font-400 bg-light-300 rounded-full border border-light-400 !pe-[48px] ps-[16px] ${prefix ? "ps-[48px] pe-[14px]" : "ps-[28px] pe-[14px]"} py-[14px] outline-0`, className)} placeholder={placeholder} {...props} />
        {prefix && <span className='absolute left-[18px] top-[13px]'>{prefix}</span>}
      </div>
      {errorMsg && <p className={cn('text-12 text-red-500 font-normal', errorClass)} style={{ color:"red" , fontSize : '15px', margin:'8px' }}>{errorMsg}</p>}
    </div>
  )
})
export default AuthInputPassword