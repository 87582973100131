import React from "react";

const IconDiamond = (props) => {
  return (
    <svg {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M4.6666 10.6667H27.3333M21.7199 2.8667H10.2666C7.99994 2.8667 6.99994 4.00003 6.3866 5.3867L2.97327 13.0667C2.35994 14.4534 2.69327 16.52 3.71994 17.64L12.8666 27.6934C14.5999 29.5867 17.4266 29.5867 19.1466 27.6934L28.2799 17.6267C29.3066 16.4934 29.6399 14.44 29.0133 13.0534L25.5999 5.37337C24.9866 4.00003 23.9866 2.8667 21.7199 2.8667Z"
        stroke="currentcolor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconDiamond;
