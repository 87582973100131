import React from 'react'
import CardActivationImg from "../../../assets/images/card/card-activation.png"
import CardActivationLogin from "../../../assets/images/card/card-activation-login.png"
import ActivatedCard from "../../../assets/images/card/activated-card-ui.png"
import CardNumberUi from "../../../assets/images/card/card-number-ui.png"
import CardActivationDone from "../../../assets/images/card/card-activation-done-ui.png"

const CardActivation = () => {
  return (
    <section className='min-h-[60vh]'>
      <div className="">
        <div className="bg-black lg:py-[100px] py-[60px] relative">
          <div className="container">
            <div className="grid lg:grid-cols-2 gap-4 items-center">
              <div className="text-content mb-[32px] flex flex-col gap-4 max-lg:justify-center max-lg:text-center">
                <h4 className='text-white sm:text-[42px] text-32'>Activate Your Card In A Few Simple Steps</h4>
                <p className='text-white font-extralight !leading-[2]'>Follow these straightforward instructions to activate your card in a quick and efficient manner, ensuring that you can start using it for all your transactions with ease and convenience.</p>
              </div>
              <div className="">
                <img src={CardActivationImg} alt="" className='max-w-[240px] sm:max-w-[300px] w-full mx-auto' />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 container py-[60px]">
          <div className="p-6 border border-gray/50 rounded-lg flex flex-col gap-6">
            <div className="text-content flex-grow">
              <h2 className='text-[24px] mb-4'>Step 1</h2>
              <p className='text-[16px] text-black/60 font-semibold leading-[1.8]'>Open Oppiwallet App And Log In To Your Account .</p>
            </div>
            <div className="imag-content">
              <img src={CardActivationLogin} alt="" />
            </div>
          </div>
          <div className="p-6 border border-gray/50 rounded-lg flex flex-col gap-6">
            <div className="text-content flex-grow">
              <h2 className='text-[24px] mb-4'>Step 2</h2>
              <p className='text-[16px] text-black/60 font-semibold leading-[1.8]'>Go to the “Card” tab and select your new card. Then click the button “Activate the card”</p>
            </div>
            <div className="imag-content">
              <img src={ActivatedCard} alt="" className='ms-[12px]' />
            </div>
          </div>
          <div className="p-6 border border-gray/50 rounded-lg flex flex-col gap-6">
            <div className="text-content flex-grow">
              <h2 className='text-[24px] mb-4'>Step 3</h2>
              <p className='text-[16px] text-black/60 font-semibold leading-[1.8]'>Fill in the required information (Valid Until and CVC) to activate the card.</p>
            </div>
            <div className="imag-content">
              <img src={CardNumberUi} alt="" />
            </div>
          </div>
          <div className="p-6 border border-gray/50 rounded-lg flex flex-col gap-6">
            <div className="text-content">
              <h2 className='text-[24px] mb-4'>Step 4</h2>
              <p className='text-[16px] text-black/60 font-semibold leading-[1.8]'>Complete activation! You can start eCommerce and POS payments now.</p>
            </div>
            <div className="imag-content">
              <img src={CardActivationDone} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardActivation