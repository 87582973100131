import React from 'react';

const IconLocation = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.3688 14.0833C32.6188 6.38329 25.9021 2.91663 20.0021 2.91663H19.9854C14.1021 2.91663 7.36878 6.36663 5.61878 14.0666C3.66878 22.6666 8.93545 29.95 13.7021 34.5333C15.3916 36.1686 17.6508 37.083 20.0021 37.0833C22.2688 37.0833 24.5354 36.2333 26.2854 34.5333C31.0521 29.95 36.3188 22.6833 34.3688 14.0833ZM20.0021 22.4333C19.3127 22.4333 18.63 22.2975 17.993 22.0337C17.3561 21.7698 16.7773 21.3831 16.2898 20.8956C15.8023 20.4081 15.4156 19.8293 15.1517 19.1924C14.8879 18.5554 14.7521 17.8727 14.7521 17.1833C14.7521 16.4939 14.8879 15.8112 15.1517 15.1742C15.4156 14.5372 15.8023 13.9585 16.2898 13.471C16.7773 12.9835 17.3561 12.5968 17.993 12.3329C18.63 12.0691 19.3127 11.9333 20.0021 11.9333C21.3945 11.9333 22.7299 12.4864 23.7144 13.471C24.699 14.4555 25.2521 15.7909 25.2521 17.1833C25.2521 18.5757 24.699 19.911 23.7144 20.8956C22.7299 21.8802 21.3945 22.4333 20.0021 22.4333Z" fill="currentColor" />
        </svg>

    );
}

export default IconLocation;
