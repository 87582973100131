import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import img from "../../../assets/images/WebHero.png";
import { Link } from "react-router-dom";

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <section className="relative bg-black flex justify-center">
      <Helmet prioritizeSeoTags>
        <title>
          Crypto Wallet App | Send, Receive & Swap Securely with oppiwallet.com
        </title>
        <meta
          name="description"
          content="Download the oppi crypto wallet app to securely Send, Recive, swap and spend multiple cryptocurrencies. Take full control of your finances with oppiwallet."
        />
        <link rel="canonical" href="https://oppiwallet.com/" />

        <meta
          property="og:title"
          content=">Crypto Wallet App | Send, Receive & Swap Securely with oppiwallet.com"
        />
        <meta
          property="og:description"
          content="Download the oppi crypto wallet app to securely Send, Recive, swap and spend multiple cryptocurrencies. Take full control of your finances with oppiwallet."
        />
        <meta property="og:url" content="https://oppiwallet.com/" />
      </Helmet>

      <div className="text-center !pt-20 relative z-[2] p-[16px]">
        <h2
          className="text-greenweb sm:text-[42px] text-32 font-600"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          {t("Simple_fast_and_secure")}
        </h2>
        <h2
          className="text-white sm:text-[42px] text-32 font-600"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {t("way_to_play_and_get_paid")}
        </h2>
        <div className="flex justify-center gap-8 mt-12">
          <Link to={"https://play.google.com/store/apps/details?id=com.oppi.wallet"} target="_blank" className=" w-[45%] max-w-[180px]">
            <img
              src={require("../../../assets/images/PlayStoreWeb.svg").default}
              className="rounded-lg"
              alt=""
              data-aos="fade-right"
              data-aos-duration="1000"
            />
          </Link>
          <Link to={"https://apps.apple.com/app/oppi-wallet/id6502544148"} target="_blank" className="w-[45%] max-w-[180px]">
            <img
              src={require("../../../assets/images/AppStoreWeb.svg").default}
              className="rounded-lg"
              alt=""
              data-aos="fade-left"
              data-aos-duration="1000"
            />
          </Link>
        </div>
        <img
          src={img}
          className="sm:-mb-[180px] -mb-[80px] sm:mt-[70px] mt-[30px] w-full"
          alt=""
          data-aos="zoom-in"
          data-aos-duration="1000"
        />
      </div>
      <div className="icons md:block hidden">
        <img
          src={require("../../../assets/images/WebIcon1.svg").default}
          className="absolute z-[1] lg:left-[100px] left-[60px] top-[8%] animate-pulse"
          alt=""
        />
        <img
          src={require("../../../assets/images/WebIcon2.svg").default}
          className="absolute z-[1] lg:left-[140px] left-[100px] top-[35%] animate-pulse"
          alt=""
        />
        <img
          src={require("../../../assets/images/WebIcon2.svg").default}
          className="absolute z-[1] lg:left-[35px] left-[40px] top-[60%] animate-pulse"
          alt=""
        />
        <img
          src={require("../../../assets/images/WebIcon2.svg").default}
          className="absolute z-[1] lg:left-[165px] left-[120px] top-[90%] animate-pulse"
          alt=""
        />
        <img
          src={require("../../../assets/images/WebIcon2.svg").default}
          className="absolute z-[1] lg:right-[260px] md:right-[180px] right-[40px] top-[8%] animate-pulse"
          alt=""
        />
        <img
          src={require("../../../assets/images/WebIcon3.svg").default}
          className="absolute z-[1] lg:right-[160px] md:right-[140px] right-[60px] top-[80%] animate-pulse"
          alt=""
        />
        <img
          src={require("../../../assets/images/WebIcon4.svg").default}
          className="absolute z-[1] left-[30px] top-[85%] animate-bounce"
          alt=""
        />
      </div>
    </section>
  );
};

export default HeroSection;
