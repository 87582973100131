import React, { useEffect, useState } from 'react';
import IconLink from "../../assets/svg/IconLink";
import IconAdd from '../../assets/svg/IconAdd';
import IconCopy from '../../assets/svg/IconCopy';
import PaymentLinkModal from '../../components/model/PaymentLinkModal';
import PaymentReceiveModal from '../../components/model/PaymentReceiveModal';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { PAYMENT_LINK_API_URL } from '../../api/api';
import { useMutation, useQuery } from 'react-query'
import Loader from '../../components/ui/Loader';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import IcnCopy from '../../assets/svg/IcnCopy';
import ReactPaginate from 'react-paginate';



export const DateShow = ({ data }) => {
  const axiosInstance = useAxiosPrivate();
  const [currentUtcTime, setCurrentUtctime] = useState();

  async function getCurrentTime() {
    const response = await axiosInstance.get(PAYMENT_LINK_API_URL.getCurrentUtcTime);
    return response.data;
  }

  const { data: currentTime } = useQuery(['currentTime'], () => getCurrentTime(), { keepPreviousData: true, })

  useEffect(() => {
    setCurrentUtctime(currentTime?.currentUTCTime)
  }, [currentTime])


  const [remainingTime, setRemainingTime] = useState(0); // Initialize remaining time in seconds
  const [formattedTime, setFormattedTime] = useState("00:00"); // Initialize formatted time

  useEffect(() => {
    if (data) {
      const originalDate = new Date(data?.createdAt);
      const endDate = new Date(originalDate.getTime() + (data.expireTime * 60000)); // Add expireTime minutes in milliseconds
      const remainTime = endDate - new Date(currentUtcTime);
      const remainingSeconds = Math.floor(remainTime / 1000);
      setRemainingTime(remainingSeconds);
    }
  }, [data, currentUtcTime]);

  useEffect(() => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    const formattedTime = `Time Left : ${String(minutes).padStart(2)} Minutes`;
    setFormattedTime(formattedTime);
    if (minutes > 0) {
      let interval = setInterval(() => {
        setRemainingTime(prevTime => prevTime - 5);
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    } else if (minutes == 0 && seconds > 0) {
      setFormattedTime("Less than one minute")
    } else {
      setFormattedTime("Link expired")
    }
  }, [remainingTime]);


  useEffect(() => {
    if (formattedTime == "00.00") {
      // handleClose()
    }
  })

  return (
    <div>{formattedTime}</div>
  )
}

const Payment = () => {
  const axiosInstance = useAxiosPrivate();
  const [paymentLinkList, setPaymentLinkList] = useState();
  const [openPaymentLinkModal, setOpenPaymentLinkModal] = useState(false);
  const [openPaymentReceive, setOpenPaymentReceive] = useState(false);
  const [paymentLinkDetails, setPaymentLinkDetails] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const handleOpenPaymentLinkModal = () => {
    setOpenPaymentLinkModal(true)
  }
  const handleClosePaymentLinkModal = () => {
    setOpenPaymentLinkModal(false)
  }



  const handleOpenPaymentReceiveModal = (data) => {

    setPaymentLinkDetails(data)
    setOpenPaymentReceive(true)
  }
  const handleClosePaymentReceiveModal = () => {
    setOpenPaymentReceive(false)
    // setLink("");
  }
  async function getCoin() {
    const response = await axiosInstance.get(
      PAYMENT_LINK_API_URL.paymentLinkList + '?page=' + (currentPage + 1)
    );
    return response.data;
  }

  const { isLoading, data: paymentList, refetch } = useQuery(
    ['paymentList', currentPage],
    getCoin,
    { keepPreviousData: true }
  );

  async function getCurrenttime() {
    const response = await axiosInstance.get(PAYMENT_LINK_API_URL.getCurrentUtcTime);
    return response.data.currentUTCTime;
  }

  const { data: CurrentTime } = useQuery(['currenttime'], () => getCurrenttime(), { keepPreviousData: true, })

  const [metaData, setMetaData] = useState()
  useEffect(() => {
    if (paymentList) {
      setPaymentLinkList(paymentList?.paymentData);
      setMetaData(paymentList?.metaData);
    }
  }, [paymentList]);

  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = (text) => {
    navigator?.clipboard?.writeText(text)
      .then(() => {
        enqueueSnackbar("Link copied!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        console.error('Unable to copy text to clipboard', error);
        // Handle the error or provide user feedback
      });
  }
  useEffect(() => {
    document.title = 'Payment Link List | Oppi Wallet'
  }, [])


  function checkLinkExpiration(data) {
    const createdAtTime = new Date(data.createdAt);
    const createdAtTimeMilliseconds = createdAtTime.getTime();
    const currentTimeMilliseconds = new Date(CurrentTime).getTime();
    const timeDifference = currentTimeMilliseconds - createdAtTimeMilliseconds;
    const expireTimeMilliseconds = parseInt(data.expireTime) * 60000;

    if (timeDifference > expireTimeMilliseconds) {
      return true;
    } else {
      return false;
    }
  }
  const pageCount = Math.ceil(metaData?.total / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);

  return (
    <>
      {isLoading ? <Loader /> :
        <div className="p-12 bg-dark h-full">
          <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100 flex flex-col p-12'>
            <div className="flex justify-between items-center mb-12">
              <p className='text-white text-20 font-500'>{t("Payment_Link_List")}</p>
              <div className="py-2 px-8 bg-dark flex items-center gap-4 rounded-full border border-light-100 cursor-pointer" onClick={handleOpenPaymentLinkModal}>
                <IconAdd className="text-white w-[20px]" />
                <p className='text-white'>{t('Create_Link')}</p>
              </div>
            </div>
            <div className={`flex flex-col ${paymentLinkList?.length == 0 ? "flex-grow justify-center items-center" : ""} gap-6`}>
              {
                paymentLinkList?.length > 0 ? (
                  paymentLinkList?.map((data, index) => {
                    const linkexpiration = checkLinkExpiration(data);
                    return (
                      <div className="p-4 pr-8 w-full bg-dark flex justify-between items-center gap-[10px] rounded-3xl cursor-pointer">
                        <div className="flex items-center justify-center gap-[12px]">
                          <div className="h-[46px] w-[46px] bg-linear-horizontal rounded-full flex item-center justify-center flex-shrink-0">
                            <IconLink className="md:w-[26px] w-[18px] text-white" />
                          </div>
                          <div className="flex justify-between items-center w-full" onClick={() => {

                            (!linkexpiration) && handleOpenPaymentReceiveModal(data);
                          }}>
                            <div className="text">
                              <h6 className='text-16 font-500 text-white mb-[8px] break-all'>{data?.link}</h6>
                              {
                                <p className='text-14 font-400 text-gray'>{!linkexpiration && data?.status == 0 ? <DateShow data={data} /> : data?.status == 1 ? "Payment Done" : "Link expired"}</p>
                              }

                            </div>
                          </div>
                        </div>
                        {
                          (!linkexpiration && data?.status == 0) && (
                            <div className="flex-shrink-1">
                              <IcnCopy className="h-[20px] w-[20px] text-white" onClick={() => handleCopy(data?.link)} />
                            </div>
                          )
                        }
                        
                        {/* </div> */}
                      </div>
                    )

                  }

                  )
                ) : <p className='text-white text-10 font-500 text-center'>Record not found!</p>
              }
            </div>
            <div className="flex items-center justify-center mt-[25px]">
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={itemsPerPage}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                className="text-white flex items-center gap-[10px] text-[14px]"
                marginPagesDisplayed={1}
                previousClassName="text-[20px]"
                nextClassName="text-white flex items-center gap-[10px] text-[20px]"
                activeClassName="flex items-center justify-center gap-[10px] text-[14px] h-[24px] rounded-[4px] text-theme border border-theme px-[6px]"
              />
            </div>

          </div>
          <PaymentLinkModal open={openPaymentLinkModal} handleClose={handleClosePaymentLinkModal} />
          <PaymentReceiveModal open={openPaymentReceive} handleClose={handleClosePaymentReceiveModal} paymentLinkDetails={paymentLinkDetails} />
        </div >}
    </>
  )
}

export default Payment