import React from "react";

const BelieveSection = () => {
  return (
    <section
      className="relative bg-dark sm:px-[25px] w-full h-full bg-[url('./../src/assets/images/map.svg')]
      bg-no-repeat bg-right"
    >
      <span className="absolute flex items-center justify-center w-[16px] h-[16px] lg:top-[14%] lg:start-[72%] top-[40%] start-[54%] -translate-x-1/2 -translate-y-1/2">
        <span className="animate-ping duration-300 absolute inline-flex h-full w-full rounded-full bg-greenweb opacity-70"></span>
        <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-linear-horizontal"></span>
      </span>
      <span className="absolute flex items-center justify-center w-[16px] h-[16px] lg:top-[77%] lg:start-[69%] top-[40%] start-[54%] -translate-x-1/2 -translate-y-1/2">
        <span className="animate-ping duration-300 absolute inline-flex h-full w-full rounded-full bg-greenweb opacity-70"></span>
        <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-linear-horizontal"></span>
      </span>
      <span className="absolute flex items-center justify-center w-[16px] h-[16px] lg:top-[53%] lg:start-[76%] top-[40%] start-[54%] -translate-x-1/2 -translate-y-1/2">
        <span className="animate-ping duration-300 absolute inline-flex h-full w-full rounded-full bg-greenweb opacity-70"></span>
        <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-linear-horizontal"></span>
      </span>
      <span className="absolute flex items-center justify-center w-[16px] h-[16px] lg:top-[47%] lg:start-[81%] top-[40%] start-[54%] -translate-x-1/2 -translate-y-1/2">
        <span className="animate-ping duration-300 absolute inline-flex h-full w-full rounded-full bg-greenweb opacity-70"></span>
        <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-linear-horizontal"></span>
      </span>
      <span className="absolute flex items-center justify-center w-[16px] h-[16px] lg:top-[26%] lg:start-[82%] top-[40%] start-[54%] -translate-x-1/2 -translate-y-1/2">
        <span className="animate-ping duration-300 absolute inline-flex h-full w-full rounded-full bg-greenweb opacity-70"></span>
        <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-linear-horizontal"></span>
      </span>
      <span className="absolute flex items-center justify-center w-[16px] h-[16px] lg:top-[26%] lg:start-[90%] top-[40%] start-[54%] -translate-x-1/2 -translate-y-1/2">
        <span className="animate-ping duration-300 absolute inline-flex h-full w-full rounded-full bg-greenweb opacity-70"></span>
        <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-linear-horizontal"></span>
      </span>
      <span className="absolute flex items-center justify-center w-[16px] h-[16px] lg:top-[49%] lg:start-[90%] top-[40%] start-[54%] -translate-x-1/2 -translate-y-1/2">
        <span className="animate-ping duration-300 absolute inline-flex h-full w-full rounded-full bg-greenweb opacity-70"></span>
        <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-linear-horizontal"></span>
      </span>
      <span className="absolute flex items-center justify-center w-[16px] h-[16px] lg:top-[18%] lg:start-[94%] top-[40%] start-[54%] -translate-x-1/2 -translate-y-1/2">
        <span className="animate-ping duration-300 absolute inline-flex h-full w-full rounded-full bg-greenweb opacity-70"></span>
        <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-linear-horizontal"></span>
      </span>
      <span className="absolute flex items-center justify-center w-[16px] h-[16px] lg:top-[77%] lg:start-[94%] top-[40%] start-[54%] -translate-x-1/2 -translate-y-1/2">
        <span className="animate-ping duration-300 absolute inline-flex h-full w-full rounded-full bg-greenweb opacity-70"></span>
        <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-linear-horizontal"></span>
      </span>
      <div className="container">
          <div className="flex flex-col max-w-[490px] md:py-[80px] py-[40px] capitalize gap-8" data-aos="zoom-out" data-aos-duration="1500">
            <h5 className="text-white sm:text-32 text-24 font-500">
              We believe that in a decade the financial system of the internet
            </h5>
            <p className="text-gray sm:text-16 font-400">
              that is, commerce that happens on the internet
            </p>
            <p className="text-gray sm:text-16 font-400">
              will be the largest financial system in the world. And it will be
              powered by crypto.
            </p>
          </div>
      </div>
    </section>
  );
};

export default BelieveSection;
