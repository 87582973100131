import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LanguageDropdown from "../../components/ui/LanguageDropdown";
import IconClose from "../../assets/svg/IconClose";
import { Button } from "../../components/ui/Button";
import IconMenu from "../../assets/svg/IconMenu";
import { Popover, Transition } from "@headlessui/react";
import IconSwap from "../../assets/svg/IconSwap";
import IconStore from "../../assets/svg/IconStore";
import IconBusiness from "../../assets/svg/IconBusiness";
import IconFaq from "../../assets/svg/IconFaq";
import IconDeveloper from "../../assets/svg/IconDeveloper";
import IconProfileSupport from "../../assets/svg/IconProfileSupport";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { useMutation, useQuery } from "react-query";
import { authStore } from "../../context/AuthProvider";
import VersionDropdown from "../../components/ui/VersionDropdown";

const WebHeader = ({ toogleMenu, setToogleMenu }) => {
  const [widthSize, setWidthSize] = useState(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openFeature, setOpenFeature] = useState(false)
  const [openSupport, setOpenSupport] = useState(false)
  const axiosInstance = useAxiosPrivate();
  const { isAuthenticated } = authStore();
  const { t } = useTranslation()
  const resizeWindow = () => {
    setWidthSize(window.innerWidth);
  };
  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  let mobileClass = `fixed transition-all duration-300 ${toogleMenu ? "start-0" : "-start-[100%]"
    } ease-in-out h-screen bg-black max-w-[320px] w-full top-0 p-12 pt-24 z-[998]`;


  const handleNavigationSignUp = () => {
    navigate('/login')
  }

  useEffect(() => {
    setOpenFeature(false)
    setOpenSupport(false)
  }, [pathname]);


  return (
    <header className=" bg-black border-b border-b-light-500 max-lg:py-9 fixed top-0 w-full z-[99] h-[99px]">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center gap-[15px]">
          <Link to={"/"}>
            {
              (widthSize < 768 && pathname.includes('/api-documentation')) ?
                <img
                  src={require("../../assets/images/logo_new.png")}
                  className="w-[40px] h-[40px]"
                  alt=""
                /> :
                <img
                  src={require("../../assets/images/WebLogo.webp")}
                  className="md:w-[175px] w-[120px] md:h-[40px] h-[30px]"
                  alt=""
                />
            }
          </Link>
          {pathname.includes('/api-documentation') && <VersionDropdown />}
        </div>
        <div className={`${widthSize > 992 ? "" : mobileClass}`}>
          <button
            className="lg:hidden absolute top-[24px] end-[20px]"
            onClick={() => setToogleMenu(false)}
          >
            <IconClose className="text-white w-[30px] h-[30px]" />
          </button>
          <ul className="flex gap-6 lg:gap-[45px] lg:flex-row flex-col">
            <li className="lg:py-[37px]">
              <Link
                to="/about-us"
                className={`text-16 relative text-white ${pathname === "/about-us" &&
                  "duration-200 before:absolute before:left-0 before:w-full before:bg-anchor-gradient before:h-1 before:-bottom-[10px]"
                  }`}

              >
                {t("About_Oppi_Wallet")}
              </Link>
            </li>
            <li className="lg:py-[37px]" onMouseOver={() => setOpenFeature(true)} onMouseLeave={() => setOpenFeature(false)}>
              <Popover.Group className="lg:flex lg:gap-x-12">
                <Popover className="relative lg:static" onMouseLeave={() => setOpenFeature(false)}>
                  {({ close }) => (
                    <>
                      <Popover.Button

                        className={`text-16 relative text-white ${pathname === "/swap" ||
                          pathname === "/store-crypto" ||
                          pathname === "/business"
                          ? `duration-200 before:absolute before:left-0 before:w-full
                             before:bg-anchor-gradient before:h-1 before:-bottom-[10px]`
                          : ""
                          }`}
                      >
                        {t('Features')}
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        show={openFeature}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute left-0 top-full z-10 min-w-full overflow-hidden bg-black px-4 lg:px-0 mt-3 lg:mt-0">
                          <div className="lg:container py-5 lg:pb-20">
                            <div className="menu-wrapper">
                              <div className="flex flex-col w-full lg:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:w-[70%] lg:mx-auto">
                                <Link
                                  to={"swap"}
                                  onClick={close}
                                  className="group flex lg:flex-col lg:gap-0 gap-8 lg:items-start items-center"
                                >
                                  <div className="flex justify-center items-center lg:w-[60px] w-[30px] border border-white group-hover:bg-linear-horizontal group-hover:border-none aspect-square rounded-full lg:mb-8">
                                    <IconSwap className="lg:w-[24px] w-[18px] group-hover:bg-transparent text-white group-hover:text-white rounded-lg" />
                                  </div>
                                  <p className="text-white text-18 font-600">
                                    Swaps
                                  </p>
                                  <p className="text-white text-14 font-500 mt-3 lg:block hidden">
                                    Swap instantly and securely
                                  </p>
                                </Link>
                                <Link
                                  to={"store-crypto"}
                                  onClick={close}
                                  className="group flex lg:flex-col lg:gap-0 gap-8 lg:items-start items-center"
                                >
                                  <div className="flex justify-center items-center lg:w-[60px] w-[30px] border border-white group-hover:bg-linear-horizontal group-hover:border-none aspect-square rounded-full lg:mb-8">
                                    <IconStore className="lg:w-[24px] w-[18px] group-hover:bg-transparent text-white group-hover:text-white rounded-full" />
                                  </div>
                                  <p className="text-white text-18 font-600">
                                    Store Cryptos
                                  </p>
                                  <p className="text-white text-14 font-500 mt-3 lg:block hidden">
                                    Do more with your wallet
                                  </p>
                                </Link>
                                <Link
                                  to={"business"}
                                  onClick={close}
                                  className="group flex lg:flex-col lg:gap-0 gap-8 lg:items-start items-center"
                                >
                                  <div className="flex justify-center items-center lg:w-[60px] w-[30px] border border-white group-hover:bg-linear-horizontal group-hover:border-none aspect-square rounded-full lg:mb-8">
                                    <IconBusiness className="lg:w-[24px] w-[18px] group-hover:bg-transparent text-white group-hover:text-white rounded-lg" />
                                  </div>
                                  <p className="text-white text-18 font-600">
                                    Oppi for Business
                                  </p>
                                  <p className="text-white text-14 font-500 mt-3 lg:block hidden">
                                    Accept crypto as a payment
                                  </p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </Popover.Group>
            </li>
            <li className="lg:py-[37px]" onMouseOver={() => setOpenSupport(true)} onMouseLeave={() => setOpenSupport(false)}>
              <Popover.Group className="lg:flex lg:gap-x-12">
                <Popover className="relative lg:static" onMouseLeave={() => setOpenSupport(false)}>
                  {({ close }) => (
                    <>
                      <Popover.Button
                        className={`text-16 relative text-white ${pathname === "/faq" ||
                          pathname === "/support" || pathname === '/api-documentation' || pathname === '/v1/api-documentation'
                          ? `duration-200 before:absolute before:left-0 before:w-full
                             before:bg-anchor-gradient before:h-1 before:-bottom-[10px]`
                          : ""
                          }`}
                      >
                        {t('Support')}
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        show={openSupport}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute left-0 top-full z-10 min-w-full overflow-hidden bg-black px-4 lg:px-0 mt-3 lg:mt-0">
                          <div className="lg:container py-5 lg:pb-20">
                            <div className="menu-wrapper">
                              <div className="flex flex-col w-full lg:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:w-[70%] lg:mx-auto">
                                <Link
                                  to={"faq"}
                                  onClick={close}
                                  className="group flex lg:flex-col lg:gap-0 gap-8 lg:items-start items-center"
                                >
                                  <div className="flex justify-center items-center lg:w-[60px] w-[30px] border border-white group-hover:bg-linear-horizontal group-hover:border-none aspect-square rounded-full lg:mb-8">
                                    <IconFaq className="lg:w-[24px] w-[18px] group-hover:bg-transparent text-white group-hover:text-white rounded-lg" />
                                  </div>
                                  <p className="text-white text-18 font-600">
                                    FAQ
                                  </p>
                                  <p className="text-white text-14 font-500 mt-3 lg:block hidden">
                                    Get answers to your most important questions
                                  </p>
                                </Link>
                                <Link
                                  to={"/v1/api-documentation"}
                                  onClick={close}
                                  className="group flex lg:flex-col lg:gap-0 gap-8 lg:items-start items-center"
                                >
                                  <div className="flex justify-center items-center lg:w-[60px] w-[30px] border border-white group-hover:bg-linear-horizontal group-hover:border-none aspect-square rounded-full lg:mb-8">
                                    <IconDeveloper className="lg:w-[24px] w-[18px] group-hover:bg-transparent text-white group-hover:text-white" />
                                  </div>
                                  <p className="text-white text-18 font-600">
                                    Developer Docs
                                  </p>
                                  <p className="text-white text-14 font-500 mt-3 lg:block hidden">
                                    Get API guides for building powerful
                                    application
                                  </p>
                                </Link>
                                <Link
                                  to={"support"}
                                  onClick={close}
                                  className="group flex lg:flex-col lg:gap-0 gap-8 lg:items-start items-center"
                                >
                                  <div className="flex justify-center items-center lg:w-[60px] w-[30px] border border-white group-hover:bg-linear-horizontal group-hover:border-none aspect-square rounded-full lg:mb-8">
                                    <IconProfileSupport className="lg:w-[28px] w-[20px] group-hover:bg-transparent text-white group-hover:text-white" />
                                  </div>
                                  <p className="text-white text-18 font-600">
                                    Support
                                  </p>
                                  <p className="text-white text-14 font-500 mt-3 lg:block hidden">
                                    Accept crypto as a payment
                                  </p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </Popover.Group>
            </li>
          </ul>
        </div>
        <div className="flex items-center gap-[8px]">
          <LanguageDropdown />
          {/* <div className="lg:flex items-center gap-[45px] hidden">
            <Link to={'/login'} className="bg-theme text-14 font-700 py-[16px] w-[140px] text-center text-white rounded-full">
              {isAuthenticated ? "Dashboard" : "Link Device"}
            </Link>
          </div> */}
          <div className="rounded-full lg:hidden flex item-center justify-center cursor-pointer border border-light-100 text-white px-[10px] py-[6px] text-[14px]" onClick={() => handleNavigationSignUp()}>
            {isAuthenticated ? "Dashboard" : t("Sign_In")}
          </div>
          <button
            className="bg-none border-none outline-none lg:hidden block"
            onClick={() => setToogleMenu(!toogleMenu)}
          >
            <IconMenu className="w-[18px] h-[18px] text-white" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default WebHeader;
