import React from 'react';

const IcnPast = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5449 1.9998C16.5871 1.99994 16.6277 2.00006 16.6667 2.00006H23.3334C23.3722 2.00006 23.4128 1.99994 23.455 1.9998C24.1904 1.99749 25.3942 1.9937 26.3646 2.64066C27.5394 3.42392 28 4.82398 28 6.66674C28 6.70568 28.0002 6.7463 28.0002 6.78846C28.0026 7.52374 28.0064 8.72756 27.3594 9.698C26.5762 10.8729 25.176 11.3334 23.3334 11.3334H16.6667C16.6277 11.3334 16.5871 11.3335 16.5449 11.3337C15.8097 11.336 14.6058 11.3398 13.6354 10.6928C12.4605 9.90954 12 8.50948 12 6.66674C12 4.82398 12.4605 3.42392 13.6354 2.64066C14.6058 1.9937 15.8097 1.99749 16.5449 1.9998ZM15.1146 4.85946C15.0395 4.90954 14.6667 5.17614 14.6667 6.66674C14.6667 8.15732 15.0395 8.42392 15.1146 8.474C15.3385 8.62328 15.7182 8.66674 16.6667 8.66674H23.3334C24.824 8.66674 25.0906 8.29392 25.1406 8.2188C25.2898 7.99488 25.3334 7.6152 25.3334 6.66674C25.3334 5.17614 24.9606 4.90954 24.8854 4.85946C24.6614 4.71018 24.2818 4.66674 23.3334 4.66674H16.6667C15.7182 4.66674 15.3385 4.71018 15.1146 4.85946ZM12 20.3334C12 19.597 12.597 19.0001 13.3333 19.0001H25C25.7364 19.0001 26.3334 19.597 26.3334 20.3334C26.3334 21.0698 25.7364 21.6668 25 21.6668H13.3333C12.597 21.6668 12 21.0698 12 20.3334ZM12 27C12 26.2636 12.597 25.6668 13.3333 25.6668H20.6334C21.3698 25.6668 21.9666 26.2636 21.9666 27C21.9666 27.7364 21.3698 28.3334 20.6334 28.3334H13.3333C12.597 28.3334 12 27.7364 12 27Z" fill="currentColor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.665 6.6281C14.7048 7.3634 14.1409 7.99172 13.4056 8.03146C10.7353 8.1758 9.09457 8.73632 8.06511 9.85066C7.02077 10.9811 6.33365 12.9748 6.33365 16.6667V26.6668C6.33365 29.9764 6.77045 32.022 7.87875 33.2888C8.94853 34.5114 10.9486 35.3334 15.0003 35.3334H25.0004C29.052 35.3334 31.0522 34.5114 32.1218 33.2888C33.2302 32.022 33.667 29.9764 33.667 26.6668V16.6667C33.667 12.9656 32.9796 10.9729 31.9362 9.8451C30.9074 8.73308 29.2676 8.17592 26.595 8.03146C25.8598 7.99172 25.2958 7.3634 25.3356 6.6281C25.3754 5.8928 26.0036 5.32894 26.739 5.36868C29.6164 5.52422 32.1432 6.14204 33.8936 8.0342C35.6294 9.91054 36.3336 12.7512 36.3336 16.6667V26.6668C36.3336 30.0236 35.9372 32.978 34.1288 35.0448C32.2818 37.1554 29.282 38 25.0004 38H15.0003C10.7187 38 7.71877 37.1554 5.87187 35.0448C4.06353 32.978 3.66699 30.0236 3.66699 26.6668V16.6667C3.66699 12.7587 4.37153 9.91902 6.10635 8.04114C7.85607 6.14716 10.3821 5.52434 13.2617 5.36868C13.997 5.32894 14.6253 5.8928 14.665 6.6281Z" fill="currentColor" />
        </svg>

    );
}

export default IcnPast;
