import React, { useEffect, useState } from "react";
import IconClose from "../../assets/svg/IconClose";
import IconMenu from "../../assets/svg/IconMenu";
import { useLocation, useNavigate } from "react-router-dom";
import IconAdd from "../../assets/svg/IconAdd";
import CreateNewTicketModal from "../model/CreateNewTicketModal";
import { authStore } from "../../context/AuthProvider";
import { useTranslation } from "react-i18next";


const SettingHeader = ({ setTooglesidebar, tooglesidebar }) => {
  const [lable, setLable] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openCreateNewTicketModal, setOpenCreateNewTicketModal] =
    useState(false);
  const handleOpenCreateNewTicketModal = () => {
    setOpenCreateNewTicketModal(true);
  };
  const handleCloseCreateNewTicketModal = () => {
    setOpenCreateNewTicketModal(false);
  };
  const location = useLocation();
  let res = location?.pathname.split("/").splice(3, 1);


  useEffect(() => {
    document.title = `${lable} | Oppi Wallet`
  }, [lable])

  useEffect(() => {
    res == "google-auth"
      ? setLable(t("Google_Authenticator"))
      : res == "backup-phrase" ||
        res == "select-backup-phrase" ||
        res == "backup-cloud" ||
        res == "backup-manually"
        ? setLable(t("Backup_Phrase"))
        : res == "support" || res == "support-detail"
          ? setLable(t("Support"))
          : res == "language"
            ? setLable(t("Language"))
            : setLable(t("Update_Profile"));
  }, [location]);

  return (
    <>
      <header className="dash-header bg-card w-full z-[8]">
        <div className="header-wrapper flex items-center xl:gap-0 px-0">
          <div className="flex justify-between items-center h-full w-full">
            <div
              className={`flex items-center justify-between p-8 ${res == "support" ? "py-[14px]" : ""
                } lg:gap-0 gap-8 w-full border-b border-[#b3b3b31a]`}
            >
              <p className="text-white font-600">{lable}</p>
              {res == "support" ?
                <div
                  className="py-2 px-8 bg-dark flex items-center gap-4 rounded-full border border-light-100 cursor-pointer"
                  onClick={handleOpenCreateNewTicketModal}
                >
                  <IconAdd className="text-white w-[20px]" />
                  <p className="text-white">{t('Create_ticket')}</p>
                </div> :
                res == 'support-detail' ?
                  <div
                    className="py-2 px-8 bg-dark flex items-center gap-4 rounded-full border border-light-100 cursor-pointer"
                    onClick={() => navigate("/dashboard/setting/support")}
                  >
                    <p className="text-white">Back</p>
                  </div> : ''
              }
              {tooglesidebar ? (
                <button
                  className="bg-none border-none outline-none lg:hidden block"
                  onClick={() => setTooglesidebar(false)}
                >
                  <IconClose className="w-[20px] h-[20px] text-white" />
                </button>
              ) : (
                <button
                  className="bg-none border-none outline-none lg:hidden block"
                  onClick={() => setTooglesidebar(!tooglesidebar)}
                >
                  <IconMenu className="w-[20px] h-[20px] text-white" />
                </button>
              )}
            </div>
          </div>
        </div>
        <CreateNewTicketModal
          open={openCreateNewTicketModal}
          handleClose={handleCloseCreateNewTicketModal}
        />
      </header>
    </>
  );
};

export default SettingHeader;
