

import React, { useState } from 'react'
import DriveIcon from "../../assets/images/DriveIcon.png"
import BackupIcon from "../../assets/images/BackupIcon.png"
import CustomBakckupImg from "../../assets/images/CustomBackup.png"
import { Button } from '../../components/ui/Button'
import { useNavigate } from 'react-router-dom'
import { generate } from 'random-words'
import { handleEncrypt } from '../../Helper'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { USER_API_URL } from '../../api/api'
import { useSnackbar } from 'notistack'
import { authStore } from '../../context/AuthProvider'
import { useMutation } from 'react-query'


const CreatePhrase = () => {
  const [customBackup, setCustomBackup] = useState(false)
  const navigate = useNavigate();
  const axiosInstance = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const setHasEncyptionWordKey = authStore((state) => state.setHasEncyptionWordKey);
  const setUser = authStore((state) => state.setUser);
  const { user } = authStore();
  const setCanBackUp = authStore((state) => state.setCanBackUp);
  // const gapi = google;
  // const CLIENT_ID = '812746535950-gusfqkurk67r1afmkid57hc0vlb3r33h.apps.googleusercontent.com';
  // const API_KEY = 'AIzaSyDRITVD6aXa_vhhqsnn7OMf0oWmmaeMITw';
  // const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
  // const SCOPES = 'https://www.googleapis.com/auth/drive.file';


  // gapi.load('client:auth2', () => {
  //   gapi.client.init({
  //     apiKey: API_KEY,
  //     clientId: CLIENT_ID,
  //     discoveryDocs: DISCOVERY_DOCS,
  //     scope: SCOPES,
  //   }).then(() => {
  //     // Google API client is initialized, you can now use gapi.client.drive to interact with Google Drive API
  //   });
  // });

  const handleSkipBackup = async () => {
    const words = generate({ exactly: 12 });
    const getSecretKey = handleEncrypt(words);
    const data = {
      isBackup: 0,
      encyption_word_key: getSecretKey
    }
    const updatedUser = {
      ...user,
      secretKey: getSecretKey,
    };
    setUser(updatedUser);
    setCanBackUp(1)
    await storeSecreat(data)
  }


  const { mutateAsync: storeSecreat } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.storeKey, data).then((res) => res.data)
         if(response){
          setHasEncyptionWordKey(1)
          enqueueSnackbar(data.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          navigate("/dashboard");
         }
      } catch (error) {
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    }
  );


  const handleGoogleDriveBackup = async () => {
    try {
    } catch (error) {

    }
  }


  return (
    <div className="p-12 bg-[#000] h-full">

      <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100'>
        <div className="flex justify-between items-center h-full w-full border-b-2 border-[#b3b3b31a]">
          <p className='text-white p-8 border-b border-b-light-100 font-500'>Secret Phrase backup</p>
          <div>

            <Button className='p-2 mr-3' onClick={handleSkipBackup} size="large">Skip</Button>
          </div>
        </div>
        {/* custom backup */}
        {
          customBackup ?
            <div className="p-12">
              <div className="xl:w-[60%] sm:w-[90%] mx-auto">
                <img src={CustomBakckupImg} className='mx-auto' alt="" />
                <h6 className='text-white font-600 text-center mt-12'>Let’s Backup Your Account Now!</h6>
                <p className='text-gray text-14 font-400 text-center mt-8'>in the next step you will see Secret Phrase (12 words) that allows you to recover a wallet</p>
                <div className='form-item flex items-center mt-12 mb-6 p-6 bg-[#000] rounded-full'>
                  <input type="checkbox" className='accent-theme' checked />
                  <div className='text-14 ml-3 text-white font-400'>If i lose my secret phrase, my funds will be lost forever</div>
                </div>
                <div className='form-item flex items-center mt-12 mb-6 p-6 bg-[#000] rounded-full'>
                  <input type="checkbox" className='accent-theme' checked />
                  <div className='text-14 ml-3 text-white font-400'>If i expose or share my secret phrase to anbody, my funds can get stolen</div>
                </div>
                <div className='form-item flex items-center mt-12 p-6 bg-[#000] rounded-full'>
                  <input type="checkbox" className='accent-theme' checked />
                  <div className='text-14 ml-3 text-white font-400'>If i lose my secret phrase, my funds will be lost forever</div>
                </div>
              </div>
              <Button className="w-full mt-20" size="sm" onClick={() => navigate("/create-phrase/select")}>Continue</Button>
            </div> :
            <div className="py-12 px-20">
              <p className='text-gray font-400 mb-12'>Wallet support will never reach out to as for it</p>
              <div className="p-6 bg-[#000] rounded-full flex items-center gap-6 mb-8 cursor-pointer" onClick={() => handleGoogleDriveBackup()}>
                <img src={DriveIcon} alt="" />
                <p className='text-white text-14'>Backup to Google Drive</p>
              </div>
              <div className="p-6 bg-[#000] rounded-full flex items-center gap-6 cursor-pointer" onClick={() => setCustomBackup(true)}>
                <img src={BackupIcon} alt="" />
                <p className='text-white text-14'>Backup Manually</p>
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default CreatePhrase